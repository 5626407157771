<div style="height: 100vH; align-content: center;">
<div style="">
    <div *ngIf="offlineHeaderSaveShow" style="text-align: center;">
        <div style="max-width: 30em; margin: auto;">
            <br>
            Für die offline-Verwendung muss einmalig eine Karte heruntergeladen werden:<br>
            <br>
        </div>
    </div>
    
    <div *ngIf="offlineHeaderRemoveShow" style="text-align: center;">
        <br>
        Karte löschen<br>
        <br>
    </div>
    
    <div class="map-container" id="mapComponentMapContainer">
        <div class="map-frame" style="border: 0;">
            <div id="map"></div>
        </div>
    </div>
    
    <br>
    
    <div id="progressBarNoTransition" style="max-width: 30em; margin: auto;"><!--// progressBar ohne Transition, weil es sonst "hinterherhinkt"-->
        <p-progressBar [value]="offlineFetchCounterProzent" [style]="{'height': '1em', 'max-width': '30em'}" [style.display]="offlineFetchCounterProzent > 0 && offlineFetchCounterProzent < 100 ? 'unset' : 'none'"></p-progressBar>
    </div>
    
    <div *ngIf="offlineButtonSaveShow" style="text-align: center;">
        <button pButton type="button" class="p-button-success" label="Karte herunterladen" icon="fa-solid fa-download" (click)="offlineSaveTiles()">
        </button>
    </div>
    
    <div *ngIf="offlineButtonRemoveShow" style="text-align: center;">
        <button pButton type="button" class="p-button-danger" label="Karte löschen" icon="fa-solid fa-trash" (click)="offlineRemoveTiles()">
        </button>
    </div>

</div>
</div>
