import { /*CRUDBasicDetailComponent_Template, */CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/autocomplete';

//import { ICheckliste } from '../_interfaces/checkliste';
import { ChecklisteService } from '../_services/checkliste.service';
import { ChecklisteDetailGuard } from './checkliste-detail.guard';

//import { ChecklisteDetailComponent_Template } from './checkliste-detail.component.include_template';

import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

@Component({
  selector: 'app-checkliste-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${ChecklisteDetailComponent_Template}`,
  //templateUrl: '../auftrags-checkliste-detail/auftrags-checkliste-detail.component.html',  
  templateUrl: './checkliste-detail.component.html',
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class ChecklisteDetailComponent extends CRUDBasicDetailComponent implements OnInit {
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Checkliste";
  CRUDPageTitleNeu: string = this.translate.instant("Neue Checkliste", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant(/*"Checkliste bearbeiten"*/"Checkliste", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Checkliste";
  CRUDItemBezeichnungPluralCapitalized: string = "Checklisten";
  CRUDItemBezeichnungSingular: string = "checkliste";
  CRUDItemBezeichnungPlural: string = "checklisten";
  CRUDItemRouteSingular: string = "checkliste";
  CRUDItemRoutePlural: string = "checklisten";
  CRUDItemHelpTopic: string = null;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START

  dokumentValueChangeSubscriptionActive = true;

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: ChecklisteService,
    private _guard: ChecklisteDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      bezeichnung: {
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      hinweistext: {
        maxlength: this._translate.instant('Hinweistext', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      imAuftrag: {
        required: this._translate.instant('Im Auftrag', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Im Auftrag', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      imAuftragTelefon: {
        required: this._translate.instant('Im Auftrag Telefon', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Im Auftrag Telefon', true) + ' ' + this._translate.instant('darf 80 Zeichen nicht überschreiten', true)
      },

      dokument: {
        required: this._translate.instant('Dokument', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      hinweistext: ['', [Validators.maxLength(255)]],
      imAuftrag: ['', [Validators.required, Validators.maxLength(255)]],
      imAuftragTelefon: ['', [Validators.required, Validators.maxLength(80)]],
      dokument: [null, [Validators.required]],
      leitfaden: [null],
    });

    // value-change-handler! // https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#valuechanges-of-formcontrol
    this.CRUDForm.get("dokument").valueChanges.subscribe(newValue => {
      console.log("LeistungDetailComponent valueChange dokument: newValue:", newValue);
      //console.log("LeistungDetailComponent valueChange dokument: .get():", this.CRUDForm.get("dokument").value); // entspr. new Value
      console.log("LeistungDetailComponent valueChange dokument: .value:", this.CRUDForm.value.dokument); // enthält OLD Value (sofern nicht innerhalb timeout())
      console.log("LeistungDetailComponent valueChange dokument: dataId:", this.dataId);
      let oldValue = this.CRUDForm.value.checkliste;
      
      if(this.dokumentValueChangeSubscriptionActive == true && this.dataId != 0) {
        if((newValue != null ? newValue.id : 0) != (oldValue != null ? oldValue.id : 0)) {
          this.messageWrapperService.postTimedMessage({ severity: 'warn', summary: "Dokument", detail: "Das nachträgliche Ändern des Dokuments kann zu unerwünschtem Ergebnis bei Aufträgen führen, die begonnen, aber noch nicht abgeschlossen sind!" }); 
        }
      }
    })

    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    this.dokumentValueChangeSubscriptionActive = false; // nach dem Save keine Warnung mehr!

    // MODI: im Gegensatz zu IMKE in den Navigations nur Id bestücken, nicht das Objekt! sonst gibt's Probleme beim PUT
    //       DAS GILT ABER NUR FÜR CRUDBasicDetailComponent - NICHT FÜR CRUDBasicDetailForChildComponent !!!
    a.dokumentId = a.dokument != null ? a.dokument.id : null;
    delete a.dokument;

    a.leitfadenId = a.leitfaden != null ? a.leitfaden.id : null;
    delete a.leitfaden;

    if(a.checklistePositionen != null) {
      a.checklistePositionen.forEach(pos => {
        if(pos.checklistePositionenFelder != null) {
          pos.checklistePositionenFelder.forEach(feld => {
            feld.zusatzDokumentId = feld.zusatzDokument != null ? feld.zusatzDokument.id : null;
            //if(feld.zusatzDokument != null) delete feld.zusatzDokument.typ_ChoiceListEntry; // sonst Error: "ChecklistePositionen[33].ChecklistePositionenFelder[1].ZusatzDokument.Typ_ChoiceListEntry.value": ["The value field is required."]
            if(feld.zusatzDokument != null) delete feld.zusatzDokument; // sonst will EF die CheckListe neu anlegen
          })
        }
      })
    }

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("ChecklisteDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      bezeichnung: this.CRUDItem.bezeichnung,
      hinweistext: this.CRUDItem.hinweistext,
      imAuftrag: this.CRUDItem.imAuftrag,
      imAuftragTelefon: this.CRUDItem.imAuftragTelefon,
      //dokument: this.CRUDItem.dokument    // ohne emit, damit der valuechanges-handler nicht anspringt // https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#valuechanges-of-formcontrol
      leitfaden: this.CRUDItem.leitfaden
    });
    this.CRUDForm.get("dokument").setValue(this.CRUDItem.dokument, { emitEvent: false }); // ohne emit, damit der valuechanges-handler nicht anspringt // https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#valuechanges-of-formcontrol
    
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("ChecklisteDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  onCrudItemsValueChangeChecklistePositionen($event) { // Parent+Child-CRUD
    console.log("ChecklisteDetailComponent.onCrudItemsValueChangeChecklistePositionen() this:", this);
    console.log("ChecklisteDetailComponent.onCrudItemsValueChangeChecklistePositionen() $event:", $event);
    this.CRUDItem.checklistePositionen = $event;
    this.CRUDForm.markAsDirty();

    //this.displayMessageForm = {}; // validity neu prüfen
    //this.CRUDForm.updateValueAndValidity(); // validity neu prüfen
  }

  onCRUDItemRetrieved(CRUDItem: /*IAnrede*/any): void { // overwrite
    console.log("ChecklisteDetailComponent.onCRUDItemRetrieved() CRUDItem:", CRUDItem);
    super.onCRUDItemRetrieved(CRUDItem);
  }

  saveCRUDItem(a: any, close: boolean) { // overwrite
    console.error("ChecklisteDetailComponent.saveCRUDItem() a:", a);

    let itemToSave = cloneDeep(a);

    //TODO
    /*
    itemToSave.pruefer = "#";
    itemToSave.ergebnis = "#";
    itemToSave.betriebskennzeichen = "#";
    itemToSave.nachpruefungErforderlich = "#";
    itemToSave.positionsdaten.forEach(positionsdaten => {
      positionsdaten.bemerkung = "#";
    });
    itemToSave.unterschriftPruefer = "";
    itemToSave.unterschriftKunde = "";
    if(itemToSave.rowVersion == null) itemToSave.rowVersion = "";
    
    if(this.dataId == 0) { // Neuanlage
      itemToSave.auftragsdatenId = this.CRUDItem.auftragsdatenId;
      itemToSave.checklisteId = this.CRUDItem.checklisteId;
      itemToSave.dokumentId = this.CRUDItem.dokumentId;
      itemToSave.benutzerId = this._app.getBenutzer().id;
    }


    // Positionsdaten: nur die speichern, die bereits eine id haben, oder _touched sind
    itemToSave.positionsdaten = itemToSave.positionsdaten.filter(f => f['_touched'] == true || f.id > 0);

    // Navigationen auf Checkliste etc. NICHT übergeben, sonst versucht EF die neu anzulegen
    itemToSave.positionsdaten.forEach(positionsdaten => {
      delete positionsdaten.checklistePosition;

      positionsdaten.positionsdatenFelder.forEach(positionsdatenFeld => {
        delete positionsdatenFeld.checklistePositionenFeld;
      });
    });

    // Bilder
    */
    console.log("ChecklisteDetailComponent.saveCRUDItem() CRUDItem.positionsdaten:", this.CRUDItem.positionsdaten);
    
    
    super.saveCRUDItem(itemToSave, close);
  }


}
