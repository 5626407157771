import { Component, ElementRef, ViewChild, Injector } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { IBenutzer } from './_interfaces/benutzer';
import { _SystemService } from './_services/_system.service';
import { AppconfigService } from './_services/appconfig.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
              ]),
              transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
              ])
        ])
    ],
    styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent {
    isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    benutzer: IBenutzer = null;
    // Abhängig vom Benutzertyp ein Grid wählen (links 3 widgets, oder nur 2)
    benutzerDisplay3Widgets: boolean = false;
    //topBarMiddleDisplay: boolean = true;

    constructor(
        //public appMain: AppMainComponent, // vermeiden, appMain schon hier zu injecten, weil offline haben wir kein appMain (kommt normal über Router)
        public app: AppComponent,
        /*private*/protected _systemService: _SystemService, // private -> protected wegen AppTopBarReplacementForAppComponent
        /*private*/protected config: AppconfigService,
        protected _injector: Injector,
        ) {
    }

    backgroundColor: any;

    activeItem: number;

    model: MegaMenuItem[] = [
        {
            label: 'UI KIT',
            items: [
                [
                    {
                        label: 'UI KIT 1',
                        items: [
                            { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
                            { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
                            { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
                            { label: 'Button', icon: 'pi pi-fw pi-mobile', routerLink: ['/uikit/button'] },
                            { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] }
                        ]
                    }
                ],
                [
                    {
                        label: 'UI KIT 2',
                        items: [
                            { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
                            { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
                            { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
                            { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
                            { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] }
                        ]
                    }
                ],
                [
                    {
                        label: 'UI KIT 3',
                        items: [
                            { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
                            { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
                            { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'] },
                            { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
                            { label: 'Misc', icon: 'pi pi-fw pi-circle-off', routerLink: ['/uikit/misc'] }
                        ]
                    }
                ]
            ]
        },
        {
            label: 'UTILITIES',
            items: [
                [
                    {
                        label: 'UTILITIES 1',
                        items: [
                            { label: 'Icons', icon: 'pi pi-fw pi-prime', routerLink: ['utilities/icons'] },
                            { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank' }
                        ]
                    }
                ],

            ]
        }
    ];

    @ViewChild('searchInput') searchInputViewChild: ElementRef;

    ngOnInit() {
        this.app.topbarComponent = this;

        this.backgroundColor = this.config.get('backgroundColor');

        this._systemService.getSettings().subscribe(settings => {
            if(settings != null) {
                console.log("AppTopBar.ngOnInit() got settings:", settings);
                let ueberfaelligTage = settings.ueberfaelligTage;

                let entryNurUeberfaelligTage = this.app.filterPresets.find(f => f.nurUeberfaelligTage == true);
                entryNurUeberfaelligTage.summary = entryNurUeberfaelligTage.summary.replace('{n}', ''+(ueberfaelligTage/30));
            }
        });
    }

    ngAfterViewInit(): void {  
        let benutzerSubscription = this.app.benutzerBehaviourSubject.subscribe(benutzer => {
            if(benutzer != null) {
                console.log("AppTopbar.ngAfterViewInit() got benutzer:", benutzer);
                this.benutzer = benutzer;

                // Abhängig vom Benutzertyp ein Grid wählen (links 3 widgets, oder nur 2)
                //this.benutzerDisplay3Widgets = benutzer.istAdmin == true && (benutzer.dienstleister.zuordnung == null || benutzer.dienstleister.zuordnung == '') || benutzer.filterung == 'U';
                this.benutzerDisplay3Widgets = this.app.getDisplay3WidgetsInToolbar(benutzer);

                // wenn der Benutzer nur seine eigenen sehen (oder umschalten) darf, dann jetzt setzen! (Achtung, passiert auch in map. Siehe dort)
                if(benutzer.filterung == 'E' || benutzer.filterung == 'U') {
                    this.app.setfilterBenutzerNurMeine();
                }

                setTimeout(() => {
                    benutzerSubscription.unsubscribe(); 
                }, 10);

                //this.placeTopBarMiddle();
            }
        });
    }

    /*placeTopBarMiddle() {
        console.log("App.placeTopBarMiddle()");
        let appTopBarMiddle = document.getElementById("appTopBarMiddle");
        if(appTopBarMiddle != null) {
          let appTopBarMiddleBoundingRect = appTopBarMiddle.getBoundingClientRect();
          let width = appTopBarMiddleBoundingRect.width;
          let x = appTopBarMiddleBoundingRect.x;
          let middleX = x + (width / 2);
          if(middleX < (window.innerWidth/2)) { // Das Logo ist zu weit Links -> zur Mitte schieben!
            // ...
          }
          else { // Das Logo ist >= Mitte
            if(middleX > (window.innerWidth*3/4)) { // Das Logo ist so weit Rechts, dass > 3/4 -> ausblenden!
                //appTopBarMiddle.style.display = 'none';
            }
          }
        }
    }*/

    /*rePositionLogo() {
        console.log("AppTopBar.rePositionLogo()");
        setTimeout(() => {
            this.topBarMiddleDisplay = !this.benutzerDisplay3Widgets;
        }, 10);
    }*/

    isDienstleisterAuswahlAvailable() {
        return this.benutzer != null && (this.benutzer.dienstleister.zuordnung == null || this.benutzer.dienstleister.zuordnung == '');
    }

    onSearchAnimationEnd(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
            break;
        }
    }

    filterPresetChanged($event) {
        console.log("App.filterPresetChanged() $event:", $event);
        this.app.filterNurUeberfaelligJahre = $event.value.nurUeberfaelligJahre;
        this.app.filterNurUeberfaelligTage = $event.value.nurUeberfaelligTage;
    }

    filterBenutzerPresetChanged($event) {
        console.log("App.filterBenutzerPresetChanged() $event:", $event);
        this.app.filterBenutzerNurMeine = $event.value.nurMeine;
    }

    appMain_onRightMenuButtonClick($event) {
        console.log("AppTopBar.appMain_onRightMenuButtonClick() $event:", $event);
        let appMain = this._injector.get(AppMainComponent);
        appMain.onRightMenuButtonClick($event);
    }
}
