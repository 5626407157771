import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IAuftragsdaten } from '../_interfaces/auftragsdaten';
//import { IChoiceList } from '../_interfaces/_choice-list';
//import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

//import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

@Injectable()
export class AuftragsdatenService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'auftraggeber', type: 'string'},
    {name: 'rechnungsempfaenger', type: 'string'},
    {name: 'nrVersorgungsunternehmen', type: 'string'},
    {name: 'niederlassungdesVersorgers', type: 'string'},
    {name: 'kundennummer', type: 'string'},
    {name: 'vorgangsart', type: 'string'},
    {name: 'auftragsdatum', type: 'string'},
    {name: 'leistung', type: 'string'},
    {name: 'auftragsbemerkung', type: 'string'},
    {name: 'bestellnummer', type: 'string'},
    {name: 'bestellposition', type: 'string'},
    {name: 'endedatum', type: 'string'},
    {name: 'terminabspracheerforderlich', type: 'string'},
    {name: 'anredekennzeichenKunde', type: 'string'},
    {name: 'name_1Kunde', type: 'string'},
    {name: 'name_2Kunde', type: 'string'},
    {name: 'strasseKunde', type: 'string'},
    {name: 'plzKunde', type: 'string'},
    {name: 'ortKunde', type: 'string'},
    {name: 'ortzusatsKunde', type: 'string'},
    {name: 'telefonKunde', type: 'string'},
    {name: 'eMailKunde', type: 'string'},
    {name: 'anredekennzeichen', type: 'string'},
    {name: 'name_1Behaelterstandort', type: 'string'},
    {name: 'name_2Behaelterstandort', type: 'string'},
    {name: 'strasseBehaelterstandort', type: 'string'},
    {name: 'plzBehaelterstandort', type: 'string'},
    {name: 'ortBehaelterstandort', type: 'string'},
    {name: 'ortzusatzBehaelterstandort', type: 'string'},
    {name: 'telefonBehaelterstandort', type: 'string'},
    {name: 'eMailBehaelterstandort', type: 'string'},
    {name: 'bemerkungzumBehaelterstandort', type: 'string'},
    {name: 'behaelternummer', type: 'string'},
    {name: 'hersteller', type: 'string'},
    {name: 'baujahr', type: 'string'},
    {name: 'behaeltervolumen', type: 'string'},
    {name: 'fuellvolumen', type: 'string'},
    {name: 'lagerart', type: 'string'},
    {name: 'schutzart', type: 'string'},
    {name: 'ersteAbnahmePruefung', type: 'string'},
    {name: 'letztePruefungvorInbetriebnahme', type: 'string'},
    {name: 'letzteInnerePruefungAnlagenpruefung', type: 'string'},
    {name: 'letzteDruckPruefungFestigkeitspruefung', type: 'string'},
    {name: 'letzteAeusserePruefung', type: 'string'},
    {name: 'baumusternummerBehaelter', type: 'string'},
    {name: 'peilrohrlaenge', type: 'string'},
    {name: 'sicherheitsventilNr', type: 'string'},
    {name: 'technischerLieferstopp', type: 'string'},
    {name: 'verwaltungsdaten', type: 'string'},
    {name: 'geodaten_Breite', type: 'double'},
    {name: 'geodaten_Laenge', type: 'double'},
    {name: 'geoLocation', type: 'Point'},
    {name: 'ankaNr', type: 'string'},
    {name: 'inbetriebnahmeKKSAnlage', type: 'string'},
    {name: 'letztePruefungKKSAnlage', type: 'string'},
    {name: 'anKa_Nr_AnlagenschluesselDruck_Anl', type: 'string'},
    {name: 'anKa_Nr_Anlagenschluessel_Ex_Anl', type: 'string'},
    {name: 'nutzungsart', type: 'int'},
    {name: 'anlagenbeschreibung', type: 'string'},
    {name: 'avisierungsart', type: 'string'},
    {name: 'kontaktDaten_Avisierung_Telefon', type: 'string'},
    {name: 'kontaktdaten_Avisierung_SMS', type: 'string'},
    {name: 'kontaktdaten_Avisierung_EMail', type: 'string'},
    {name: 'dienstleister', type: 'int'},
    {name: 'status', type: 'string'},
    {name: 'tankfarbe', type: 'string'},
    {name: 'name_1Ansprechpartner', type: 'string'},
    {name: 'name_2Ansprechpartner', type: 'string'},
    {name: 'telefonAnsprechpartner', type: 'string'},
    {name: 'eMailAnsprechpartner', type: 'string'},
    {name: 'kundensegment', type: 'string'},
    {name: 'kundenposition', type: 'int'},
    {name: 'telefonGeschaeftlichKunde', type: 'string'},
    {name: 'telefonPrivatKunde', type: 'string'},
    {name: 'telefonMobilKunde', type: 'string'},
    {name: 'eMailKunde', type: 'string'},
    {name: 'telefonGeschaeftlichBehaelterstandort', type: 'string'},
    {name: 'telefonPrivatBehaelterstandort', type: 'string'},
    {name: 'telefonMobilBehaelterstandort', type: 'string'},
    {name: 'eMailBehaelterstandort', type: 'string'},
    {name: 'telefonGeschaeftlichAnsprechpartner', type: 'string'},
    {name: 'telefonPrivatAnsprechpartner', type: 'string'},
    {name: 'telefonMobilAnsprechpartner', type: 'string'},
    {name: 'absatzKg', type: 'string'},
    {name: 'fernabfragegeraet', type: 'string'},
    {name: 'zaehlernummer', type: 'string'},
    {name: 'zaehlerbaujahr', type: 'DateTimeOffset'},
    {name: 'zaehlergroesse', type: 'string'},
    {name: 'zaehlerhersteller', type: 'string'},
    {name: 'zaehlereichdatum', type: 'DateTimeOffset'},
    {name: 'zaehlerbauart', type: 'string'},
    {name: 'vertragartbezeichnung', type: 'string'},
    {name: 'zaehlerKundennummer', type: 'string'},
    {name: 'zaehlerKundensegment', type: 'string'},
    {name: 'zaehlerKundenposition', type: 'int'},
    {name: 'telefonGeschaeftlichZaehlerKunde', type: 'string'},
    {name: 'telefonPrivatZaehlerKunde', type: 'string'},
    {name: 'telefonMobilZaehlerKunde', type: 'string'},
    {name: 'eMailZaehlerKunde', type: 'string'},
    {name: 'telefonGeschaeftlichZaehlerStandort', type: 'string'},
    {name: 'telefonPrivatZaehlerStandort', type: 'string'},
    {name: 'telefonMobilZaehlerStandort', type: 'string'},
    {name: 'eMailZaehlerStandort', type: 'string'},
    {name: 'name_1ZaehlerStandort', type: 'string'},
    {name: 'name_2ZaehlerStandort', type: 'string'},
    {name: 'strasseZaehlerStandort', type: 'string'},
    {name: 'pLZZaehlerStandort', type: 'string'},
    {name: 'ortZaehlerStandort', type: 'string'},
    {name: 'ortzusatzZaehlerStandort', type: 'string'},
    {name: 'name_1ZaehlerKunde', type: 'string'},
    {name: 'name_2ZaehlerKunde', type: 'string'},
    {name: 'strasseZaehlerKunde', type: 'string'},
    {name: 'pLZZaehlerKunde', type: 'string'},
    {name: 'ortZaehlerKunde', type: 'string'},
    {name: 'ortzusatzZaehlerKunde', type: 'string'},
    {name: 'wiedervorlage', type: 'DateTimeOffset'},
  ];
    

  getAuftragsdatenCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ auftragsdaten: IAuftragsdaten[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'auftragsdaten?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { auftragsdaten: <IAuftragsdaten[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getAuftragsdatenForDienstleister(dienstleisterId: number): Observable<{ auftragsdaten: IAuftragsdaten[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'auftragsdaten/dienstleister/' + dienstleisterId, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { auftragsdaten: <IAuftragsdaten[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getAuftragsdatenForDienstleisterIn(dienstleisterIdCommaSeparated: string): Observable<{ auftragsdaten: IAuftragsdaten[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'auftragsdaten/dienstleisterIn/' + dienstleisterIdCommaSeparated, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { auftragsdaten: <IAuftragsdaten[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }

  getAuftragsdaten(id: number): Observable<IAuftragsdaten> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeAuftragsdaten());
      //   observer.complete();
      // })
      return of(this.initializeAuftragsdaten());
    }

    return this.httpClient.get<IAuftragsdaten>(this.apiBaseUrl + 'auftragsdaten?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
            //response
            {
              console.log("KopfdatenService.getKopfdaten() response: ", response)
              return response[0];
            }
      ), catchError(this.handleError));
  }

  saveAuftragsdaten(auftragsdaten: IAuftragsdaten): Observable<IAuftragsdaten> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(auftragsdaten); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("AuftragsdatenService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("AuftragsdatenService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (auftragsdaten.id === 0) {
      return this.createAuftragsdaten(/*auftragsdaten*/itemToSave);
    }

    return this.updateAuftragsdaten(/*auftragsdaten*/itemToSave)

  }

  updateAuftragsdaten(auftragsdaten: IAuftragsdaten): Observable<IAuftragsdaten> {
    return this.httpClient.put<IAuftragsdaten>(this.apiBaseUrl + 'auftragsdaten' /*'/' + auftragsdaten.id*/, auftragsdaten, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  updateAuftragsdatenForDisposition(auftragsdatenForDisposition: any): Observable<any> {
    return this.httpClient.put<IAuftragsdaten>(this.apiBaseUrl + 'auftragsdaten/disposition', auftragsdatenForDisposition, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))
  }

  updateAuftragsdatenForNotizDienstleister(auftragsId: number, notiz: string): Observable<any> {
    return this.httpClient.put<IAuftragsdaten>(this.apiBaseUrl + 'auftragsdaten/notizDienstleister/'+auftragsId, '"'+notiz+'"', { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))
  }

  deleteAuftragsdaten(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'auftragsdaten/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createAuftragsdaten(auftragsdaten: IAuftragsdaten): Observable<IAuftragsdaten> {
    return this.httpClient.post<IAuftragsdaten>(this.apiBaseUrl + 'auftragsdaten', auftragsdaten, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeAuftragsdaten(): IAuftragsdaten {
    return {
      id: 0,
      createdBy: '',
      created: '',
      modifiedBy: '',
      modified: '',
      rowVersion: '',
      auftraggeber: '',
      rechnungsempfaenger: '',
      nrVersorgungsunternehmen: '',
      niederlassungdesVersorgers: '',
      kundennummer: '',
      vorgangsart: '',
      auftragsdatum: '',
      //auftragsart: '',
      auftragsbemerkung: '',
      bestellnummer: '',
      bestellposition: '',
      endedatum: '',
      terminabspracheerforderlich: '',
      anredekennzeichenKunde: '',
      name_1Kunde: '',
      name_2Kunde: '',
      strasseKunde: '',
      plzKunde: '',
      ortKunde: '',
      ortzusatsKunde: '',
      telefonKunde: '',
      eMailKunde: '',
      anredekennzeichen: '',
      name_1Behaelterstandort: '',
      name_2Behaelterstandort: '',
      strasseBehaelterstandort: '',
      plzBehaelterstandort: '',
      ortBehaelterstandort: '',
      ortzusatzBehaelterstandort: '',
      telefonBehaelterstandort: '',
      eMailBehaelterstandort: '',
      bemerkungzumBehaelterstandort: '',
      behaelternummer: '',
      hersteller: '',
      auftragsdatenHersteller: null,
      baujahr: '',
      behaeltervolumen: '',
      fuellvolumen: '',
      lagerart: '',
      schutzart: '',
      ersteAbnahmePruefung: '',
      letztePruefungvorInbetriebnahme: '',
      letzteInnerePruefungAnlagenpruefung: '',
      letzteDruckPruefungFestigkeitspruefung: '',
      letzteAeusserePruefung: '',
      baumusternummerBehaelter: '',
      peilrohrlaenge: '',
      sicherheitsventilNr: '',
      technischerLieferstopp: '',
      verwaltungsdaten: '',
      geodaten_Breite: null,
      geodaten_Laenge: null,
      geoLocation: null,
      ankaNr: '',
      inbetriebnahmeKKSAnlage: '',
      letztePruefungKKSAnlage: '',
      anKa_Nr_AnlagenschluesselDruck_Anl: '',
      anKa_Nr_Anlagenschluessel_Ex_Anl: '',
      nutzungsart: 0,
      //anlagenbeschreibung: '',
      anlagentyp: '',
      auftragsdatenAnlagentyp: null,
      avisierungsart: '',
      kontaktDaten_Avisierung_Telefon: '',
      kontaktdaten_Avisierung_SMS: '',
      kontaktdaten_Avisierung_EMail: '',
      dienstleisterId: 0,
      bemerkungzumKunden: '',
      leistung: '',
      status: '',
      tankfarbe: null,
      name_1Ansprechpartner: null,
      name_2Ansprechpartner: null,
      telefonAnsprechpartner: null,
      eMailAnsprechpartner: null,
      kundensegment: '',
      kundenposition: 0,
      telefonGeschaeftlichKunde: '',
      telefonPrivatKunde: '',
      telefonMobilKunde: '',
      telefonGeschaeftlichBehaelterstandort: '',
      telefonPrivatBehaelterstandort: '',
      telefonMobilBehaelterstandort: '',
      telefonGeschaeftlichAnsprechpartner: '',
      telefonPrivatAnsprechpartner: '',
      telefonMobilAnsprechpartner: '',
      absatzKg: '',
      fernabfragegeraet: '',
      zaehlernummer: '',
      zaehlerbaujahr: null,
      zaehlergroesse: '',
      zaehlerhersteller: '',
      zaehlereichdatum: null,
      zaehlerbauart: '',
      vertragartbezeichnung: '',
      zaehlerKundennummer: '',
      zaehlerKundensegment: '',
      zaehlerKundenposition: 0,
      telefonGeschaeftlichZaehlerKunde: '',
      telefonPrivatZaehlerKunde: '',
      telefonMobilZaehlerKunde: '',
      eMailZaehlerKunde: '',
      telefonGeschaeftlichZaehlerStandort: '',
      telefonPrivatZaehlerStandort: '',
      telefonMobilZaehlerStandort: '',
      eMailZaehlerStandort: '',
      name_1ZaehlerStandort: '',
      name_2ZaehlerStandort: '',
      strasseZaehlerStandort: '',
      pLZZaehlerStandort: '',
      ortZaehlerStandort: '',
      ortzusatzZaehlerStandort: '',
      name_1ZaehlerKunde: '',
      name_2ZaehlerKunde: '',
      strasseZaehlerKunde: '',
      pLZZaehlerKunde: '',
      ortZaehlerKunde: '',
      ortzusatzZaehlerKunde: '',

      wiedervorlage: null
    };
  }


}
