<!-- START CRUD STANDARD -->
<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->

          <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
          <div class="titlebar-button-grid">
            <div>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
            </div>
            <div style="text-align: center;">
              <span style="line-height: 35px; font-size: medium; font-weight: 500;">{{pageTitle}}</span>
            </div>
            <div>
              <span class="floatRight">
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
              </span>
            </div>
          </div>

        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card" style="display: none;"><!--MODI: kein Footer-->
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>
<!-- ENDE CRUD STANDARD -->

<ng-template #CRUDChildTemplate>
    <div class="detail-grid">
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="reihenfolge">
                <label>{{'Reihenfolge'|translate:true}}</label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.reihenfolge}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="abhaengigkeit">
                <label>{{'Abhängigkeit'|translate:true}}</label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.abhaengigkeit}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="bezeichnung">
                <label>{{'Bezeichnung'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.bezeichnung}}
            </div>
        </div>
        <!--
                <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
                  <span class="p-float-label">
                    <input style="width:90%" type="text" pInputText formControlName="checklistePositionId">
                    <label>{{'Checkliste Position Id'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
                  </span>
                </div>
                <div class="detail-grid-sub">
                  <div class="errorMessage">
                    {{displayMessage.checklistePositionId}}
                  </div>
                </div>
    -->
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <!--<input style="width:90%" type="text" pInputText formControlName="info">-->
                <textarea #info style="width: 90%" [rows]="2" pInputTextarea
                formControlName="info" autoResize="autoResize"></textarea>
                <label>{{'Info'|translate:true}}</label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.info}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="platzhalter">
                <label>{{'Platzhalter'|translate:true}}</label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.platzhalter}}
            </div>
        </div>

        <div class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <!--<input style="width:90%" type="text" pInputText formControlName="typ">-->
                <p-dropdown [options]="typOptions" [(ngModel)]="typOptionSelected" optionLabel="summary"
                            [style]="{'width': '90%', 'height': '36px'}"
                            (onChange)="typOptionChanged($event)"
                            ></p-dropdown>
                <label>{{'Typ'|translate:true}}</label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.typ}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="felddefinition">
                <label>{{'Felddefinition'|translate:true}}</label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.felddefinition}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <!--<input style="width:90%" type="text" pInputText formControlName="dokument">-->
            <crud-basic-autocomplete [formGroup]="CRUDForm" style="width: 100%;" [autoCompleteStyle]="{'width':'90%'}"
            formControlName="zusatzDokument"
            id="zusatzDokument" [required]="false" [type]="'dokument'"
            [allowUnvalidatedInput]="false" 
            [disabled]="zusatzDokumentEnabled==false"
            >
            </crud-basic-autocomplete>
            <label>{{'Zusatz-Dokument'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.zusatzDokument}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
            <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="mangel">
                <label>{{'Mangel'|translate:true}}</label>
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.mangel}}
            </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
              <input style="width:90%" type="text" pInputText formControlName="kennzeichen">
              <label>{{'Kennzeichen'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
              {{displayMessage.kennzeichen}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
              <input style="width:90%" type="text" pInputText formControlName="kennziffer">
              <label>{{'Kennziffer'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
              {{displayMessage.kennziffer}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub checkboxes">
            <span class="p-float-label">
                <crud-basic-checkbox formControlName="mussfeld" [nullable]="true" [label]="translate.instant('Mussfeld',true)"></crud-basic-checkbox>
                <!--<p-checkbox label="Mussfeld" pTooltip="Mussfeld" formControlName="mussfeld" [binary]="true" [style]="{'margin-left': '0.76em'}"></p-checkbox>-->
            </span>
        </div>
        <div class="detail-grid-sub">
            <div class="errorMessage">
                {{displayMessage.mussfeld}}
            </div>
        </div>
    </div>

    <hr>
    <div [style.display]="typOptionSelected != null && (typOptionSelected.value == 'dropdown' || typOptionSelected.value == 'combobox') ? 'inline-block' : 'none'">
      <app-checkliste-positionen-feldwert-list #checklistePositionenFeldwerteList *ngIf="CRUDItem != null"
        [parentCrudItem]="CRUDItem" [parentComponent]="this" [typ]="typOptionSelected != null ? typOptionSelected.value : null"
        [crudItems]="CRUDItem.checklistePositionenFeldwerte"
        (crudItemsValueChange)="onCrudItemsValueChangeChecklistePositionenFeldwerte($event)" 
        [style.width]="'100%'">
      </app-checkliste-positionen-feldwert-list>
    </div>
  
</ng-template>
    
