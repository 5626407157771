//import { /*CRUDBasicDetailComponent_Template,*/ CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from '../crud-basic-detail-for-child/crud-basic-detail-for-child.component';


import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/autocomplete';

//import { IChecklistePositionenFeldwert } from '../_interfaces/checkliste-positionen-feldwert';
import { ChecklistePositionenFeldwertService } from '../_services/checkliste-positionen-feldwert.service';
import { ChecklistePositionenFeldwertDetailGuard } from './checkliste-positionen-feldwert-detail.guard';

//import { ChecklistePositionenFeldwertDetailComponent_Template } from './checkliste-positionen-feld-detail.component.include_template';

@Component({
  selector: 'app-checklistePositionenFeldwert-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${ChecklistePositionenFeldwertDetailComponent_Template}`,
  templateUrl: './checkliste-positionen-feldwert-detail.component.html',  
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class ChecklistePositionenFeldwertDetailComponent extends CRUDBasicDetailForChildComponent implements OnInit {
  @Input('typ') typ: string; // dropdown / combobox
  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "ChecklistePositionenFeldwerte";
  CRUDPageTitleNeu: string = this.translate.instant(/*"Neuer Checkliste Positionen Feldwert"*/"Neuer Auswahl-Listen-Wert", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant(/*"Checkliste Positionen Feldwert bearbeiten"*//*"Checkliste Position Feld"*/"Auswahl-Listen-Wert", true);
  CRUDItemBezeichnungSingularCapitalized: string = "ChecklistePositionenFeldwert";
  CRUDItemBezeichnungPluralCapitalized: string = "ChecklistePositionenFeldwerte";
  CRUDItemBezeichnungSingular: string = "checklistePositionenFeldwert";
  CRUDItemBezeichnungPlural: string = "checklistePositionenFeldwerte";
  CRUDItemRouteSingular: string = "checkliste-positionen-feldwert";
  CRUDItemRoutePlural: string = "checkliste-positionen-feldwerte";
  CRUDItemHelpTopic: string = null
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START
  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: ChecklistePositionenFeldwertService,
    private _guard: ChecklistePositionenFeldwertDetailGuard,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      mangel: {
        maxlength: this._translate.instant('Mangel', true) + ' ' + this._translate.instant('darf 6 Zeichen nicht überschreiten', true)
      },

      kennzeichen: {
        maxlength: this._translate.instant('Kennzeichen', true) + ' ' + this._translate.instant('darf 50 Zeichen nicht überschreiten', true)
      },

      bezeichnung: {
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      feldwert: {
        maxlength: this._translate.instant('Feldwert', true) + ' ' + this._translate.instant('darf 1000 Zeichen nicht überschreiten', true)
      },

      //checklistePositionenFelderId: {
      //  required: this._translate.instant('Checkliste Positionen Felder Id', true) + ': ' + this._translate.instant('ist erforderlich', true),
      //},
    };
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.CRUDForm = this.fb.group({
      reihenfolge: [0],
      mangel: ['', [Validators.maxLength(6)]],
      kennzeichen: ['', [Validators.maxLength(50)]],
      bezeichnung: ['', [Validators.maxLength(255)]],
      feldwert: ['', [Validators.maxLength(1000)]],
      checklistePositionenFelderId: [0, [Validators.required]],
    });
    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("ChecklistePositionenFeldwertDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.CRUDForm.patchValue({
      reihenfolge: this.CRUDItem.reihenfolge,
      mangel: this.CRUDItem.mangel,
      kennzeichen: this.CRUDItem.kennzeichen,
      bezeichnung: this.CRUDItem.bezeichnung,
      feldwert: this.CRUDItem.feldwert,
      checklistePositionenFelderId: this.CRUDItem.checklistePositionenFelderId
    });

    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("ChecklistePositionenFeldwertDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

}
