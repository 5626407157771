<br>
<h5 *ngIf="false">&nbsp;&nbsp;Anleitungen und Sonstiges:</h5>

<ul class="fa-ul">
    <ng-container *ngFor="let rowData of crudItems; index as i">
        <li style="margin-top: 1.5em;">
            <span *ngIf="rowData.typ != 'Ueb'" class="fa-li"><i class="fa-regular fa-file-circle-info"></i></span>

            <span *ngIf="rowData.typ == 'Lnk'" style="font-size: revent;"><a href="{{rowData.link}}" target="_blank'">{{rowData.bezeichnung}}</a></span>
            <br *ngIf="rowData.typ == 'Lnk'">
            <div *ngIf="rowData.typ == 'Lnk'" style="margin-top: 0.5em;"><a href="{{rowData.link}}" target="_blank'">{{rowData.beschreibung}}</a></div>
            
            <span *ngIf="rowData.typ != 'Lnk' && rowData.typ != 'Ueb'" style="font-size: revent;"><a style="cursor: pointer;" (click)="downloadToBlob(rowData)">{{rowData.bezeichnung}}</a></span>
            <br *ngIf="rowData.typ != 'Lnk' && rowData.typ != 'Ueb'">
            <div *ngIf="rowData.typ != 'Lnk' && rowData.typ != 'Ueb'" style="margin-top: 0.5em;"><a style="cursor: pointer;" (click)="downloadToBlob(rowData)">{{rowData.beschreibung}}</a></div>

            <br *ngIf="rowData.typ == 'Ueb' && i > 0">
            <span *ngIf="rowData.typ == 'Ueb'" style="margin-left: -1.5rem; font-weight: bold; font-size: 1.25rem;">{{rowData.bezeichnung}}</span>
            <br *ngIf="rowData.typ == 'Ueb'">
            <div *ngIf="rowData.typ == 'Ueb'" style="margin-left: -1.5em; margin-top: 0.5em;">{{rowData.beschreibung}}</div>
        </li>
    </ng-container>
</ul>