import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import * as L from 'leaflet';
import * as LO from 'leaflet.offline';
import { MapComponent } from '../map/map.component';
import { AppComponent } from '../app.component';
import { MessageWrapperService } from '../_services/message-wrapper.service';

@Component({
  selector: 'app-map-download',
  templateUrl: './map-download.component.html',
  styleUrls: ['./map-download.component.scss']
})
export class MapDownloadComponent implements OnInit {
  tileUrlTemplate: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  private map;
  private tiles;

  offlineFetchCounterIst: number = 0;
  offlineFetchCounterSoll: number = 0;
  offlineFetchCounterProzent: number = 0;
  offlineSaveControl: any = null;

  offlineButtonSaveShow: boolean = false;
  offlineButtonRemoveShow: boolean = false;
  offlineHeaderSaveShow: boolean = false;
  offlineHeaderRemoveShow: boolean = false;

  constructor(@Inject(forwardRef(() => AppComponent)) public app: AppComponent,
  public messageWrapperService: MessageWrapperService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {  
    console.log("MapDownload.ngAfterViewInit()");
    /*globalThis*/window['___mapDownloadComponent'] = this; // der android webView kann kein globalThis

    let zoom = 5
    let viewParms:any = [51.34921788779875, 10.446875];
    this.initMap(viewParms, zoom);

    this.map._handlers.forEach(function(handler) { // alle Interaktionen unterbinden
      handler.disable();
    });

    this.offlineZoomD();

    this.updateVisibility();
  }

  updateVisibility() {
    this.offlineButtonSaveShow = false;
    this.offlineButtonRemoveShow = false;
    this.offlineHeaderSaveShow = false;
    this.offlineHeaderRemoveShow = false;
    try {
      LO.getStorageInfo(this.tileUrlTemplate).then((r) => {
        //console.log("MapDownload.getStorageInfo() r:", r);
        /*let summary: number = 0;
        for (let i = 0; i < r.length; i += 1) {
          //console.log("MapDownload.getStorageInfo() i: "+i+" "+r[i].url+" bytes: "+r[i].blob.size);
            summary += r[i].blob.size;
        };
        let kb = summary / 1000; // 1024?
        let mb = kb / 1000; // 1024?
        console.log("MapDownload.getStorageInfo() summary: bytes: "+summary+" = mb: "+mb+" / "+r.length+" tiles.");*/

        // wenn 368 tiles (Deutschlandkarte) bereits geladen -> dann direkt weiter:
        if(r.length >= 368) {
          let mapComponent = window["___mapDownloadComponent"];
          mapComponent.downloadCompleted(mapComponent);
        }else{
          let mapComponent = window["___mapDownloadComponent"];
          mapComponent.tilesNotFound(mapComponent);
        //}
        //if(r.length > 0) {
          //this.offlineButtonRemoveShow = true;
          //this.offlineHeaderRemoveShow = true;
        // }
        // else {
          this.offlineButtonSaveShow = true;
          this.offlineHeaderSaveShow = true;
        }
      });
    }
    catch(e) {
      console.log("MapDownload.getStorageInfo() exception:", e);
      this.offlineButtonSaveShow = true;
      this.offlineHeaderSaveShow = true;
    }
  }

  private initMap(viewParms, zoom): void {
    console.log("Download.initMap() viewParms/zoom:", viewParms, zoom);
    this.map = L.map('map', {
      //center: [ 39.8282, -98.5795 ],
      center: viewParms,
      zoom: zoom,
      zoomControl: false
    });

    // aus https://github.com/allartk/leaflet.offline/blob/master/docs/src/index.js
      let baseLayer = LO.tileLayerOffline(this.tileUrlTemplate, {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 18,
        minZoom: 3,
        maxNativeZoom: 9, // oberhalb von Zoom 9 bleiben die tiles von 9 -> aber werden skaliert!
        minNativeZoom: 3 // scheint keine Auswirkung zu haben! trotzdem gesetzt!
      });//.addTo(this.map);
      this.tiles = baseLayer;
      this.tiles.addTo(this.map);
  
      this.offlineSaveControl = LO.savetiles(baseLayer, {
        zoomlevels: [3, 4, 5, 6, 7, 8, 9], // optional zoomlevels to save, default current zoomlevel
        alwaysDownload: false,
        confirm(layer, successCallback) {
          let mapComponent = window["___mapDownloadComponent"];
          mapComponent.offlineFetchCounterSoll += layer._tilesforSave.length;
          // eslint-disable-next-line no-alert
          //if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
            mapComponent.offlineButtonSaveShow = false;
            successCallback();
          //}
        },
        confirmRemoval(layer, successCallback) {
          // eslint-disable-next-line no-alert
          //if (window.confirm('Remove all the tiles?')) {
            successCallback();
            setTimeout(() => {
              let mapComponent = window["___mapDownloadComponent"];
              mapComponent.updateVisibility();
            }, 1000);
          //}
        },
        saveText: '<i class="fa fa-download" title="Save tiles"></i>',
        rmText: '<i class="fa fa-trash" title="Remove tiles"></i>',
      });
      this.offlineSaveControl.addTo(this.map);

      // saveTiles Control unsichtbar machen: https://github.com/allartk/leaflet.offline/issues/10
      // alternativen wären: https://github.com/allartk/leaflet.offline/issues/29     https://github.com/allartk/leaflet.offline/issues/84
      setTimeout(() => {
        let saveTilesControls = document.getElementsByClassName('savetiles');
        let saveTilesControl = <HTMLElement>saveTilesControls[0];
        saveTilesControl.style.display = 'none';
      }, 10);

      /*let layerswitcher = new L.Control.Layers(
        {
          'osm (offline)': baseLayer,
        },
        null,
        { collapsed: false }
      ).addTo(this.map);
      // add storage overlay
      this.offlineStorageLayer(baseLayer, layerswitcher);*/
    
      baseLayer.on('savestart', (e) => {
        console.log("Map.offline: savestart e:", e);
      });
      baseLayer.on('loadtileend', (e) => {
        console.log("Map.offline: loadtileend e:", e);
        let mapComponent = window["___mapDownloadComponent"];
        mapComponent.offlineFetchCounterIst++;
        mapComponent.offlineFetchCounterProzent = Math.floor(mapComponent.offlineFetchCounterIst * 100 / mapComponent.offlineFetchCounterSoll);

        if(mapComponent.offlineFetchCounterProzent >= 100) {
          this.downloadCompleted(mapComponent);
        }
      });
  }

  downloadCompleted(mapComponent: MapDownloadComponent) {
    console.log("Map.offline: downloadCompleted()");
    setTimeout(() => {
      let eventData = {
            eventType: "tileDownloadCompleted",
            data: JSON.stringify(
            {
              //key: value
            })
      };
      try {
        mapComponent.app.sendMessageToDotNet(eventData);
      }
      catch(e) {
        console.error("Map.offline: downloadCompleted exception e:", e);
        // nicht innerhalb MAUI
        //mapComponent.messageWrapperService.postTimedMessage({ severity: 'info', summary: "Download", detail: "Die Karte wurde erfolgreich heruntergeladen." }); 
      }
      
    }, 10);		
  }

  tilesNotFound(mapComponent: MapDownloadComponent) {
    setTimeout(() => {
      let eventData = {
            eventType: "tileDownloadNotCompleted",
            data: JSON.stringify(
            {
              //key: value
            })
      };
      try {
        mapComponent.app.sendMessageToDotNet(eventData);
      }
      catch(e) {
        console.error("Map.offline: tilesNotFound exception e:", e);       
      }
      
    }, 10);		
  }

  offlineZoomD() {
    let mapDiv = document.getElementById('map');
    mapDiv.style.minWidth = 'unset';
    mapDiv.style.minHeight = 'unset';
    setTimeout(() => {
      mapDiv.style.width = '210px';
      mapDiv.style.height = '290px';
      mapDiv.style.left = 'calc((100vW - 210px) / 2)'; // in der Mitte zentrieren
  
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
  
        setTimeout(() => {
          this.map.setView(new L.LatLng(51.34921788779875, 10.446875), 5);
        }, 10);			
    
      }, 10);
    }, 10);
  }

  offlineSaveTiles() {
    console.log("map.offlineLoadTiles()");
    this.offlineSaveControl._saveTiles();
  }

  offlineRemoveTiles() {
    console.log("map.offlineLoadTiles()");
    this.offlineSaveControl._rmTiles();
  }

  offlineStorageLayer(baseLayer, layerswitcher) { // aus https://github.com/allartk/leaflet.offline/blob/master/docs/src/index/storageLayer.js
    let layer;
  
    let getGeoJsonData = () =>
    LO.getStorageInfo(this.tileUrlTemplate).then((tiles) =>
      LO.getStoredTilesAsJson(baseLayer.getTileSize(), tiles),
    );

    let addStorageLayer = () => {
      getGeoJsonData().then((geojson) => {
        layer = L.geoJSON(geojson).bindPopup(
          (clickedLayer) => clickedLayer['feature']['properties'].key,
        );
        layerswitcher.addOverlay(layer, 'offline tiles');
      });
    };

    addStorageLayer();

    baseLayer.on('storagesize', (e) => {
      if (layer) {
        layer.clearLayers();
        getGeoJsonData().then((data) => {
          layer.addData(data);
        });
      }
    });
  }
}
