<!-- "readonly" heisst: schon änderbar! aber eben nicht per text-input! -->
<p-autoComplete #autoComplete [(ngModel)]="option" [field]="crudItemResultLabelFeld" [required]="required" [suggestions]="filteredOptions" id="dropdownHersteller"
 [readonly]="noTextInput"
 [inputStyle]="inputStyle != null ? inputStyle : {'width':'100%'}" 
 [style]="autoCompleteStyle != null ? autoCompleteStyle : null" 
 (completeMethod)="searchCRUDItem($event)" [size]="size != null ? size : 30"
 (onFocus)="handleCRUDItemFocus($event)"
 (onBlur)="handleCRUDItemBlur($event)"
 (onSelect)="onChange()" 
 (onUnselect)="onChange()" 
 (onClear)="onChange()"
 [disabled]="disabled" 
 [placeholder]="placeholder != null ? placeholder : ''" 
[minLength]="crudItemMinLength" 
[dropdown]="true" 
(onDropdownClick)="onDropdownClick()"
(onShow)="onShowOverlay($event)"
[dropdownIcon]="dropdownIcon"
[appendTo]="'body'"
><!--//#WAITFORVIEWCHILD dropdownIcon = wait als Ersatz für früheren loading ...-->
<!--  [type]="'search'" autocomplete/input search clear/cancel-Button: Macht leider nur webkit -->
<!-- Achtung: dropdownMode wird per TS gesetzt! -->
    <ng-template let-option pTemplate="item">
        <div class="item-grid-container"><!--MODI: DIV Classes-->
            <div>{{getProperty(option, crudItemResultLabelFeld)}}</div><!--MODI: DIV Classes-->
            <div [innerHTML]="getIcon(option)"><!--MODI: DIV Classes-->
                <!--<i class="fa fa-construction"></i>-->
            </div>
        </div>
    </ng-template>
</p-autoComplete>

<!-- der progressSpinner ist nicht in Verwendung, weil wenn er innerhalb onDropdownClick() gesetzt wird, wird er leider NICHT SOFORT dargestellt...-->
<div #divProgressSpinner style="position:relative; margin-left: -60px; display: inline-block; top: 0px;"
    [style.display]="showProgressSpinner == true  ? 'unset' : 'none'">
    <p-progressSpinner [style]="{width: '1.25em', height: '1.25em'}" strokeWidth="3"></p-progressSpinner>
</div>

<!--erasebutton deaktiviert, weil das löschen noch nicht zu null führt (das ist ein problem, wenn pflichtfeld)    false &&-->
<i #eraseButton class="fas fa-times-circle buttonInInputField" (click)="clear($event)" [style.display]="false && inputHasFocus == true && autoCompleteControl != null && autoCompleteControl.inputEL != null && autoCompleteControl.inputEL.nativeElement != null && autoCompleteControl.inputEL.nativeElement.value != null && autoCompleteControl.inputEL.nativeElement.value.length>0 ? 'unset' : 'none'"></i>

<!--inputStyleClass="ng-dirty ng-invalid ng-touched"-->
<!--[dataKey]="id"-->
<!--option:{{ option != null ? option.id : 'null'}}-->

<crud-basic-select *ngIf="dialog==true" [visible]="dialogVisible==true" [options]="options" 
[search]="dialogSearchValue" [title]="crudItemSearchDialogTitle" [label]="crudItemSearchDialogLabel" [label2]="crudItemSearchDialogLabel2" 
(onSelect)="onDialogSelected($event)" (onClose)="onDialogClose($event)"></crud-basic-select>
<!-- visible reicht nicht! *ngIf muss auch, sonst macht er für alle autocompletes einen unsichtbaren select-dialog -->
