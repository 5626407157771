import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import { RouteReuseStrategy } from "@angular/router";
import { CustomReuseStrategy } from "./_routing/custom-reuse-strategy";
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from '@fullcalendar/angular';
import {GalleriaModule} from 'primeng/galleria';
import {ImageModule} from 'primeng/image';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

import {AppCodeModule} from './blocks/app-code/app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppInlineMenuComponent} from './app.inlinemenu.component';
import {AppRightMenuComponent} from './app.rightmenu.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {DashboardComponent} from './demo/view/dashboard.component';
import {DashboardAnalyticsComponent} from './demo/view/dashboardanalytics.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {FloatLabelDemoComponent} from './demo/view/floatlabeldemo.component';
import {InvalidStateDemoComponent} from './demo/view/invalidstatedemo.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MenusComponent} from './demo/view/menus/menus.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {IconsComponent} from './utilities/icons.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppTimelineDemoComponent} from './pages/app.timelinedemo.component';
import {AppWizardComponent} from './pages/app.wizard.component';
import {AppLandingComponent} from './pages/app.landing.component';
import {BlocksComponent} from './blocks/blocks/blocks.component';
import {BlockViewer} from './blocks/blockviewer/blockviewer.component';

import {CountryService} from './demo/service/countryservice';
import {CustomerService} from './demo/service/customerservice';
import {EventService} from './demo/service/eventservice';
import {IconService} from './demo/service/iconservice';
import {NodeService} from './demo/service/nodeservice';
import {PhotoService} from './demo/service/photoservice';
import {ProductService} from './demo/service/productservice';

import {MenuService} from './app.menu.service';
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {AppContactusComponent} from './pages/app.contactus.component';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';


import { MessageService } from 'primeng/api';
import { APP_INITIALIZER } from '@angular/core';
import {
    ConfirmationService,
  } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';

import { TranslateService, TRANSLATION_PROVIDERS } from './_services/translate.service';
import { CRUDBasicPipe } from './_pipes/crud-basic.pipe';
import { TranslatePipe } from './_pipes/translate.pipe';
import { MapComponent } from './map/map.component';
import { AppconfigService } from './_services/appconfig.service';
import { AuftragsdatenService } from './_services/auftragsdaten.service';
import { AuftragsdatenListComponent } from './auftragsdaten-list/auftragsdaten-list.component';
import { ListeComponent } from './liste/liste.component';
import { DummyComponent } from './dummy/dummy.component';
import { LeistungPipe } from './_pipes/leistung.pipe';
import { BenutzerPipe } from './_pipes/benutzer.pipe';
import { PhoneCanonicalPipe } from './_pipes/phone-canonical.pipe';
import { CRUDBasicDetailComponent } from './crud-basic-detail/crud-basic-detail.component';
import { CRUDBasicDetailForChildComponent } from './crud-basic-detail-for-child/crud-basic-detail-for-child.component';
import { CRUDBasicListComponent } from './crud-basic-list/crud-basic-list.component';
import { CRUDBasicListForChildComponent } from './crud-basic-list-for-child/crud-basic-list-for-child.component';
import { CRUDBasicSignatureComponent } from './crud-basic-signature/crud-basic-signature.component';
import { CRUDBasicInputComponent } from './crud-basic-input/crud-basic-input.component';
import { CRUDBasicInputCalendarComponent } from './crud-basic-input-calendar/crud-basic-input-calendar.component';
import { CRUDBasicCheckboxComponent } from './crud-basic-checkbox/crud-basic-checkbox.component';

import { AuftragsChecklisteDetailComponent } from './auftrags-checkliste-detail/auftrags-checkliste-detail.component';
import { AuftragsChecklisteDetailGuard } from './auftrags-checkliste-detail/auftrags-checkliste-detail.guard';
//import { ChecklisteFreitexteDetailComponent } from './checkliste-freitexte-detail/checkliste-freitexte-detail.component';
//import { ChecklistePositionDetailComponent } from './checkliste-position-detail/checkliste-position-detail.component';
//import { ChecklistePositionenFeldDetailComponent } from './checkliste-positionen-feld-detail/checkliste-positionen-feld-detail.component';

import { ChecklisteService } from './_services/checkliste.service';
import { ChecklisteFreitexteService } from './_services/checkliste-freitexte.service';
import { ChecklistePositionService } from './_services/checkliste-position.service';
import { ChecklistePositionenFeldService } from './_services/checkliste-positionen-feld.service';

import { KopfdatenService } from './_services/kopfdaten.service';
import { PositionsdatenService } from './_services/positionsdaten.service';
import { PositionsdatenFelderService } from './_services/positionsdaten-felder.service';
import { PositionsdatenFotosService } from './_services/positionsdaten-fotos.service';
import { LeistungListComponent } from './leistung-list/leistung-list.component';
import { LeistungDetailComponent } from './leistung-detail/leistung-detail.component';
import { LeistungService } from './_services/leistung.service';
import { LeistungDetailGuard } from './leistung-detail/leistung-detail.guard';
import { DokumenteService } from './_services/dokumente.service';
import { BenutzerService } from './_services/benutzer.service';
import { _SystemService } from './_services/_system.service';
import { DatumOhneZeitPipe } from './_pipes/datum-ohne-zeit.pipe';
import { ChecklisteListComponent } from './checkliste-list/checkliste-list.component';
import { ChecklisteDetailComponent } from './checkliste-detail/checkliste-detail.component';
import { ChecklisteDetailGuard } from './checkliste-detail/checkliste-detail.guard';
import { ChecklistePositionListComponent } from './checkliste-position-list/checkliste-position-list.component';
import { ChecklistePositionDetailComponent } from './checkliste-position-detail/checkliste-position-detail.component';
import { ChecklistePositionDetailGuard } from './checkliste-position-detail/checkliste-position-detail.guard';
import { ChecklistePositionenFeldListComponent } from './checkliste-positionen-feld-list/checkliste-positionen-feld-list.component';
import { ChecklistePositionenFeldDetailComponent } from './checkliste-positionen-feld-detail/checkliste-positionen-feld-detail.component';
import { ChecklistePositionenFeldDetailGuard } from './checkliste-positionen-feld-detail/checkliste-positionen-feld-detail.guard';
import { FAIconPipe } from './_pipes/faicon.pipe';
import { DokumentListComponent } from './dokument-list/dokument-list.component';
import { DokumentDetailComponent } from './dokument-detail/dokument-detail.component';
import { DokumentDetailGuard } from './dokument-detail/dokument-detail.guard';
import { DokumentDateiUploadComponent } from './dokument-datei-upload/dokument-datei-upload.component';
import { _DateiService } from './_services/_datei.service';
import { CRUDBasicAutocompleteComponent } from './crud-basic-autocomplete/crud-basic-autocomplete.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserTableColumnStateService } from './_services/user-table-column-state.service';
import { AnlagenbeschreibungService } from './_services/anlagenbeschreibung.service';
import { StammdatenKorrekturListComponent } from './stammdaten-korrektur-list/stammdaten-korrektur-list.component';
import { StammdatenKorrekturDetailComponent } from './stammdaten-korrektur-detail/stammdaten-korrektur-detail.component';
import { StammdatenKorrekturService } from './_services/stammdaten-korrektur.service';
import { StammdatenKorrekturDetailGuard } from './stammdaten-korrektur-detail/stammdaten-korrektur-detail.guard';
import { LeitfaedenListComponent } from './leitfaeden-list/leitfaeden-list.component';
import { DienstleisterListComponent } from './dienstleister-list/dienstleister-list.component';
import { DienstleisterDetailComponent } from './dienstleister-detail/dienstleister-detail.component';
import { DienstleisterService } from './_services/dienstleister.service';
import { DienstleisterDetailGuard } from './dienstleister-detail/dienstleister-detail.guard';
import { BenutzerListComponent } from './benutzer-list/benutzer-list.component';
import { BenutzerDetailComponent } from './benutzer-detail/benutzer-detail.component';
import { BenutzerDetailGuard } from './benutzer-detail/benutzer-detail.guard';
import { LoginComponent } from './auth/login.component';
import { TokenInterceptor } from './auth/token.interceptor';
import { PasswortResetComponent } from './passwort-reset/passwort-reset.component';
import { AnstrichService } from './_services/anstrich.service';
import { ChecklistePositionenFeldwertListComponent } from './checkliste-positionen-feldwert-list/checkliste-positionen-feldwert-list.component';
import { ChecklistePositionenFeldwertDetailComponent } from './checkliste-positionen-feldwert-detail/checkliste-positionen-feldwert-detail.component';
import { ChecklistePositionenFeldwertService } from './_services/checkliste-positionen-feldwert.service';
import { ChecklistePositionenFeldwertDetailGuard } from './checkliste-positionen-feldwert-detail/checkliste-positionen-feldwert-detail.guard';
import { AppTopBarReplacementForAppComponent } from './app.topbar.replacement.for.app.component';
import { UeberComponent } from './ueber/ueber.component';
import { QrcodegeneratorComponent } from "./qrcodegenerator/qrcodegenerator.component";
import { _QrCodeService } from "./_services/_qr-code.service";
import { MapDownloadComponent } from './map-download/map-download.component';

FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin
]);

export function loadConfig(config: AppconfigService) {
    //console.log("AppModule.loadConfig()");
    return function internalLoadConfig() {
        //console.log("AppModule.internalLoadConfig()");
        return config.load();
    }
  }

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        BlockUIModule,
        ProgressSpinnerModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineMenuComponent,
        AppRightMenuComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
        DashboardComponent,
        DashboardAnalyticsComponent,
        FormLayoutDemoComponent,
        FloatLabelDemoComponent,
        InvalidStateDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        IconsComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppLoginComponent,
        AppLandingComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppTimelineDemoComponent,
        AppWizardComponent,
        AppContactusComponent,
        BlocksComponent,
        BlockViewer,

        CRUDBasicPipe,
        CRUDBasicSignatureComponent,
        MapComponent,
        AuftragsdatenListComponent,
        ListeComponent,
        DummyComponent,
        LeistungPipe,
        BenutzerPipe,
        PhoneCanonicalPipe,
        DatumOhneZeitPipe,
        TranslatePipe,
        CRUDBasicDetailComponent, // Obwohl nie direkt verwendet: Muss hier angegeben werden, sonst gibt es compile Errors in dessen HTML-Template! (kennt die ganzen primeNG Tags nicht!)
        CRUDBasicDetailForChildComponent, // Obwohl nie direkt verwendet: Muss hier angegeben werden, sonst gibt es compile Errors in dessen HTML-Template! (kennt die ganzen primeNG Tags nicht!)
        CRUDBasicListComponent, // Obwohl nie direkt verwendet: Muss hier angegeben werden, sonst gibt es compile Errors in dessen HTML-Template! (kennt die ganzen primeNG Tags nicht!)
        CRUDBasicListForChildComponent, // Obwohl nie direkt verwendet: Muss hier angegeben werden, sonst gibt es compile Errors in dessen HTML-Template! (kennt die ganzen primeNG Tags nicht!)
        CRUDBasicInputComponent,
        CRUDBasicInputCalendarComponent,
        CRUDBasicCheckboxComponent,
        CRUDBasicAutocompleteComponent,

        AuftragsChecklisteDetailComponent,
         LeistungListComponent,
         LeistungDetailComponent,
         ChecklisteListComponent,
         ChecklisteDetailComponent,
         ChecklistePositionListComponent,
         ChecklistePositionDetailComponent,
         ChecklistePositionenFeldListComponent,
         ChecklistePositionenFeldDetailComponent,
         FAIconPipe,
         DokumentListComponent,
         DokumentDetailComponent,
         DokumentDateiUploadComponent,
         StammdatenKorrekturListComponent,
         StammdatenKorrekturDetailComponent,
         LeitfaedenListComponent,
         DienstleisterListComponent,
         DienstleisterDetailComponent,
         BenutzerListComponent,
         BenutzerDetailComponent,
         LoginComponent,
         PasswortResetComponent,
         ChecklistePositionenFeldwertListComponent,
         ChecklistePositionenFeldwertDetailComponent,
         AppTopBarReplacementForAppComponent,
         UeberComponent,
         QrcodegeneratorComponent,
         MapDownloadComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, MenuService, AppBreadcrumbService,

        TranslateService, TRANSLATION_PROVIDERS,
        ConfirmationService,
        MessageService,
        AppconfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [AppconfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuftragsdatenService,
        FormBuilder,
        ChecklisteService,
        ChecklisteFreitexteService,
        ChecklistePositionService,
        ChecklistePositionenFeldService,
        KopfdatenService,
        PositionsdatenService,
        PositionsdatenFelderService,
        PositionsdatenFotosService,

        LeistungService,
        LeistungDetailGuard,
        DokumenteService,
        BenutzerService,
        BenutzerDetailGuard,

        AuftragsChecklisteDetailGuard,
        ChecklisteDetailGuard,
        ChecklistePositionDetailGuard,
        ChecklistePositionenFeldDetailGuard,
        ChecklistePositionenFeldwertService,
        ChecklistePositionenFeldwertDetailGuard,

        DokumenteService,
        DokumentDetailGuard,

       _DateiService,
       UserTableColumnStateService,

       AnlagenbeschreibungService,
       StammdatenKorrekturService,
       StammdatenKorrekturDetailGuard,

       DienstleisterService,
       DienstleisterDetailGuard,

       AnstrichService,
       _SystemService,

       _QrCodeService,

        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
