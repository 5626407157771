import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IChecklistePositionenFeldwert } from '../_interfaces/checkliste-positionen-feldwert';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';


@Injectable()
export class ChecklistePositionenFeldwertService extends CRUDBasicService {

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'reihenfolge', type: 'int'},
    {name: 'mangel', type: 'string'},
    {name: 'kennzeichen', type: 'string'},
    {name: 'bezeichnung', type: 'string'},
    {name: 'feldwert', type: 'string'},
    {name: 'checklistePositionenFelderId', type: 'int'},
    {name: 'summary', type: 'string'}
  ];
    
  getChecklistePositionenFeldwerteCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ checklistePositionenFeldwerte: IChecklistePositionenFeldwert[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'checklistepositionenfeldwerte?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { checklistePositionenFeldwerte: <IChecklistePositionenFeldwert[]>response.body, pagination: /*<IPagination>JSON.parse(response.headers.get('x-pagination'))*/this.getDummyPagination((<any[]>response.body).length) };
        }), catchError(this.handleError))
  }

  getChecklistePositionenFeldwert(id: number): Observable<IChecklistePositionenFeldwert> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeChecklistePositionenFeldwert());
      //   observer.complete();
      // })
      return of(this.initializeChecklistePositionenFeldwert());
    }

    return this.httpClient.get<IChecklistePositionenFeldwert>(this.apiBaseUrl + 'checklistepositionenfeldwerte/?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => {
             console.log("xxxService.getKopfdaten() response: ", response)
             return response[0];
           }), catchError(this.handleError));
  }

  saveChecklistePositionenFeldwert(checklistePositionenFeldwert: IChecklistePositionenFeldwert): Observable<IChecklistePositionenFeldwert> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(checklistePositionenFeldwert); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("ChecklistePositionenFeldwertService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("ChecklistePositionenFeldwertService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (checklistePositionenFeldwert.id === 0) {
      return this.createChecklistePositionenFeldwert(/*checklistePositionenFeldwert*/itemToSave);
    }

    return this.updateChecklistePositionenFeldwert(/*checklistePositionenFeldwert*/itemToSave)

  }

  updateChecklistePositionenFeldwert(checklistePositionenFeldwert: IChecklistePositionenFeldwert): Observable<IChecklistePositionenFeldwert> {

    return this.httpClient.put<IChecklistePositionenFeldwert>(this.apiBaseUrl + 'checklistepositionenfeldwerte' /*+ checklistePositionenFeldwert.id*/, checklistePositionenFeldwert, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteChecklistePositionenFeldwert(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'checklistepositionenfeldwerte/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createChecklistePositionenFeldwert(checklistePositionenFeldwert: IChecklistePositionenFeldwert): Observable<IChecklistePositionenFeldwert> {
    return this.httpClient.post<IChecklistePositionenFeldwert>(this.apiBaseUrl + 'checklistepositionenfeldwerte', checklistePositionenFeldwert, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeChecklistePositionenFeldwert(): IChecklistePositionenFeldwert {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      reihenfolge: 0,
      mangel: '',
      kennzeichen: '',
      bezeichnung: '',
      feldwert: '',
      checklistePositionenFelderId: 0,
      summary: ''
    };
  }


}
