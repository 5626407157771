import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ViewChild, ElementRef, HostListener, Inject, forwardRef, Injector, ComponentFactoryResolver } from '@angular/core';

import { AppComponent } from '../app.component';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';
import { NavigationEnd } from '@angular/router';

import * as L from 'leaflet';
import * as awesome from 'leaflet.awesome-markers'; // Warum auch immer, aber ohne das funktioniert es nicht! Dann ist L['AwesomeMarkers'] = null!

import * as leafletSearch from 'leaflet-search'; // Warum auch immer, aber ohne das funktioniert es nicht! Dann ist L['AwesomeMarkers'] = null!
//import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
//3. Möglichkeit: https://sjaakpriester.nl/software/leaflet-search

import * as leafletLocateControl from 'leaflet.locatecontrol'; // Warum auch immer, aber ohne das funktioniert es nicht! Dann ist L['AwesomeMarkers'] = null!

//import 'overlapping-marker-spiderfier-leaflet/dist/oms'; // integrate in angular: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/issues/50
import 'overlapping-marker-spiderfier-leaflet/build/oms'; // integrate in angular: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/issues/50

import 'leaflet.markercluster/src/index.js'; // integrate in angular: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/issues/50

import * as LO from 'leaflet.offline';

import { _SystemService } from '../_services/_system.service';
import { IBenutzer } from '../_interfaces/benutzer';
import { IAuftragsdaten } from '../_interfaces/auftragsdaten';
import { AuftragsdatenService } from '../_services/auftragsdaten.service';
import { KopfdatenService } from '../_services/kopfdaten.service';
import { DokumenteService } from '../_services/dokumente.service';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import { AppconfigService } from '../_services/appconfig.service';
import {Message, MessageService} from 'primeng/api';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix
import { reduce } from 'rxjs/operators';
import { Splitter } from '@fullcalendar/core';
import { AuftragsdatenListComponent } from '../auftragsdaten-list/auftragsdaten-list.component';
import { TitleCasePipe } from '@angular/common';

import { RouteBuilderAuftragsCheckliste } from '../_helpers/route-builder-auftrags-checkliste';
import { ICheckliste } from '../_interfaces/checkliste';
import { ChecklisteService } from '../_services/checkliste.service';

import { Subscription } from 'rxjs';
import { DienstleisterService } from '../_services/dienstleister.service';
//import { faGolfFlagHole } from '@fortawesome/pro-solid-svg-icons';

import { PhoneCanonicalPipe } from '../_pipes/phone-canonical.pipe';

// https://www.digitalocean.com/community/tutorials/angular-angular-and-leaflet
// npm install leaflet
// npm install @types/leaflet
// leaflet.css: statt in angular.json -> styles.scss
// angular.json: .js

// fontawesome ...

// npm i leaflet.awesome-markers
// .css: statt in angular.json -> styles.scss
// angular.json: .js

// npm i leaflet-search
// angular.json: .js

// npm i moment

// npm i leaflet.locatecontrol

// OverlappingMarkerSpiderfier integrate in angular: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/issues/50

// npm i idb
// npm i leaflet.offline

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  //providers: [MessageService]
  host: {
    '(window:resize)': 'sizeFrame_inAppMode()'
  }
})

export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('splitter', /* TODO: add static flag */ {}) public splitter: Splitter;
  @ViewChild('auftragsdatenList', /* TODO: add static flag */ {}) public auftragsdatenList: AuftragsdatenListComponent;
  tileUrlTemplate: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  leafletMode: string = 'markercluster'; // 'spiderfier' 'simple' 'markercluster'
  debugMode: boolean = false;

  zaehlerEnabled: boolean = null;

  //offlineToggle Methoden:
  /*
  offlineToogleActualSystemState: enthält aktuellen Systemwert on|off - aus API oder bei iOS aus queryParm/_from_MAUI()
  offlineToggleGetActualNetworkStateFromAPI(): ermittelt on|off|null aus API
  offlineToggleConnectionChanged_from_MAUI(): aktualisiert on|off aus MAUI iOS App
  offlineToggleConnectionChanged_from_API(): wird per event aufgerufen, wenn die API einen connectionChange feststellt (init des events aus XXXXX)
  offlineToggleUpdateAuto(): wird aus offlineToggleInit() | offlineToggleConnectionChanged_from_*** aufgerufen !!!!!!!!!!!!!!!!
  offlineToogleChanged(): wird bei Button-Click on|off|auto aufgerufen
  offlineToggleSwitchTo(): setzt nur die Buttons in Splitbutton zu on|off|auto
  offlineToggleShowValueForAuto(): setzt unter die Buttons online|offline ein underscore (bei auto)
  */
  offlineEnable: boolean = false;
  offlineFetchCounterIst: number = 0;
  offlineFetchCounterSoll: number = 0;
  offlineFetchCounterProzent: number = 0;
  offlineSaveControl: any = null;
  offlineMode: boolean = false; // offline in der app.
  offlineToggleOptions = [
    {name: 'Offline', value: 'off'},
    {name: 'Auto', value: 'auto'},
    {name: 'Online', value: 'on'},
  ];
  offlineToggleSelected: any = null; // was innerhalb der offlineToggleOptions selected ist
  //offlineToggleAutoAtTheMoment: string = null; // on oder off // GIBT ES NICHT MEHR !!! - nur noch offlineToogleActualSystemState
  offlineToogleActualSystemState: string = null; // kommt bei iOS per query parameter + per _from_MAUI() / ansonsten aus dem event/offlineToggleConnectionChanged_from_API/offlineToggleGetActualNetworkStateFromAPI

  splitterGutterSize = /*8*/1; // weil eh nicht resizeable!

  auftragsListAlreadyRefreshed = false;

  // ein-/ausblenden - DESKTOP
  alreadyAusgeblendet = false;
  //alreadyAusgeblendetListInListMode = false; // Spezialfall: man kann auch im List-Mode die Liste ausblenden!
  alreadyAusgeblendetLastML = null; // Merker, was zuletzt ausgeblendet wurde: Liste oder Map ?
  _save_leftpanel_style_flex_basis = null;
  _save_leftpanel_style_display = null;
  _save_rightpanel_style_flex_basis = null;
  _save_rightpanel_style_display = null;
  _save_gutter_style_display = null;
  _save_panelMaxHeight = null;

  // portrait / landscape (nur bei inAppMode relevant (?))
  landscape :boolean = screen.availWidth > screen.availHeight; // Achtung: wird in setInAppPanelSizes()

  // ein-/ausblenden - inAPPMode
  inAppModeStyleGrid: string = "grid-template-columns: 100%"; // setInAppPanelSizes();
  inAppModeStyleMapContainer: string = null; // setInAppPanelSizes();
  inAppModeStyleMapFrame: string = null;
  inAppModeStyleMapMap: string = null;
  inAppModeStyleListContainer: string = null; // setInAppPanelSizes();
  
  viewType = null;
  //panelSizes = [1,99];
  panelSizes = [20,80];
  //panelSizes = null;

  splitterLayout = null;

  mode = null; // map oder list
  alreadyInitialized = false;

  panelMaxHeight = 'calc(100vH - 3.125em)'; // wird bei list oder ausblenden geändert!
  panelWidth = 'unset'; // wird bei list oder ausblenden geändert!

  benutzerDisplay3Widgets: boolean = false; // Anzeige von 3 Widgets in Toolbar ? (inkl. DL-Auswahl)

  SpiderfierNearbyDistance = 20; // https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet

  simulateNetworkIOS: boolean = false; // simuliert, die nicht vorhandene navigator.connection API (wie iOS)
  simulateNetwork2g: boolean = false; // simuliert 2g (bei android / ios)

  /******************************** */
  private map;
  private tiles;
  private OverlappingMarkerSpiderfier;
  private oms;
  private markerCluster;
  markerClusterAddedToMap: boolean = false;

  private mapMarkers:any = [];

  cluster_iconCreateFunction(cluster) { // Funktion, die die Cluster-Icons rendert
    //let defaultMarkerColor = '#08f'; // blau
    let defaultMarkerColor_R = 0; // R
    let defaultMarkerColor_G = 127; // G
    let defaultMarkerColor_B = 255; // B
    let lighterMarkerColor_R = 63; // R
    let lighterMarkerColor_G = 191; // G
    let lighterMarkerColor_B = 255; // B
    let lighterMarkerOpacity = 0.5; // B

    let _markers = cluster.getAllChildMarkers();
    //console.log("map.cluster_iconCreateFunction() _markers:", _markers);
    // ermitteln, ob ein fälliges bzw. überfälliges enthalten ist:
    // PROVISORISCH: über die color des HTML-codes // TODO: Andere Alternative ?
    let _containsRed: boolean = false;
    let _containsOrange: boolean = false;
    try {
      for(let i=0; i<_markers.length; i++) {
        if(_markers[i].options.icon.options.html.indexOf('color: red;') > -1) {
          _containsRed = true;
          break;
        }
        if(_markers[i].options.icon.options.html.indexOf('color: orange;') > -1) {
          _containsOrange = true;
          // bei orange kein break! - vielleicht kommt ja noch rot ?
        }
      }
    }
    catch (e) {
      console.error("map.cluster_iconCreateFunction() catched exception:", e);
    }

    if(_containsRed != false) {
      lighterMarkerColor_R = 255; // R
      lighterMarkerColor_G = 0; // G
      lighterMarkerColor_B = 0; // B
      lighterMarkerOpacity = 1;
    }
    else if(_containsOrange != false) {
      lighterMarkerColor_R = 255; // R
      lighterMarkerColor_G = 165; // G
      lighterMarkerColor_B = 0; // B
      lighterMarkerOpacity = 1;
    }

    let defaultSize = 40;
    let markerSize = defaultSize + (_markers.length/4);
    if(markerSize > 100) markerSize = 100;
    let frameSize = markerSize + 8;

    let _html = '<div style="width: ' + frameSize + 'px; height: ' + frameSize + 'px; line-height: ' + frameSize + 'px; background-color: rgba(' + lighterMarkerColor_R + ', ' + lighterMarkerColor_G + ', ' + lighterMarkerColor_B + ', ' + lighterMarkerOpacity + '); text-align: center; border-radius: 8em;">'
               + '<div style="width: ' + markerSize + 'px; height: ' + markerSize + 'px; line-height: ' + markerSize + 'px; background-color: rgba(' + defaultMarkerColor_R + ', ' + defaultMarkerColor_G + ', ' + defaultMarkerColor_B + ', 1); text-align: center; border-radius: 8em; position: absolute; margin-left: 0.25em; margin-top: 0.25em; color:white; font-size: 1.375em;">'
               + _markers.length + '</div></div>';
    return L.divIcon({ html: _html, className: 'mycluster', iconSize: L.point(40, 40) });
  }

  markerClusterAddRemoveToMap(add: boolean /* remove = false */) {
    console.log("map.markerCluster_AddRemoveToMap() add:", add);
    if(add == true && this.markerClusterAddedToMap != true) {
      this.map.addLayer(this.markerCluster); 
      this.markerClusterAddedToMap = true;
    }
    else if(add != true && this.markerClusterAddedToMap == true) {
      this.map.removeLayer(this.markerCluster); 
      this.markerClusterAddedToMap = false;
    }
  }

  private initMap(viewParms, zoom): void {
    console.log("map.initMap() viewParms/zoom:", viewParms, zoom);
    this.map = L.map('map', {
      //center: [ 39.8282, -98.5795 ],
      center: viewParms,
      zoom: zoom,
      zoomControl: this.app.inAppMode ? false : true // s.u. - das soll rechts platziert werden
    });
    //new L.Control.Zoom({ position: 'topright' }).addTo(this.map);

    if(this.offlineEnable == false) {
      this.tiles = L.tileLayer(this.tileUrlTemplate, {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      });

      this.tiles.addTo(this.map);
    }
    else { // offline-enabled - aus https://github.com/allartk/leaflet.offline/blob/master/docs/src/index.js
      let baseLayer = LO.tileLayerOffline(this.tileUrlTemplate, {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        maxZoom: 18,
        minZoom: 3,
        maxNativeZoom: 9, // oberhalb von Zoom 9 bleiben die tiles von 9 -> aber werden skaliert!
        minNativeZoom: 3 // scheint keine Auswirkung zu haben! trotzdem gesetzt!
      });//.addTo(this.map);
      this.tiles = baseLayer;
      this.tiles.addTo(this.map);
  
      /*this.offlineSaveControl = LO.savetiles(baseLayer, {
        zoomlevels: [3, 4, 5, 6, 7, 8, 9], // optional zoomlevels to save, default current zoomlevel
        alwaysDownload: false,
        confirm(layer, successCallback) {
          let mapComponent = window["___mapComponent"];
          mapComponent.offlineFetchCounterSoll += layer._tilesforSave.length;
          // eslint-disable-next-line no-alert
          if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
            successCallback();
          }
        },
        confirmRemoval(layer, successCallback) {
          // eslint-disable-next-line no-alert
          //if (window.confirm('Remove all the tiles?')) {
            successCallback();
          //}
        },
        saveText: '<i class="fa fa-download" title="Save tiles"></i>',
        rmText: '<i class="fa fa-trash" title="Remove tiles"></i>',
      });
      this.offlineSaveControl.addTo(this.map);
      */
  
      /*let layerswitcher = new L.Control.Layers(
        {
          'osm (offline)': baseLayer,
        },
        null,
        { collapsed: false }
      ).addTo(this.map);
      // add storage overlay
      this.offlineStorageLayer(baseLayer, layerswitcher);
    
      baseLayer.on('savestart', (e) => {
        console.log("Map.offline: savestart e:", e);
      });
      baseLayer.on('loadtileend', (e) => {
        console.log("Map.offline: loadtileend e:", e);
        let mapComponent = window["___mapComponent"];
        mapComponent.offlineFetchCounterIst++;
        mapComponent.offlineFetchCounterProzent = Math.floor(mapComponent.offlineFetchCounterIst * 100 / mapComponent.offlineFetchCounterSoll);
      });
      */
    }

    if(this.leafletMode == 'markercluster') {
      //console.log("map.initMap() (<any>window):", (<any>window));
      this.markerCluster = new L['MarkerClusterGroup'](
        {
          iconCreateFunction: this.cluster_iconCreateFunction
        }
      );
      //this.markerCluster.addTo(this.map);
      //this.map.addLayer(this.markerCluster); // noch nicht adden! (performance)
      //this.markerClusterAddRemoveToMap(true);

      //for debug
      /*this.markerCluster.on('clusterclick', function (a) {
        // a.layer is actually a cluster
        console.log('Map.markerCluster.on.clusterclick a:', a);
        console.log('Map.markerCluster.on.clusterclick cluster ' + a.layer.getAllChildMarkers().length);
      });*/
    }

    if(this.leafletMode == 'spiderfier') {
      // OverlappingMarkerSpiderfier // integrate in angular: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/issues/50
      this.OverlappingMarkerSpiderfier = (<any>window).OverlappingMarkerSpiderfier;
      console.log("map.initMap() OverlappingMarkerSpiderfier:", this.OverlappingMarkerSpiderfier);
      this.oms = new this.OverlappingMarkerSpiderfier(this.map, { nearbyDistance: this.SpiderfierNearbyDistance, keepSpiderfied: true });
      this.oms.addListener('click', function(marker) {
        console.log("map OverlappingMarkerSpiderfier click listener: marker", marker);
        let mapComponent = window["___mapComponent"];
        console.log("map OverlappingMarkerSpiderfier click listener: mapComponent", mapComponent);
        /*globalThis*/window['___lastclickedMarker'] = marker; // der android webView kann kein globalThis

        // den ge-clickten Auftrag finden
        let _clickedAuftragArr = mapComponent.fakeCRUDItem.auftraegeFiltered.filter(a => a['___marker'] == marker);
        if(_clickedAuftragArr != null && _clickedAuftragArr.length == 1) {
          let clickedAuftrag = _clickedAuftragArr[0];
          mapComponent.markerOnClick(clickedAuftrag.id);
        }

      });
    }

    if(this.offlineMode != true) {
      let _divSearch = document.getElementById("divSearch"); // falls divSearch schon mal verwendet: vorher clearen
      console.log("map.initMap() _divSearch:", _divSearch);
      _divSearch.innerText = null;
  
      let searchCtrl = new L.Control['Search']({
        container: 'divSearch',   // -> auslagern in die Kopfzeile
        // nominatum   https://nominatim.org/release-docs/latest/api/Search/
        //             https://wiki.openstreetmap.org/wiki/DE:Nominatim
        //url: 'https://nominatim.openstreetmap.org/search?format=json&q={s}&countrycodes=DE&limit=100', 
        url: 'https://nominatim.openstreetmap.org/search?format=json&city={s}&countrycodes=DE&limit=100',
        jsonpParam: 'json_callback',
        propertyName: 'display_name',
        propertyLoc: ['lat','lon'],
        marker: L.circleMarker([0,0],{radius:30}),
        autoCollapse: false,
        autoType: false,
        minLength: 2,
        textPlaceholder: 'Suche nach Ort                          '+(/*this.benutzerDisplay3Widgets==true*/false ? '' : '                    '),     // wenn in der TopBar 3 Widgets angezeigt werden (also inkl. DL-Auswahl), dann nicht ganz so viel Space für die Orts-Suche reservieren
        //autoResize: true,
        //position: 'topright',
        buildTip: this.leafletSearchCustomTip,
        //autoCollapseTime: 100000
      });
      /*searchCtrl.on('search:expanded', function () {
        this._input.onkeyup = function(){
          console.log("Map search:expanded ...");
        }
      });
      searchCtrl.on('search:collapsed', function () {
        this._input.onkeyup = function(){
          console.log("Map search:collapsed ...");
        }
      });*/
      //searchCtrl.on('search:locationfound', function (obj) {
      //  console.log("Map locationfound: obj:", obj);
      //});
  
      setTimeout(() => {
        this.map.addControl(searchCtrl);
        //L.Control['Locate']().addTo(this.map);
    
        let searchCtl = document.querySelector(".search-button");
        //searchCtl.addEventListener("click", () => {
        //	console.log("Map search:clicked ...");
        //});
    
        // suche Button autom. klicken!
        searchCtl['click']();
      }, 10);		
    }

    this.map.addControl(new L.Control['Locate']({
      icon: 'fa-solid fa-location-crosshairs fa-xl',
      strings: {popup: 'Sie befinden sich im Umkreis von {distance}m von diesem Punkt.'}, // {unit}
      //position: 'topright',
    }));
    // Event, wenn der LocationButton betätigt wurde ...
    //this.map.on('locationfound', function(e) {
    //  console.log('map.locationfound: Location gefunden:', e.latlng);
    //  this.map.setZoom(9);
    //});

    /*
    const provider = new OpenStreetMapProvider();

    const searchControl = GeoSearchControl({
      provider: provider,
    });
    
    //const map = new L.Map('map');
    this.map.addControl(searchControl);
    */

    // Zoom-Event: Overlay Anzahl sameLocationCounter aktualisieren
    this.map.on('zoomend', event => {
      //console.log("map.zoomed: event:", event);
      //console.log("map.zoomed: this:", this);
      if(this.leafletMode == 'spiderfier') {
        this.refreshMarkerCounter();
      }
      //console.log("map.zoomed: getZoom():", this.map.getZoom());
    });

    if(this.leafletMode == 'spiderfier') {
      // beim spidern die sameLocationCounter rausnehmen
      this.oms.addListener('spiderfy', markers => {
        if(this.debugMode == true) console.log("map.spiderfy: markers:", markers);
        this.fakeCRUDItem.auftraegeFiltered.forEach(auftrag => {
          let marker = auftrag['___marker'];  
          if(marker != null && marker != undefined && markers.find(f => f == marker)) {
            let ueberfaelligJahr = this.isAuftragUeberfaelligJahr(auftrag);
            let ueberfaelligTage = this.isAuftragUeberfaelligTage(auftrag);
            let sameLocationCounter = 0;
            let newIcon = this.getMarkerIcon(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter);
            if(this.debugMode == true) console.log("map.spiderfy: auftrag.id: "+auftrag.id+" sameLocationCounter: "+sameLocationCounter+" setIcon() options.html:", newIcon.options.html);
            marker.setIcon(newIcon);
            //marker.setZIndexOffset(1000*sameLocationCounter);
            marker['___sameLocationCounter'] = sameLocationCounter; // sameLocationCounter merken, damit er beim zoom abgleichen kann, ob das icon neu gezeichnet werden muss
          }
          else { // Auftrag ohne Marker (Status O ?)
            //console.log("map.zoomed: auftrag ohne ___marker:", auftrag);
          }

        });
      });

      // beim un-spidern die sameLocationCounter wieder setzen
      this.oms.addListener('unspiderfy', markers => {
        console.log("map.unspiderfy: markers:", markers);
        this.fakeCRUDItem.auftraegeFiltered.forEach(auftrag => {
          let marker = auftrag['___marker'];  
          if(marker != null && marker != undefined && markers.find(f => f == marker)) {
            let ueberfaelligJahr = this.isAuftragUeberfaelligJahr(auftrag);
            let ueberfaelligTage = this.isAuftragUeberfaelligTage(auftrag);
            let sameLocationCounter = this.countAuftragSameLocation(auftrag);
            let newIcon = this.getMarkerIcon(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter);
            if(this.debugMode == true) console.log("map.unspiderfy: auftrag.id: "+auftrag.id+" sameLocationCounter: "+sameLocationCounter+" setIcon() options.html:", newIcon.options.html);
            marker.setIcon(newIcon);
            marker.setZIndexOffset(1000*sameLocationCounter);
            marker['___sameLocationCounter'] = sameLocationCounter; // sameLocationCounter merken, damit er beim zoom abgleichen kann, ob das icon neu gezeichnet werden muss
          }
          else { // Auftrag ohne Marker (Status O ?)
            //console.log("map.zoomed: auftrag ohne ___marker:", auftrag);
          }
    
        });
      });
    }
    
  }

  //getMetresPerPixel() { // https://stackoverflow.com/questions/27545098/leaflet-calculating-meters-per-pixel-at-zoom-level
  //  const southEastPoint = this.map.getBounds().getSouthEast();
  //  const northEastPoint = this.map.getBounds().getNorthEast();
  //  const mapHeightInMetres = southEastPoint.distanceTo(northEastPoint);
  //  const mapHeightInPixels = this.map.getSize().y;
  //
  //  return mapHeightInMetres / mapHeightInPixels;
  //}

  leafletSearchCustomTip(text,val) {
		return '<div style="height: 30px; line-height: 30px; vertical-align: middle; background-color: white;"><a href="#" style="color: black; font-size: 1.0em;">'+text+'</a></div>'; // vor search in topbar: font-size: 1.25em
	}

  //leafletMarkerIconDefault = null;
  //leafletMarkerIconAufRoute = null;

  leafletMarkerIconsNormal = [];
  leafletMarkerIconsAufRoute = [];
  leafletMarkerIconsUeberfaelligJahr = [];
  leafletMarkerIconsUeberfaelligJahrAufRoute = [];
  leafletMarkerIconsUeberfaelligTage = [];
  leafletMarkerIconsUeberfaelligTageAufRoute = [];
  leafletMarkerIconsOverlayCounterTemplate: string = null;
  leafletMarkerIconsOverlayDisponiertTemplate: string = null;

  //leafletMarkerIconYourPosition = null;
  
  addMarker(auftrag:IAuftragsdaten, ueberfaelligJahr: boolean, ueberfaelligTage: boolean, sameLocationCounter: number, arrayOfMarkers: L.Marker[]) {
    if(this.leafletMode == 'markercluster') { // bei markercluster nicht über timeout, weil sowieso per addlayerS (Array!) gemacht wird!
      this.addMarker_pt2(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter, arrayOfMarkers);
    }
    else {
      setTimeout(() => {
        this.addMarker_pt2(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter, arrayOfMarkers);
      }, 50);		
    }
  }

  addMarker_pt2(auftrag:IAuftragsdaten, ueberfaelligJahr: boolean, ueberfaelligTage: boolean, sameLocationCounter: number, arrayOfMarkers: L.Marker[]) {
    //console.log('Map.addMarker() setting marker/latlng:', auftrag.name_1Behaelterstandort, auftrag.geodaten_Breite,auftrag.geodaten_Laenge);
      
    // ist der Marker schon in der Routenplanung ?
    //let found = this.app.auftraegeRoutenplanung.find(f => f.id == auftrag.id);

    let marker=L.marker(/*[auftrag.geodaten_Breite,auftrag.geodaten_Laenge]*/auftrag['_leafletLocation'],{
      //riseOnHover: true,
      zIndexOffset: 1000*sameLocationCounter,
      draggable: false,
      autoPan: true,
      title: auftrag.name_1Behaelterstandort + (this.debugMode == true ? " "+auftrag.id : "" ),
      //bubblingMouseEvents: auftrag.mainMarker,
      //color: '#ff0000',
      //icon: auftrag.mainMarker == true ? redIcon : blueIcon
      icon: this.getMarkerIcon(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter)   //found != null ? this.leafletMarkerIconAufRoute : this.leafletMarkerIconDefault
    });
    marker['___sameLocationCounter'] = sameLocationCounter; // sameLocationCounter merken, damit er beim zoom abgleichen kann, ob das icon neu gezeichnet werden muss

    // den Marker mit den Auftragsdaten verknüpfen
    auftrag['___marker'] = marker;

    if(this.leafletMode != 'markercluster') {
      marker.addTo(this.map)
        //.bindPopup("<b>"+auftrag.name_1Behaelterstandort+"</b><br>Leistung: "+auftrag.leistung+"<br>"+auftrag.strasseBehaelterstandort+"<br>"+auftrag.ortBehaelterstandort+"<br>"
        //+ ( !found ? "<a onclick='window[\"___mapComponent\"].markierenClicked("+auftrag.id+")' href='javascript:void(0);'>für Route planen</a>" : "<a onclick='deMarkierenClicked("+auftrag.id+")' href='javascript:void(0);'>von Route entfernen</a>")
        //)
        .on('dragend', function(e) {
        console.log('Map.addMarker() marker dragend at:', e.target._latlng);
        /*setTimeout(function(e2) {
          popup
          .setLatLng(e.target._latlng)
          .setContent("You moved to " + e.target._latlng.toString())
          .openOn(map);
        }, 10);*/
      });
    }
    if(this.leafletMode != 'spiderfier') {
      //OverlappingMarkerSpiderfier: kein marker.on.click!, sondern zentral auf OMS (siehe oben)
      marker.on('click', function () { /*globalThis*/window['___lastclickedMarker'] = marker; window["___mapComponent"].markerOnClick(auftrag.id)}) // der android webView kann kein globalThis
    }
    if(this.leafletMode == 'markercluster') {
      // nicht jeden Marker einzeln, sondern über array zurückgeben - und anschliessend auf 1mal adden
      if(arrayOfMarkers != null) {
        arrayOfMarkers.push(marker);
      }
      else {
        this.markerCluster.addLayer(marker); 
      }
    }

    
    if(this.debugMode == true) {
      let circle = L.circle(auftrag['_leafletLocation'], {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 1,
        radius: 500,
        className: 'myLeafletCircle'
      });
      circle.addTo(this.map);
    }
      

    // mark own marker to red - others to different blue (not default)
    //if(auftrag.mainMarker == true) {
      //marker._icon.classList.add("huechangeToRed"); // der android webView kann das nicht
    //}
    //else {
      //marker._icon.classList.add("huechangeToOtherBlue");
    //}
      
    // setview zu marker - and open popup
    //if(auftrag.mainMarker == true) {
      //map.setView([markerlatlng.lat, markerlatlng.lng]/*, 2*/); // ,2 wäre der zoomFaktor
      ///*globalThis*/window.___lastclickedMarker = marker; // der android webView kann kein globalThis
      //marker.openPopup();
    //}

    if(this.leafletMode == 'spiderfier') {
      this.oms.addMarker(marker); // OverlappingMarkerSpiderfier integrate in angular: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/issues/50
    }
  
    this.mapMarkers.push(marker);
  }

  refreshMarkerCounter() { // wird nicht nur nach zoomend aufgerufen, sondern auch einmalig NACH init, weil bei 1. Bestückung (init) gibt es noch keine anderen Marker gegen die er abgleichen kann.
    console.log("map.refreshMarkerCounter()");
      //let currentZoom = this.map.getZoom();
      //let metresPerPixel = this.getMetresPerPixel();
      //console.log("map.zoomed: metresPerPixel:", metresPerPixel);

      this.fakeCRUDItem.auftraegeFiltered.forEach(auftrag => {
        let marker = auftrag['___marker'];  
        if(marker != null && marker != undefined) {
          let sameLocationCounter = this.countAuftragSameLocation(auftrag);
          if(sameLocationCounter != marker['___sameLocationCounter']) { // abgleichen, ob das icon neu gezeichnet werden muss
            let ueberfaelligJahr = this.isAuftragUeberfaelligJahr(auftrag);
            let ueberfaelligTage = this.isAuftragUeberfaelligTage(auftrag);
            let newIcon = this.getMarkerIcon(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter);
            if(this.debugMode == true) console.log("map.refreshMarkerCounter(): auftrag.id: "+auftrag.id+" sameLocationCounter: "+sameLocationCounter+" setIcon() options.html:", newIcon.options.html);
            marker.setIcon(newIcon);
            marker.setZIndexOffset(1000*sameLocationCounter);
            marker['___sameLocationCounter'] = sameLocationCounter; // sameLocationCounter merken, damit er beim zoom abgleichen kann, ob das icon neu gezeichnet werden muss
          }
          else { 
            console.log("map.refreshMarkerCounter(): marker icon muss nicht neu gezeichnet werden! auftrag: ", auftrag);
          }
        }
        else { // Auftrag ohne Marker (Status O ?)
          //if(this.debugMode == true) {
          //  console.log("map.refreshMarkerCounter: auftrag ohne ___marker:", auftrag);
          //}
        }

      });
  }

  removeMarker(auftrag:IAuftragsdaten) {
    console.log("map.removeMarker() auftrag:", auftrag);
    
    // Wichtig: in this.auftraege sofort mit O markieren, weil das bei Click auf den Marker abgefragt wird (sonst könnte man während des Ausblendens nochmal klicken)
    let auftragFoundInAuftraege = this.auftraege.find(f => f.id == auftrag.id)[0];
    if(auftragFoundInAuftraege != null) {
      auftragFoundInAuftraege.status = 'O';
    }

    let auftragFound = this.fakeCRUDItem.auftraegeFiltered.filter(a => a.id == auftrag.id)[0];
    let marker = auftragFound['___marker'];
    if(marker != null && marker != undefined) {
      console.log("map.removeMarker() marker:", marker);

      //marker.on('click', function () { /* do nothing! */});

      this.dialogAuftragShow = false;

      let markerIcon = marker.options.icon;
      let markerIconFadeout =  cloneDeep(markerIcon);
      console.log("map.removeMarker() markerIconFadeout:", markerIconFadeout);
      markerIconFadeout.options.className += " fadeout"; 
      marker.setIcon(markerIconFadeout);

      this.fakeCRUDItem.auftraegeFiltered = this.fakeCRUDItem.auftraegeFiltered.filter(f => f != auftragFound);
      if(this.mode == 'map') {
        this.auftragsdatenList.crudItems = this.auftragsdatenList.crudItems.filter(a => a.id != auftragFound.id);
      }
      else {
        let auftragFoundInList = this.auftragsdatenList.crudItems.filter(a => a.id == auftragFound.id)[0];
        auftragFoundInList.status = 'O';
        auftragFoundInList['_pruefergebnis'] = 'Abgeschlossen';
      }


      setTimeout(() => {
        //console.log("map.removeMarker() mapMarkers length before:", this.mapMarkers.length);
        this.mapMarkers = this.mapMarkers.filter(f => f != marker);
        //console.log("map.removeMarker() mapMarkers length after:", this.mapMarkers.length);
        
        if(this.leafletMode == 'markercluster') { // "gleiche Logik" wie bei setAuftraegeMarker() ... "alle bisherigen löschen (bei refresh)"
          this.markerCluster.removeLayer(marker);
        }
        else {
          //this.map.removeLayer(marker);
          marker.remove();
        }
        
        marker.setIcon(markerIcon);
      }, 1500);		
    }
  }

  markerOnClick(auftragId: number) {
    console.log('Map.markerOnClick() auftragId:', auftragId);
    let thisComponent = /*globalThis*/window['___mapComponent']; // der android webView kann kein globalThis
    console.log('Map.markerOnClick() thisComponent:', thisComponent);
    let auftrag = thisComponent.auftraege.find(f => f.id == auftragId);
    if(auftrag != null) {
      if(auftrag.status == 'O') { // falls inzw. abgeschlossen 
        this.messageWrapperService.postTimedMessage({ severity: 'info', summary: "Aufgabe", detail: "Die Aufgabe ist bereits abgeschlossen." }); 
        return;
      }
      let auftragInRoutenplanung = thisComponent.app.auftraegeRoutenplanung.find(f => f.id == auftragId);
      thisComponent.lastclickedAuftrag_in_Routenplanung = (auftragInRoutenplanung != null);
      thisComponent.lastclickedAuftrag = auftrag;
      thisComponent.refreshLastclickedAuftrag_Checkliste(auftrag);
      console.log('Map.markerOnClick() lastclickedAuftrag:', thisComponent.lastclickedAuftrag);
      thisComponent.dialogAuftragShow = true;
    }
  }

  navigationClicked(id: number) {
    console.log('Map.navigationClicked() lastclickedMarker:', /*globalThis*/window['___lastclickedMarker']); // der android webView kann kein globalThis
    console.log('Map.navigationClicked() id:', id); 

    if(this.app.inAppMode == true) {
      let found = this.auftraege.find(f => f.id == id);
      if(found != null) {
        setTimeout(() => {
          let eventData = {
              eventType: "navigate",
              data: JSON.stringify(
              {
                  auftragId: id,
                  longitude: found.geodaten_Laenge,
                  latitude: found.geodaten_Breite,
                  strasse: found.strasseBehaelterstandort,
                  plz: found.plzBehaelterstandort,
                  ort: found.ortBehaelterstandort,
                  ortzusatz: found.ortzusatzBehaelterstandort
              })
          };
          this.app.sendMessageToDotNet(eventData);
        }, 10);		
      }
    }
    else {
      ///*globalThis*/window.___lastclickedMarker.dragging.enable(); // der android webView kann kein globalThis
      this.map.closePopup(); 

      let found = this.auftraege.find(f => f.id == id);
      if(found != null) {
        //this.app.auftraegeRoutenplanung.push(found);
        let url = 'https://www.google.de/maps/place/'+found.geodaten_Breite+','+found.geodaten_Laenge;
        let urlEncoded = encodeURI(url);
        console.log("Map.navigationClicked() urlEncoded:", urlEncoded);
        window.open(urlEncoded, "DLP_Navigation");
      }

      this.dialogAuftragShow = false;
      //this.lastclickedAuftrag_in_Routenplanung = null;
      //this.lastclickedAuftrag = null;
      //this.refreshLastclickedAuftrag_Checkliste(null);

      //let leafletMarkerIcon = window['___lastclickedMarker'];
      //let ueberfaelligJahr = this.isAuftragUeberfaelligJahr(found);
      //let marker = this.getMarkerIcon(found, ueberfaelligJahr);
      //leafletMarkerIcon.setIcon(marker);
   }
  }

  phoneNrClicked(phoneNr) {
    console.log('Map.phoneNrClicked() phoneNr:', phoneNr);
    let pipe = new PhoneCanonicalPipe();
    let transformed = pipe.transform(phoneNr);
    console.log('Map.phoneNrClicked() transformed:', transformed);

    setTimeout(() => {
      let eventData = {
          eventType: "phone",
          data: JSON.stringify(
          {
              phoneNr: transformed
          })
      };
      this.app.sendMessageToDotNet(eventData);
    }, 10);		
  }

  eMailClicked(eMail) {
    console.log('Map.eMailClicked() phoneNr:', eMail);

    setTimeout(() => {
      let eventData = {
          eventType: "eMail",
          data: JSON.stringify(
          {
              eMail: eMail
          })
      };
      this.app.sendMessageToDotNet(eventData);
    }, 10);		
  }

  markierenClicked(id: number) {
    console.log('Map.markierenClicked() lastclickedMarker:', /*globalThis*/window['___lastclickedMarker']); // der android webView kann kein globalThis
    console.log('Map.markierenClicked() id:', id); 
    ///*globalThis*/window.___lastclickedMarker.dragging.enable(); // der android webView kann kein globalThis
    this.map.closePopup(); 

    let found = this.auftraege.find(f => f.id == id);
    if(found != null) {
      this.app.auftraegeRoutenplanung.push(found);
      this.messageService.add({key:'app.main', severity:'success', summary: this.benutzer != null && this.benutzer.istAdmin == true ? 'Markieren' : 'Route', 
      detail: this.benutzer != null && this.benutzer.istAdmin == true ? 'Auftrag wurde markiert.' : 'Auftrag wurde in die Routenplanung aufgenommen.'});
      console.log('Map.markierenClicked() auftraegeRoutenplanung:', this.app.auftraegeRoutenplanung); 
    }

    this.dialogAuftragShow = false;
    this.lastclickedAuftrag_in_Routenplanung = null;
    this.lastclickedAuftrag = null;
    this.refreshLastclickedAuftrag_Checkliste(null);

    let leafletMarkerIcon = window['___lastclickedMarker'];
    let ueberfaelligJahr = this.isAuftragUeberfaelligJahr(found);
    let ueberfaelligTage = this.isAuftragUeberfaelligTage(found);

    // sameLocationCounter nicht zählen, sondern den beim Marker gespeicherten Wert nehmen. Weil: wenn gerade spiderfied ist ja immer 0! -> soll 0 bleiben!
    //let sameLocationCounter = this.countAuftragSameLocation(found); 
    let sameLocationCounter = 0;
    let existingMarker = found['___marker'];  
    if(existingMarker != null && existingMarker != undefined) {
      sameLocationCounter = existingMarker['___sameLocationCounter'];
    }

    let markerIcon = this.getMarkerIcon(found, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter);
    leafletMarkerIcon.setIcon(markerIcon);
  }

  demarkierenClicked(id: number) {
    console.log('Map.demarkierenClicked() lastclickedMarker:', /*globalThis*/window['___lastclickedMarker']); // der android webView kann kein globalThis
    console.log('Map.demarkierenClicked() id:', id); 
    ///*globalThis*/window.___lastclickedMarker.dragging.enable(); // der android webView kann kein globalThis
    this.map.closePopup(); 

    let found = this.app.auftraegeRoutenplanung.find(f => f.id == id);
    if(found != null) {
      this.app.auftraegeRoutenplanung = this.app.auftraegeRoutenplanung.filter(f => f.id != id)
      this.messageService.add({key:'app.main', severity:'warn', summary: this.benutzer != null && this.benutzer.istAdmin == true ? 'Markieren' : 'Route', 
      detail: this.benutzer != null && this.benutzer.istAdmin == true ? 'Die Markierung des Auftrags wurde aufgehoben.' : 'Auftrag wurde von der Routenplanung entfernt.'});
      console.log('Map.demarkierenClicked() auftraegeRoutenplanung:', this.app.auftraegeRoutenplanung); 
    }

    this.dialogAuftragShow = false;
    this.lastclickedAuftrag_in_Routenplanung = null;
    this.lastclickedAuftrag = null;
    this.refreshLastclickedAuftrag_Checkliste(null);

    let leafletMarkerIcon = window['___lastclickedMarker'];
    let ueberfaelligJahr = this.isAuftragUeberfaelligJahr(found);
    let ueberfaelligTage = this.isAuftragUeberfaelligTage(found);

    // sameLocationCounter nicht zählen, sondern den beim Marker gespeicherten Wert nehmen. Weil: wenn gerade spiderfied ist ja immer 0! -> soll 0 bleiben!
    //let sameLocationCounter = this.countAuftragSameLocation(found); 
    let sameLocationCounter = 0;
    let existingMarker = found['___marker'];  
    if(existingMarker != null && existingMarker != undefined) {
      sameLocationCounter = existingMarker['___sameLocationCounter'];
    }

    let markerIcon = this.getMarkerIcon(found, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter);
    leafletMarkerIcon.setIcon(markerIcon);
  }

  /******************************** */

  auftraege: IAuftragsdaten[] = null;
  auftraegeListShow: boolean = true;
  auftraegeListAppModeShow: boolean = false;
    fakeCRUDItem = {
    auftraegeFiltered: /*IAuftragsdaten[]*/ null
  };
  auftraegeLastRetrieveFilter: string; // Merker, mit welchem Filter (Dienstleister) zuletzt von der API geholt wurde
  
  //auftraegeRoutenplanung: IAuftragsdaten[] = []; // -> app.component

  lastclickedAuftrag: IAuftragsdaten = null;
  lastclickedAuftrag_in_Routenplanung: boolean = null;
  lastclickedAuftrag_Checkliste: ICheckliste = null;
  dialogAuftragShow: boolean = false;
  dialogEditNotizShow: boolean = false;

  todayYYYYMMDD: string = null;
  thisYearYYYY: string = null;
  ueberfaelligTageDatumYYYYMMDD: string = null;

  //leistungen = [
  //  {leistung: '1', icon: 'wrench'},
  //  {leistung: '2', icon: 'screwdriver'},
  //  {leistung: '*', icon: 'hammer'},
  //];

  benutzer: IBenutzer = null;
  benutzerSubscription: Subscription = null;

  // "(kein) Aufkleber anbringen"
  aufkleberVertragsartenKeinAufkleber: string = null;
  aufkleberTextAufkleber: string = null;
  aufkleberTextKeinAufkleber: string = null;

  // Ansprechpartner Text
  versorgerAnsprechpartnerText: string = null;

  // Lagerungsarten
  lagerungsarten: { [key: string]: string } = {
    '01': 'oberirdisch',
    '02': 'erdgedeckt',
    '03': 'im Raum',
    '04': 'Semi',
    '05': 'unter Erdgleiche',
    '06': 'erdgedeckt >0,5m',
    '07': 'überfahrbar bis 0,5m',
    '08': 'überfahrbar >0,5m'
  };


  constructor(
    @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private config: AppconfigService,
    private _systemService: _SystemService,
    private messageService: MessageService,
    private auftragsdatenService: AuftragsdatenService,
    private kopfdatenService: KopfdatenService,
    private router: Router,
    private route: ActivatedRoute,
    private dokumenteService: DokumenteService,
    private messageWrapperService: MessageWrapperService,
    private checklisteService: ChecklisteService,
    private dienstleisterService: DienstleisterService,
  ) { }

  ngOnInit(): void {
    console.log("Map.ngOnInit()");

    // OFFLINE-MODE ? query parameters per JS holen (kein ng Router!)
    let searchParams = new URLSearchParams(window.location.search); // https://sentry.io/answers/how-to-get-values-from-urls-in-javascript/
    // im Prinzip erkennt er den offline-mode eh schon aufgrund AppConfigService, man kann aber alternativ (zum Test) auch ?offline=T mitgeben

    if((searchParams.has('offline') && searchParams.get('offline') == 'T') || this.config.get('dataMode').localeCompare/*("online") != 0*/("maui") == 0) {
      this.offlineEnable = true;
      this.offlineMode = true;
      this.app.inAppMode = true; 
      console.log("Map.ngOnInit() OFFLINE: getting searchParams:", searchParams);
    }
    if(searchParams.has('network')) {
      this.offlineToogleActualSystemState = searchParams.get('network'); // Variante IOS-App: kommt initial per parm, dann per update-calls über _from_MAUI()
    }
    else {
      this.offlineToogleActualSystemState = this.offlineToggleGetActualNetworkStateFromAPI(this); // Variante Android / Windows
      if(this.offlineToogleActualSystemState == null) this.offlineToogleActualSystemState = /*"auto"*/"on"; // Sollte eigentlich nicht vorkommen, wenn doch, dann = on
    }

    let offlineEnable = this.config.get('enableOfflineMap');
    if(offlineEnable != null && offlineEnable == true) {
      this.offlineEnable = true;  
    }

    let debug_Offline = this.config.get('debug_Offline');
    if(debug_Offline != null && debug_Offline == true) {
      this.debugMode = true;
      this.offlineEnable = true;  
    }

    if(this.app.inAppMode == true) {
      this.setInAppPanelSizes();
    }

    let queryParmSubscription = this.route.queryParams.subscribe(params => {
      let routerUrlWithoutParameters = this.router.url;
      let idx = routerUrlWithoutParameters.indexOf("?");
      if(idx >= 0) {
          routerUrlWithoutParameters = routerUrlWithoutParameters.substring(0, idx);
      }
      switch(routerUrlWithoutParameters) {
        case "/":
          this.mode = 'map';
          this.panelSizes = [20,80];
          this.panelWidth = '100%';
          this.splitterLayout = 'horizontal';

          setTimeout(() => { // Karte zunächst mit ausgeblendeter Liste starten
            this.showList_changed();
          }, 10);
          
          break;
        case "/map":
          this.mode = 'map';
          this.panelSizes = [20,80];
          this.panelWidth = '100%';
          this.splitterLayout = 'horizontal';

          setTimeout(() => { // Karte zunächst mit ausgeblendeter Liste starten
            this.showList_changed();
          }, 10);
          
          break;
        case "/list":
          this.mode = 'list';
          this.panelSizes = [64,36];
          this.panelMaxHeight = 'calc(100vH - 20.0em)'; // US 34045 Fix (bei Einführung virtualScroll bemerkt:) 21em -> 20em (= topbar height ???)
          this.panelWidth = '100vW';
          this.splitterLayout = 'vertical';
          break;
      }
      
      setTimeout(() => { 
        queryParmSubscription.unsubscribe(); // bei Reuse nicht nochmal reagieren !!!
      }, 10);
      
    });

    /*// auf reuse (RouteReuseStrategy) reagieren: https://stackoverflow.com/questions/50392691/angular-5-how-to-reload-current-data-when-i-use-routereusestrategy
    this.router.onSameUrlNavigation = 'reload';
    this.router.events.subscribe(event => {
      if ((event instanceof NavigationEnd)) {
        let navigationEnd: NavigationEnd = <NavigationEnd>event;
        if (navigationEnd.url != null) {
          //console.log("Map event NavigationEnd.url:", navigationEnd.url);

          let url: string = navigationEnd.url;
          if (url.startsWith("/" + "map") || url.startsWith("/" + "list")) {
            console.log("Map reuse MapComponent!");
            // Eigentlich sollte die map/list nur beim zurückgehen aus der Aufgabe reused werden
            // falls doch müssen dann die Wiederaufsetzparameter url/lat/lng berücksichtigt werden!
            let zoomStr = this.route.snapshot.queryParams['zoom'];
            let latStr = this.route.snapshot.queryParams['lat'];
            let lngStr = this.route.snapshot.queryParams['lng'];
            let zoom = zoomStr != null ? Number(zoomStr) : null;
            let lat = latStr != null ? Number(latStr) : null;
            let lng = lngStr != null ? Number(lngStr) : null;
            
            let latlng:any = null;
            if(lat != null && lng != null) {
              latlng = [lat, lng];
              //this.map.panTo(viewParms);
              setTimeout(() => {
                this.map.setView(latlng, zoom);
              }, 1000);		

            }
            console.log("Map reuse latlng:", latlng);
          }
        }
      }
    });*/
  }

  ngAfterViewInit(): void {  
    console.log("MapComponent.ngAfterViewInit()");
    /*globalThis*/window['___lastclickedMarker'] = null; // der android webView kann kein globalThis
    /*globalThis*/window['___mapComponent'] = this; // der android webView kann kein globalThis

    let dateoptions = {year: 'numeric', month: 'numeric', day: 'numeric' };
    //let dateDD_MM_YYYY = new Date()['toLocaleDateString']('de-DE', dateoptions);
    //this.todayYYYYMMDD = dateDD_MM_YYYY.substring(6)+dateDD_MM_YYYY.substring(3,5)+dateDD_MM_YYYY.substring(0,2);
    this.todayYYYYMMDD = moment(new Date()).format('YYYYMMDD');
    this.thisYearYYYY = moment(new Date()).format('YYYY');

    this._systemService.getSettings().subscribe(settings => {
      if(settings != null) {
        console.log("MapComponent.ngAfterViewInit() got settings:", settings);
        let ueberfaelligTageDatum = new Date();
        ueberfaelligTageDatum.setDate(ueberfaelligTageDatum.getDate() - settings.ueberfaelligTage);
        this.ueberfaelligTageDatumYYYYMMDD = moment(ueberfaelligTageDatum).format('YYYYMMDD');
        this.aufkleberVertragsartenKeinAufkleber = settings.aufkleberVertragsartenKeinAufkleber;
        this.aufkleberTextAufkleber = settings.aufkleberTextAufkleber;
        this.aufkleberTextKeinAufkleber = settings.aufkleberTextKeinAufkleber;
        this.versorgerAnsprechpartnerText = settings.versorgerAnsprechpartnerText;
        this.zaehlerEnabled = settings.zaehlerEnabled;

        this.benutzerSubscription = this.app.benutzerBehaviourSubject.subscribe(benutzer => {
          if(benutzer != null) {
            console.log("MapComponent.ngAfterViewInit() got benutzer:", benutzer);
            this.benutzer = benutzer;

            this.benutzerDisplay3Widgets = this.app.getDisplay3WidgetsInToolbar(benutzer);

            // wenn der Benutzer nur seine eigenen sehen (oder umschalten) darf, dann jetzt setzen!
            // das passiert zwar auch in topbar/topbar.replacement, aber wichtig ist, dass es hier passiert,
            // BEVOR er die Aufträge einstellt (Filter muss gesetzt sein)
            if(benutzer.filterung == 'E' || benutzer.filterung == 'U') {
              this.app.setfilterBenutzerNurMeine();
            }

            setTimeout(() => {
              this.benutzerSubscription.unsubscribe(); 
            }, 10);

            this.getAuftraege();
          }
          //else {
          //  console.log("MapComponent.ngAfterViewInit() benutzer == null");
          //}
        });
      }
      //else {
      //  console.log("MapComponent.ngAfterViewInit() settings == null");
      //}
    });
  }

  getAuftraege() {
    console.log("MapComponent.getAuftraege()");
    let thisInstance = this;
    if(this.app.topbarComponent.isDienstleisterAuswahlAvailable()) {
      let dienstleisterCommaSeparated = "";
      if(thisInstance.app.filterDienstleisterSelected != null && thisInstance.app.filterDienstleisterSelected.length > 0) {
        thisInstance.app.filterDienstleisterSelected.forEach(dienstleister => {
          if(dienstleisterCommaSeparated.length > 0) dienstleisterCommaSeparated += ",";
          dienstleisterCommaSeparated += dienstleister.id;
        });
      }
      else {
        dienstleisterCommaSeparated = "0"; // kein ausgewählt -> mit 0 suchen => findet eh Nichts!

        // Achtung: Falls es eine (localStorage) gespeicherte Auswahl der Dienstleister gibt, dann wird sowieso gleich 
        // (aus AppComponent) ein Refresh erfolgen!   Dann hier keine Meldung!
        let storedDL = localStorage.getItem("SELECTED_DIENSTLEISTER");
        if(storedDL == null) {
          this.messageWrapperService.postTimedMessage({ severity: 'info', summary: "Dienstleister", detail: "Bitte wählen Sie Dienstleister aus - und klicken Sie anschliessend das Aktualisieren-Symbol!"}); 
        }
      }
      thisInstance.auftraegeLastRetrieveFilter = dienstleisterCommaSeparated;
      this.auftragsdatenService.getAuftragsdatenForDienstleisterIn(dienstleisterCommaSeparated).subscribe(function (response) {
        console.log("Map.getAuftraege() response from getting Auftragsdaten (in). response:", response);
        thisInstance.onAuftraegeRetrieved(response.auftragsdaten);
      }, function (error) { 
        console.error("Map.getAuftraege() error getting Auftragsdaten (in). error:", error);
        return this.handleError(error); 
      });
    }
    else {
      thisInstance.auftraegeLastRetrieveFilter = ""+this.benutzer.dienstleisterId;
      this.auftragsdatenService.getAuftragsdatenForDienstleister(this.benutzer.dienstleisterId).subscribe(function (response) {
        console.log("Map.getAuftraege() response from getting Auftragsdaten (dienstleister). response:", response);
        thisInstance.onAuftraegeRetrieved(response.auftragsdaten);
      }, function (error) { 
        console.error("Map.getAuftraege() error getting Auftragsdaten (dienstleister). error:", error);
        return this.handleError(error); 
      });
    }
    /*this.onAuftraegeRetrieved(
      [{
        "id":212343,"createdBy":null,"created":null,"modifiedBy":null,"modified":null,"rowVersion":"AAAAAAAAB9Q=","auftraggeber":"1",
        "rechnungsempfaenger":"1","nrVersorgungsunternehmen":"1","niederlassungdesVersorgers":"1","kundennummer":"10000",
        "vorgangsart":"1","auftragsdatum":"23.07.2022","leistung":"1","auftragsbemerkung":"2","bestellnummer":"12345",
        "bestellposition":"1","endedatum":"25.07.2022","terminabspracheerforderlich":"1","anredekennzeichenKunde":"1",
        "name_1Kunde":"Voigt Name1","name_2Kunde":"Name2","strasseKunde":"Reinhold-Würth-Str. 19","plzKunde":"74360","ortKunde":"Ilsfeld",
        "ortzusatsKunde":null,"telefonKunde":"017777777","bemerkungzumKunden":null,"anredekennzeichen":"1",
        "name_1Behaelterstandort":"Voigt Name1 Behälter","name_2Behaelterstandort":null,"strasseBehaelterstandort":"Reinhold-Würth-Str. 19 B",
        "plzBehaelterstandort":"74360","ortBehaelterstandort":"Ilsfeld","ortzusatzBehaelterstandort":null,
        "telefonBehaelterstandort":null,"bemerkungzumBehaelterstandort":null,"behaelternummer":"BEHNR.",
        "hersteller":"Hersteller 1","baujahr":"2010","behaeltervolumen":"1900","fuellvolumen":"2100","lagerart":"1",
        "schutzart":"1","ersteAbnahmePruefung":"01.01.2010","letztePruefungvorInbetriebnahme":"01.01.2015",
        "letzteInnerePruefungAnlagenpruefung":"01.01.2015","letzteDruckPruefungFestigkeitspruefung":"",
        "letzteAeusserePruefung":"","baumusternummerBehaelter":null,"peilrohrlaenge":null,"sicherheitsventilNr":null,
        "technischerLieferstopp":null,"verwaltungsdaten":null,
        "geodaten_Breite":49.0644,"geodaten_Laenge":9.2639,
        //"geodaten_Breite":49.065546,"geodaten_Laenge":9.26948,
        "geoLocation":null,
        "ankaNr":null,"inbetriebnahmeKKSAnlage":null,"letztePruefungKKSAnlage":null,
        "anKa_Nr_AnlagenschluesselDruck_Anl":null,"anKa_Nr_Anlagenschluessel_Ex_Anl":null,
        "nutzungsart":1,"anlagenbeschreibung":"1","avisierungsart":null,
        "kontaktDaten_Avisierung_Telefon":"1","kontaktdaten_Avisierung_SMS":"1","kontaktdaten_Avisierung_EMail":"1",
        "dienstleister":1},
        {"id":212344,"createdBy":null,"created":null,"modifiedBy":null,"modified":null,
        "rowVersion":"AAAAAAAAB9o=","auftraggeber":"1","rechnungsempfaenger":"1","nrVersorgungsunternehmen":"1",
        "niederlassungdesVersorgers":"1","kundennummer":"10000","vorgangsart":"1","auftragsdatum":"23.09.2022",
        "leistung":"2","auftragsbemerkung":"2","bestellnummer":"12345","bestellposition":"1","endedatum":"25.09.2022",
        "terminabspracheerforderlich":"1","anredekennzeichenKunde":"1","name_1Kunde":"Merkle Name1","name_2Kunde":"Name2",
        "strasseKunde":"Danziger Str. 3/1","plzKunde":"71711","ortKunde":"Steinheim","ortzusatsKunde":null,
        //"strasseKunde":"Danziger Str. 3","plzKunde":"71711","ortKunde":"Steinheim","ortzusatsKunde":null,
        "telefonKunde":"0049188888","bemerkungzumKunden":null,"anredekennzeichen":"1",
        "name_1Behaelterstandort":"Merkle Name1 Behälter","name_2Behaelterstandort":null,
        "strasseBehaelterstandort":"Danziger Str. 3 B","plzBehaelterstandort":"71711","ortBehaelterstandort":"Steinheim",
        "ortzusatzBehaelterstandort":null,"telefonBehaelterstandort":null,"bemerkungzumBehaelterstandort":null,
        "behaelternummer":"BEHNR.","hersteller":"Hersteller 1","baujahr":"2010","behaeltervolumen":"1900",
        "fuellvolumen":"2100","lagerart":"1","schutzart":"1","ersteAbnahmePruefung":"01.01.2010",
        "letztePruefungvorInbetriebnahme":"01.01.2015","letzteInnerePruefungAnlagenpruefung":"01.01.2015",
        "letzteDruckPruefungFestigkeitspruefung":"","letzteAeusserePruefung":"","baumusternummerBehaelter":null,
        "peilrohrlaenge":null,"sicherheitsventilNr":null,"technischerLieferstopp":null,"verwaltungsdaten":null,
        "geodaten_Breite":48.965511,"geodaten_Laenge":9.27868,
        //"geodaten_Breite":48.970983,"geodaten_Laenge":9.27516,  // https://www.revilodesign.de/tools/google-maps-latitude-longitude-finder/   Danziger Str. 3/1
        //"geodaten_Breite":48.9709959,"geodaten_Laenge":9.2750162,  // https://www.revilodesign.de/tools/google-maps-latitude-longitude-finder/  Danziger Str. 3
        "geoLocation":null,"ankaNr":null,
        "inbetriebnahmeKKSAnlage":null,"letztePruefungKKSAnlage":null,"anKa_Nr_AnlagenschluesselDruck_Anl":null,
        "anKa_Nr_Anlagenschluessel_Ex_Anl":null,"nutzungsart":1,"anlagenbeschreibung":"1","avisierungsart":null,
        "kontaktDaten_Avisierung_Telefon":"1","kontaktdaten_Avisierung_SMS":"1","kontaktdaten_Avisierung_EMail":"1",
        "dienstleister":1
      },
      {"id":212345,"createdBy":null,"created":null,"modifiedBy":null,"modified":null,
      "rowVersion":"AAAAAAAAB9o=","auftraggeber":"1","rechnungsempfaenger":"1","nrVersorgungsunternehmen":"1",
      "niederlassungdesVersorgers":"1","kundennummer":"10000","vorgangsart":"1","auftragsdatum":"23.10.2022",
      "leistung":"4","auftragsbemerkung":"2","bestellnummer":"12345","bestellposition":"1","endedatum":"25.10.2022",
      "terminabspracheerforderlich":"1","anredekennzeichenKunde":"1","name_1Kunde":"Schäfer Name1","name_2Kunde":"Name2",
      "strasseKunde":"Steinhälde 25","plzKunde":"74360","ortKunde":"Ilsfeld","ortzusatsKunde":"Auenstein",
      //"strasseKunde":"Danziger Str. 3","plzKunde":"71711","ortKunde":"Steinheim","ortzusatsKunde":null,
      "telefonKunde":"004919999","bemerkungzumKunden":null,"anredekennzeichen":"1",
      "name_1Behaelterstandort":"Merkle Name1 Behälter","name_2Behaelterstandort":null,
      "strasseBehaelterstandort":"Steinhälde 25","plzBehaelterstandort":"74360","ortBehaelterstandort":"Ilsfeld",
      "ortzusatzBehaelterstandort":"Auenstein","telefonBehaelterstandort":null,"bemerkungzumBehaelterstandort":null,
      "behaelternummer":"BEHNR.","hersteller":"Hersteller 1","baujahr":"2010","behaeltervolumen":"1900",
      "fuellvolumen":"2100","lagerart":"1","schutzart":"1","ersteAbnahmePruefung":"01.01.2010",
      "letztePruefungvorInbetriebnahme":"01.01.2015","letzteInnerePruefungAnlagenpruefung":"01.01.2015",
      "letzteDruckPruefungFestigkeitspruefung":"","letzteAeusserePruefung":"","baumusternummerBehaelter":null,
      "peilrohrlaenge":null,"sicherheitsventilNr":null,"technischerLieferstopp":null,"verwaltungsdaten":null,
      "geodaten_Breite":49.05827,"geodaten_Laenge":9.28775,
      //"geodaten_Breite":48.970983,"geodaten_Laenge":9.27516,  // https://www.revilodesign.de/tools/google-maps-latitude-longitude-finder/   Danziger Str. 3/1
      //"geodaten_Breite":48.9709959,"geodaten_Laenge":9.2750162,  // https://www.revilodesign.de/tools/google-maps-latitude-longitude-finder/  Danziger Str. 3
      "geoLocation":null,"ankaNr":null,
      "inbetriebnahmeKKSAnlage":null,"letztePruefungKKSAnlage":null,"anKa_Nr_AnlagenschluesselDruck_Anl":null,
      "anKa_Nr_Anlagenschluessel_Ex_Anl":null,"nutzungsart":1,"anlagenbeschreibung":"1","avisierungsart":null,
      "kontaktDaten_Avisierung_Telefon":"1","kontaktdaten_Avisierung_SMS":"1","kontaktdaten_Avisierung_EMail":"1",
      "dienstleister":1
    }]
    );*/

    this.auftragsListRefresh();

    //splitter nicht resizeable machen
    try {
      let _splitters = document.getElementsByClassName(this.mode == 'map' ? "p-splitter-horizontal" : "p-splitter-vertical");
      let _gutters = _splitters[0].getElementsByClassName("p-splitter-gutter");
      let _gutter = _gutters[0];
      let _gutterClone = _gutter['cloneNode'](true);
      _gutter.parentNode.replaceChild(_gutterClone, _gutter);
      _gutterClone['style'].cursor = "auto"; // Mauszeiger
      let _gutterHandle = _gutterClone['getElementsByClassName']("p-splitter-gutter-handle");
      _gutterHandle[0]['style'].background = "none"; // Handle ausblenden    
    }
    catch(e) {
      console.error("map.getAuftraege() catched exception:", e);
    }
  }

  onAuftraegeRetrieved(auftragsdaten: IAuftragsdaten[]) {
    console.log("Map.onAuftraegeRetrieved() auftragsdaten:", auftragsdaten);
    // dummy feld nachtragen - übergangsweise, bis alle Felder vorhanden sind
    auftragsdaten.forEach(element => {
      element['dummy']= '';
    });

    this.auftraege = auftragsdaten;

    this.fakeCRUDItem.auftraegeFiltered = auftragsdaten;

    // jedem Auftrag gleich eine Location im Leaflet-Format mitgeben (wird z.B. für sameLocationCounter benötigt)
    this.fakeCRUDItem.auftraegeFiltered.forEach(auftrag => {
      let _leafletLocation = [auftrag.geodaten_Breite, auftrag.geodaten_Laenge];
      auftrag._leafletLocation = _leafletLocation;
    });

    this.auftragsListRefresh();
    console.log("Map.onAuftraegeRetrieved() fakeCRUDItem:", this.fakeCRUDItem);

    //this.app.auftraegeRoutenplanung = _.clone(this.auftraege); // Zu Beginn alle autom. auf die Route

    let zoomStr = this.route.snapshot.queryParams['zoom'];
    let latStr = this.route.snapshot.queryParams['lat'];
    let lngStr = this.route.snapshot.queryParams['lng'];
    let zoom = zoomStr != null ? Number(zoomStr) : 8 /*default*/;
    let lat = latStr != null ? Number(latStr) : null;
    let lng = lngStr != null ? Number(lngStr) : null;
    
    let viewParms:any = null;
    if(lat != null && lng != null) {
      viewParms = [lat, lng];
    }
    else {
      viewParms = this.getViewParms();
    }
    console.log("Map.onAuftraegeRetrieved() viewParms:", viewParms);
    /*this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: {}
      });*/
    

    if(this.alreadyInitialized == false) {
      this.alreadyInitialized = true;

      this.initMap(viewParms, zoom);

      // den aktuellen Standort vom Browser holen (für Routenplanung)
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.callBackFromGeolocation, this.callBackFromGeolocationOnError);
      } 
      else {
        console.log("Map.ngAfterViewInit() Geolocation is not supported by this browser.");
      }
  
      console.log("Map.ngAfterViewInit() L:", L);
      console.log("Map.ngAfterViewInit() awesome:", awesome); // Warum auch immer, aber ohne das funktioniert es nicht! Dann ist L['AwesomeMarkers'] = null!
      console.log("Map.ngAfterViewInit() leafletSearch:", leafletSearch); // Warum auch immer, aber ohne das funktioniert es nicht! 
      console.log("Map.ngAfterViewInit() leafletLocateControl:", leafletLocateControl); // Warum auch immer, aber ohne das funktioniert es nicht! 
  
      // innerhalb des splitters baut leaflet sonst die falsche Grösse zusammen
      setTimeout(() => {
        this.map.invalidateSize();
      }, 100);		

      // "manuell" per fa-stack zusammengesetzter marker - benötigt KEIN leaflet.awesome-markers
      /*this.leafletMarkerIconDefault = L.divIcon({
        html: `
          <span class="fa-stack fa-lg">
              <i class="fa fa-map-marker fa-stack-2x" style="color: red;"></i>
              <i class="fa fa-coffee fa-stack-1x"></i>
          </span>      `,
        iconSize: [20, 20],
        className: 'myDivIcon',
      });*/
  
      /*this.leafletMarkerIconDefault = L['AwesomeMarkers'].icon({
        icon: 'wrench',
        markerColor: 'blue',
        prefix: 'fa',
        //className: 'awesome-marker awesome-marker-square'  // quadratische Marker
      });
      */
      /*
      this.leafletMarkerIconAufRoute = L['AwesomeMarkers'].icon({
        icon: 'wrench',
        markerColor: 'darkblue',
        prefix: 'fa',
        //className: 'awesome-marker awesome-marker-square'  // quadratische Marker
      });*/
  
      /*this.leafletMarkerIconYourPosition = L['AwesomeMarkers'].icon({
        icon: 'location-crosshairs',
        markerColor: 'orange',
        prefix: 'fa',
        //className: 'awesome-marker awesome-marker-square'  // quadratische Marker
      });*/
  
      this.offlineToggleInit();

      this.initMarkers();
    }
  }

  initMarkers() {
    if(this.app == null || this.app.leistungen == null || this.app.leistungen.length == 0) {
      console.log("map.initMarkers() waiting for app.leistungen ...");
      setTimeout(() => {
        this.initMarkers();
      }, 100);		
    }
    else {
      this.app.leistungen.forEach(icon => {
        /*let leafletMarkerIconNormal = L['AwesomeMarkers'].icon({
          icon: icon.icon,
          markerColor: 'blue',
          prefix: 'fa',
          //className: 'awesome-marker awesome-marker-square'  // quadratische Marker
        });
        let leafletMarkerIconAufRoute = L['AwesomeMarkers'].icon({
          icon: icon.icon,
          markerColor: 'darkblue',
          prefix: 'fa',
          //className: 'awesome-marker awesome-marker-square'  // quadratische Marker
        });
        let leafletMarkerIconUeberfaelligJahr = L['AwesomeMarkers'].icon({
          icon: icon.icon,
          markerColor: 'red',
          prefix: 'fa',
          //className: 'awesome-marker awesome-marker-square'  // quadratische Marker
        });
        let leafletMarkerIconUeberfaelligJahrAufRoute = L['AwesomeMarkers'].icon({
          icon: icon.icon,
          markerColor: 'darkred',
          prefix: 'fa',
          //className: 'awesome-marker awesome-marker-square'  // quadratische Marker
        });*/
        let leafletMarkerIconNormal = L.divIcon({
          html: `
            <span class="fa-stack fa-2x" style="width: 100%;"><!--style="margin-left: -16px; margin-top: -36px;"-->
                <i class="fa-thin fa-map-marker fa-stack-2x" style="color: lightblue; font-size: 2.25em; margin-top: -2.0px; margin-left: -1.75px;"></i>
                <i class="fa fa-map-marker fa-stack-2x" style="color: #08f; font-size: 2em;"></i>
                <i class="fa fa-${icon.icon} fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
                <!--overlayCounter-->
                <!--overlayDisponiert-->
            </span>      `,
            iconSize: [32, 42],
            iconAnchor: [16, 42],
            className: 'myLeafletMarker' + (this.debugMode == true ? ' myLeafletMarker_debug' : '')
        });
        let leafletMarkerIconAufRoute = L.divIcon({
          html: `
            <span class="fa-stack fa-2x" style="width: 100%;"><!--style="margin-left: -16px; margin-top: -36px;"-->
                <i class="fa-thin fa-map-marker fa-stack-2x" style="color: lightblue; font-size: 2.25em; margin-top: -2.0px; margin-left: -1.75px;"></i>
                <i class="fa fa-map-marker fa-stack-2x" style="color: #00a; font-size: 2em;"></i>
                <i class="fa fa-${icon.icon} fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
                <!--overlayCounter-->
                <!--overlayDisponiert-->
            </span>      `,
            iconSize: [32, 42],
            iconAnchor: [16, 42],
            className: 'myLeafletMarker' + (this.debugMode == true ? ' myLeafletMarker_debug' : '')
        });
        let leafletMarkerIconUeberfaelligJahr = L.divIcon({
          html: `
            <span class="fa-stack fa-2x" style="width: 100%;"><!--style="margin-left: -16px; margin-top: -36px;"-->
                <i class="fa fa-map-marker fa-stack-2x" style="color: red; font-size: 2.375em; margin-top: -3px; margin-left: -2.5px;"></i>
                <i class="fa fa-map-marker fa-stack-2x" style="color: #08f; font-size: 2em;"></i>
                <i class="fa fa-${icon.icon} fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
                <!--overlayCounter-->
                <!--overlayDisponiert-->
            </span>      `,
            iconSize: [32, 42],
            iconAnchor: [16, 42],
            className: 'myLeafletMarker' + (this.debugMode == true ? ' myLeafletMarker_debug' : '')
        });
        let leafletMarkerIconUeberfaelligJahrAufRoute = L.divIcon({
          html: `
            <span class="fa-stack fa-2x" style="width: 100%;"><!--style="margin-left: -16px; margin-top: -36px;"-->
                <i class="fa fa-map-marker fa-stack-2x" style="color: red; font-size: 2.375em; margin-top: -3px; margin-left: -2.5px;"></i>
                <i class="fa fa-map-marker fa-stack-2x" style="color: #00a; font-size: 2em;"></i>
                <i class="fa fa-${icon.icon} fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
                <!--overlayCounter-->
                <!--overlayDisponiert-->
            </span>      `,
            iconSize: [32, 42],
            iconAnchor: [16, 42],
            className: 'myLeafletMarker' + (this.debugMode == true ? ' myLeafletMarker_debug' : '')
        });
        let leafletMarkerIconUeberfaelligTage = L.divIcon({
          html: `
            <span class="fa-stack fa-2x" style="width: 100%;"><!--style="margin-left: -16px; margin-top: -36px;"-->
                <i class="fa fa-map-marker fa-stack-2x" style="color: darkorange; font-size: 2.375em; margin-top: -3px; margin-left: -2.5px;"></i>
                <i class="fa fa-map-marker fa-stack-2x" style="color: #08f; font-size: 2em;"></i>
                <i class="fa fa-${icon.icon} fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
                <!--overlayCounter-->
                <!--overlayDisponiert-->
            </span>      `,
            iconSize: [32, 42],
            iconAnchor: [16, 42],
            className: 'myLeafletMarker' + (this.debugMode == true ? ' myLeafletMarker_debug' : '')
        });
        let leafletMarkerIconUeberfaelligTageAufRoute = L.divIcon({
          html: `
            <span class="fa-stack fa-2x" style="width: 100%;"><!--style="margin-left: -16px; margin-top: -36px;"-->
                <i class="fa fa-map-marker fa-stack-2x" style="color: darkorange; font-size: 2.375em; margin-top: -3px; margin-left: -2.5px;"></i>
                <i class="fa fa-map-marker fa-stack-2x" style="color: #00a; font-size: 2em;"></i>
                <i class="fa fa-${icon.icon} fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
                <!--overlayCounter-->
                <!--overlayDisponiert-->
            </span>      `,
            iconSize: [32, 42],
            iconAnchor: [16, 42],
            className: 'myLeafletMarker' + (this.debugMode == true ? ' myLeafletMarker_debug' : '')
        });
        this.leafletMarkerIconsNormal.push({leistung: icon.leistung, icon: leafletMarkerIconNormal});
        this.leafletMarkerIconsAufRoute.push({leistung: icon.leistung, icon: leafletMarkerIconAufRoute});
        this.leafletMarkerIconsUeberfaelligJahr.push({leistung: icon.leistung, icon: leafletMarkerIconUeberfaelligJahr});
        this.leafletMarkerIconsUeberfaelligJahrAufRoute.push({leistung: icon.leistung, icon: leafletMarkerIconUeberfaelligJahrAufRoute});
        this.leafletMarkerIconsUeberfaelligTage.push({leistung: icon.leistung, icon: leafletMarkerIconUeberfaelligTage});
        this.leafletMarkerIconsUeberfaelligTageAufRoute.push({leistung: icon.leistung, icon: leafletMarkerIconUeberfaelligTageAufRoute});

        // Markers zusätzlich mit einem Overlay versehen, das die Anzahl der Markers an der gleichen Location zeigt
        // dieses Template wird dann in die obigen Templates an Stelle
        //    <!--overlayCounter-->
        // eingesetzt. Dabei wird $iconname durch two, ... nine oder ellipsis ersetzt
        //this.leafletMarkerIconsOverlayCounterTemplate = `
        //<i class="fa fa-circle fa-stack-1x"          style="color: white; font-size: 0.75em; margin-left: 13px; margin-top: -13px;"></i>
        //<i class="fa fa-circle-$iconname fa-stack-1x" style="color: #666;  font-size: 0.75em; margin-left: 13px; margin-top: -13px;"></i>
        //`;
        this.leafletMarkerIconsOverlayCounterTemplate = `
        <i class="fa-duotone fa-circle-$iconname fa-stack-1x" <i class="fa-duotone fa-droplet" style="--fa-primary-color: white; --fa-secondary-color: #000; font-size: 0.75em; margin-left: 13px; margin-top: -13px;"></i>
        `;

        this.leafletMarkerIconsOverlayDisponiertTemplate = `
        <i class="fa fa-solid fa-calendar fa-stack-1x" style="color: #08f; font-size: 0.7em; margin-left: 10px; margin-top: -19px;"></i>
        <i class="fa fa-regular fa-calendar fa-stack-1x" style="color: white; font-size: 0.5em; margin-left: 10px; margin-top: -18px;"></i>
        `;
      });
  
      this.setAuftraegeMarker();

      if(this.leafletMode == 'spiderfier') {
        setTimeout(() => {
          this.refreshMarkerCounter(); // NACH init nochmal resfresh!, weil bei 1. Bestückung (init) gibt es noch keine anderen Marker gegen die er abgleichen kann.
        }, 100);	
      }
  
      this.app.setMapComponent(this);
  
      // nachdem die Aufträge bekannt sind, deren Anzahl ermitteln und im Filter anzeigen
      this.app.leistungen.forEach(leistung => {
        leistung.count = 0;
      });
      this.auftraege.forEach(auftrag => {
        if(auftrag.status == 'C' || this.mode != 'map') { // bei Liste auch die counten, die != Status C
          let foundLeistung = this.app.leistungen.find(f => f.leistung == auftrag.leistung);
          if(foundLeistung != null) {
            if(foundLeistung.count == null) foundLeistung.count = 1;
            else foundLeistung.count ++;
          }
        }
      });
      // zum Schluss die ausfiltern, die Counter = 0
      this.app.leistungenFiltered = this.app.leistungen.filter(f => f.count > 0);
    }
  }

  ngOnDestroy(): void {
    console.log("map.ngOnDestroy()");

    try {
      this.map.off();
      this.map.remove();
      //this.map = null;
  
      let _map = document.getElementById("map");
      //_map.innerText = null;
      //_map.className = null;
      console.log("map.ngOnDestroy() _map:", _map);
  
      this.app.setMapComponent(null);
    }
    catch(e) {
      console.log("map.ngOnDestroy() error:", e);
    }
    
  } 

  callBackFromGeolocation(position) {
    console.log("Map.callBackFromGeolocation() "+
    "Latitude: " + position.coords.latitude +
    " Longitude: " + position.coords.longitude);

    console.log("Map.callBackFromGeolocation() position:", position);

    let thisComponent = /*globalThis*/window['___mapComponent']; // der android webView kann kein globalThis
    thisComponent.app.ownLocation_Long = position.coords.longitude;
    thisComponent.app.ownLocation_Latt = position.coords.latitude;

    // https://gis.stackexchange.com/questions/351440/showing-users-current-location-with-leaflet
    /*var radius = position.coords.accuracy / 2;
    L.marker([position.coords.latitude,position.coords.longitude],{icon: thisComponent.leafletMarkerIconYourPosition}).addTo(thisComponent.map)
        .bindPopup("Sie befinden sich innerhalb " + radius + " meter von diesem Punkt.").openPopup();
    L.circle([position.coords.latitude,position.coords.longitude], radius).addTo(thisComponent.map);*/
  }

  callBackFromGeolocationOnError(error) {
    /*if(error.code == 1) {
        result.innerHTML = "You've decided not to share your position, but it's OK. We won't ask you again.";
    } else if(error.code == 2) {
        result.innerHTML = "The network is down or the positioning service can't be reached.";
    } else if(error.code == 3) {
        result.innerHTML = "The attempt timed out before it could get the location data.";
    } else {
        result.innerHTML = "Geolocation failed due to unknown error.";
    }*/

    let thisComponent = /*globalThis*/window['___mapComponent']; // der android webView kann kein globalThis
    thisComponent.messageService.add({sticky: true, key:'app.main', severity:'error', summary:'Standort', detail:'Ihr Standort konnte nicht abgerufen werden.\nUm den vollen Funktionsumfang (z.B. bei Routenplanung) nutzen zu können, aktivieren Sie bitte die Standort-Ermittlung und laden Sie die Seite neu.'});
  }

  // das passende Marker-Icon finden, je nachdem ob auf Route, ob fällig, ...
  getMarkerIcon(auftrag: IAuftragsdaten, ueberfaelligJahr: boolean, ueberfaelligTage: boolean, sameLocationCounter: number) {
    //console.log("Map.getMarkerIcon() leafletMarkerIconsNormal:", this.leafletMarkerIconsNormal);
    try {
      let iconArrayDasDurchsuchtwerdenMuss = null;

      // ist der Marker schon in der Routenplanung ?
      let foundInRoutenPlanung = this.app.auftraegeRoutenplanung.find(f => f.id == auftrag.id);

      //let auftragDatumYYYYMMDD = auftrag.auftragsdatum.substring(6)+auftrag.auftragsdatum.substring(3,5)+auftrag.auftragsdatum.substring(0,2);
      if(ueberfaelligJahr) { // fällig!
        if(foundInRoutenPlanung != null) iconArrayDasDurchsuchtwerdenMuss = this.leafletMarkerIconsUeberfaelligJahrAufRoute;
        else iconArrayDasDurchsuchtwerdenMuss = this.leafletMarkerIconsUeberfaelligJahr;
      }
      else if(ueberfaelligTage) { // ueberfaelligTage!
        if(foundInRoutenPlanung != null) iconArrayDasDurchsuchtwerdenMuss = this.leafletMarkerIconsUeberfaelligTageAufRoute;
        else iconArrayDasDurchsuchtwerdenMuss = this.leafletMarkerIconsUeberfaelligTage;
      }
      else { // nicht fällig oder ueberfaelligTage
        if(foundInRoutenPlanung != null) iconArrayDasDurchsuchtwerdenMuss = this.leafletMarkerIconsAufRoute;
        else iconArrayDasDurchsuchtwerdenMuss = this.leafletMarkerIconsNormal;
      }

      // im entspr. Array das Icon suchen
      let foundIcon = iconArrayDasDurchsuchtwerdenMuss.find(f => f.leistung == auftrag.leistung);

      // wenn nicht gefunden: mit * suchen
      if(foundIcon == null) {
        //console.log("Map.getMarkerIcon() not found for auftrag.leistung = '"+auftrag.leistung+"' - so using generic icon!");
        //foundIcon = iconArrayDasDurchsuchtwerdenMuss.find(f => f.leistung == '*');
        console.error("Map.getMarkerIcon() not found for auftrag.leistung = '"+auftrag.leistung+"'");
        return null;
      }

      let _debugAddon: string = null;
      if(this.debugMode == true) {
        _debugAddon = "<small style='position: absolute; margin-left: -32px; margin-top: +21px'>"+auftrag.id+"_s:"+sameLocationCounter+"</small>"
      }
    
      // Markers zusätzlich mit einem Overlay versehen, das die Anzahl der Markers an der gleichen Location zeigt
      if(this.leafletMode == 'spiderfier' && sameLocationCounter > 1) {
        let iconname:string = sameLocationCounter > 9 ? 'ellipsis' : ''+sameLocationCounter;
        let clonedIconWithCounter = cloneDeep(foundIcon.icon);
        clonedIconWithCounter.options.html = clonedIconWithCounter.options.html.replace('<!--overlayCounter-->', this.leafletMarkerIconsOverlayCounterTemplate);
        clonedIconWithCounter.options.html = clonedIconWithCounter.options.html.replace('$iconname', iconname);

        if(auftrag.dispodatum != null || auftrag.benutzerId != null) {
          clonedIconWithCounter.options.html = clonedIconWithCounter.options.html.replace('<!--overlayDisponiert-->', this.leafletMarkerIconsOverlayDisponiertTemplate);
        }

        if(this.debugMode == true) clonedIconWithCounter.options.html += _debugAddon;

        return clonedIconWithCounter;
      }
      else {
        if(this.debugMode == true) {
          let clonedIconWithDebug = cloneDeep(foundIcon.icon);
          clonedIconWithDebug.options.html += _debugAddon;
          if(auftrag.dispodatum != null || auftrag.benutzerId != null) {
            clonedIconWithDebug.options.html = clonedIconWithDebug.options.html.replace('<!--overlayDisponiert-->', this.leafletMarkerIconsOverlayDisponiertTemplate);
          }
          return clonedIconWithDebug;
        }
        else {
          if(auftrag.dispodatum != null || auftrag.benutzerId != null) {
            let clonedIconWithDisponiert = cloneDeep(foundIcon.icon);
            clonedIconWithDisponiert.options.html = clonedIconWithDisponiert.options.html.replace('<!--overlayDisponiert-->', this.leafletMarkerIconsOverlayDisponiertTemplate);
            return clonedIconWithDisponiert;
          }
          else {
            return foundIcon.icon;
          }
        }
      }
    }
    catch (e) {
      console.error("Map.getMarkerIcon() Exception! Exception/Auftrag:", e, auftrag);
      return null;
    }
  }

  isAuftragUeberfaelligJahr(auftrag: IAuftragsdaten) {
    let auftragDatumYYYY = moment(auftrag.endedatum).format('YYYY');  
    if(auftragDatumYYYY < this.thisYearYYYY) { // ueberfaelligJahr!
      return true;
    }
    else {
      return false;
    }
  }

  isAuftragUeberfaelligTage(auftrag: IAuftragsdaten) {
    let auftragDatumYYYY = moment(auftrag.endedatum).format('YYYY');  
    let auftragDatumYYYYMMDD = moment(auftrag.endedatum).format('YYYYMMDD');  
    if(auftragDatumYYYYMMDD <= this.ueberfaelligTageDatumYYYYMMDD) { // ueberfaelligTage!
      return true;
    }
    else { // nicht ueberfaelligTage
      return false;
    }
  }

  getViewParms() {
    let lowestBreite = 99999999;
    let lowestLaenge = 99999999;
    let highestBreite = 0;
    let highestLaenge = 0;
    this.auftraege.forEach(auftrag => {
      if(auftrag.geodaten_Breite != 0 && auftrag.geodaten_Laenge != 0) {
        if(auftrag.geodaten_Breite < lowestBreite) lowestBreite = auftrag.geodaten_Breite;
        if(auftrag.geodaten_Laenge < lowestLaenge) lowestLaenge = auftrag.geodaten_Laenge;
        if(auftrag.geodaten_Breite > highestBreite) highestBreite = auftrag.geodaten_Breite;
        if(auftrag.geodaten_Laenge > highestLaenge) highestLaenge = auftrag.geodaten_Laenge;
      }
    });

    // wenn keine Daten vorhanden, dann Krefeld einstellen
    if(lowestBreite == 99999999 && highestBreite == 0) {
      lowestBreite = 51.3388;
      lowestLaenge = 6.5853;
      highestBreite = 51.3388
      highestLaenge = 6.5853;
    }

    console.log('Map.lowestBreite/lowestLaenge:', lowestBreite, lowestLaenge);
    console.log('Map.highestBreite/highestLaenge:', highestBreite, highestLaenge);

    //this.map.setView(lowestBreite, lowestLaenge);
    //L.map('map').setView([lowestBreite, lowestLaenge], 16);
    return [lowestBreite + ((highestBreite - lowestBreite) / 2), lowestLaenge + ((highestLaenge - lowestLaenge) / 2)];
  }

  setAuftraegeMarker() {
    console.log('Map.setAuftraegeMarker()');

    let arrayOfMarkers: L.Marker[] = null; // markercluster-mode: nicht jeden marker einzeln adden, sondern auf 1mal (in array sammeln)
    if(this.leafletMode == 'markercluster') {  
      arrayOfMarkers = []; // markercluster-mode: nicht jeden marker einzeln adden, sondern auf 1mal (in array sammeln)
      this.markerClusterAddRemoveToMap(false); // markerCluster Layer entfernen
    }

    // alle bisherigen löschen (bei refresh)
    for(/*let i = 0; i < this.mapMarkers.length; i++*/let i = this.mapMarkers.length-1; i>=0; i--){ // das muss rückwärts laufen!
      //console.log('Map.setAuftraegeMarker() removing mapMarkers['+i+']:', this.mapMarkers[i]);
      if(this.leafletMode == 'markercluster') {  
        this.markerCluster.removeLayer(this.mapMarkers[i]);
      }
      else {
        this.map.removeLayer(this.mapMarkers[i]);
      }
    }
    this.mapMarkers = [];
    if(this.leafletMode == 'spiderfier') {
      this.oms.clearMarkers();
    }
    // auch in der auftragslist:
    this.auftragsdatenList.crudItems = [];

    this.fakeCRUDItem.auftraegeFiltered.forEach(auftrag => {
      // prüfen, wie viele weitere Marker es an dieser Stelle gibt
      /*let sameLocationCounter = this.countAuftragSameLocation(auftrag);

      let ueberfaelligJahr = this.isAuftragUeberfaelligJahr(auftrag);
      let ueberfaelligTage = this.isAuftragUeberfaelligTage(auftrag);
      if(this.app == null || this.app.filterLeistungenSelected == null || this.app.filterLeistungenSelected.length == 0) {
        if(this.app != null && (this.app.filterNurUeberfaelligJahre == false || ueberfaelligJahr == true) && (this.app.filterNurUeberfaelligTage == false || ueberfaelligTage == true)) {
          if(auftrag.status != 'O') this.addMarker(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter); // nur NICHT-abgeschlossene auf der Karte anzeigen!
          if(auftrag.status != 'O' || this.mode == 'list') this.auftragsdatenList.crudItems.push(auftrag); // Abgeschlosse nur im List-Mode anzeigen!
        } 
      }
      else {
        let foundInSelected = this.app.filterLeistungenSelected.find(f => f.leistung == auftrag.leistung);
        if( foundInSelected != null ) {
          if(this.app != null && (this.app.filterNurUeberfaelligJahre == false || ueberfaelligJahr == true) && (this.app.filterNurUeberfaelligTage == false || ueberfaelligTage == true)) {
            if(auftrag.status != 'O') this.addMarker(auftrag, ueberfaelligJahr, ueberfaelligTage, sameLocationCounter); // nur NICHT-abgeschlossene auf der Karte anzeigen!
            if(auftrag.status != 'O' || this.mode == 'list') this.auftragsdatenList.crudItems.push(auftrag); // Abgeschlosse nur im List-Mode anzeigen!
          } 
        }
      }*/

      let isAuftragVisibleInMapAndList = this.isAuftragVisibleInMapAndList(auftrag);
      //console.log('Map.setAuftraegeMarker() isAuftragVisibleInMapAndList:', isAuftragVisibleInMapAndList);
      if(isAuftragVisibleInMapAndList.addMap == true) {
        let sameLocationCounter = this.leafletMode == 'spiderfier' ? this.countAuftragSameLocation(auftrag) : 0;
        this.addMarker(auftrag, isAuftragVisibleInMapAndList.ueberfaelligJahr, isAuftragVisibleInMapAndList.ueberfaelligTage, sameLocationCounter, arrayOfMarkers); // nur NICHT-abgeschlossene auf der Karte anzeigen!
      }
      if(isAuftragVisibleInMapAndList.addList == true) {
        this.auftragsdatenList.crudItems.push(auftrag); // Abgeschlosse nur im List-Mode anzeigen!
      }
    });

    if(this.leafletMode == 'markercluster') {  
      this.markerCluster.addLayers(arrayOfMarkers);
      this.markerClusterAddRemoveToMap(true); // markerCluster Layer wieder einfügen
    }

    // Warum auch immer refresht sich die List nicht, daher
    this.auftragsdatenList.getCRUDItems();
  }

  isAuftragVisibleInMapAndList(auftrag) {
    let result = {
      addMap: false, // Kennzeichen, ob Auftrag per addMarker() in Map dargestellt werden soll
      addList: false, // Kennzeichen, ob Auftrag in die auftragsdatenList übernommen werden soll
      ueberfaelligJahr: false,
      ueberfaelligTage: false
    };

    result.ueberfaelligJahr = this.isAuftragUeberfaelligJahr(auftrag);
    result.ueberfaelligTage = this.isAuftragUeberfaelligTage(auftrag);
    
    let filterLeistungenPassed = false;
    if(this.app == null || this.app.filterLeistungenSelected == null || this.app.filterLeistungenSelected.length == 0) {
      filterLeistungenPassed = true;

    }
    else {
      let foundInSelected = this.app.filterLeistungenSelected.find(f => f.leistung == auftrag.leistung);
      if( foundInSelected != null ) {
        filterLeistungenPassed = true;
      }
    }
    if(filterLeistungenPassed == true) {
      if(this.app.filterBenutzerNurMeine == false || this.benutzer != null && auftrag.benutzerId != null && this.benutzer.id == auftrag.benutzerId) {
        if(this.app != null && (this.app.filterNurUeberfaelligJahre == false || result.ueberfaelligJahr == true) && (this.app.filterNurUeberfaelligTage == false || result.ueberfaelligTage == true)) {
          if(auftrag.status != 'O') result.addMap = true; // nur NICHT-abgeschlossene auf der Karte anzeigen!
          if(auftrag.status != 'O' || this.mode == 'list') result.addList = true; // Abgeschlosse nur im List-Mode anzeigen!
        } 
      }
    }

    return result;
  }



  countAuftragSameLocation(auftrag: IAuftragsdaten) {
    //let sameLocationCounter = this.fakeCRUDItem.auftraegeFiltered.filter(f => Math.abs(f.geodaten_Laenge - auftrag.geodaten_Laenge) <= 0.1 && Math.abs(f.geodaten_Breite - auftrag.geodaten_Breite) <= 1).length;

    //let metresPerPixel = this.getMetresPerPixel();
    
    //let markerPixels = 42; // Annahme! angezeigte Höhe bei "inspect" = wieviele Pixel überdeckt ein Marker ?
    //let oneMarkerConversPixels = metresPerPixel * markerPixels; // wieviele Meter überdeckt ein Marker ?
    //let searchForOtherMarkersWithinMeters = oneMarkerConversPixels / 3; // Innerhalb so vielen Metern gilt ein Marker als überlappend - also solange er nur 1/3 Marker entfernt ist
    
    //let searchForOtherMarkersWithinMeters = this.SpiderfierNearbyDistance * metresPerPixel;

    //let sameLocationCounter = this.fakeCRUDItem.auftraegeFiltered.filter(f => L.latLng(f['_leafletLocation']).distanceTo(auftrag['_leafletLocation']) <= searchForOtherMarkersWithinMeters).length; // distanceTo = in Metern!

    // Die gleiche Logik, wie der Spiderifier nutzt, um alle "in der Nähe" zu ermitteln hier verwenden!
    // aus: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/blob/master/lib/oms.coffee
    let pxSq = this.SpiderfierNearbyDistance * this.SpiderfierNearbyDistance;
    let markerPt = this.map.latLngToLayerPoint(auftrag['_leafletLocation']);
    let nearbyAuftraege = [];
    //console.log("map.countAuftragSameLocation: auftraegeFiltered: ", this.fakeCRUDItem.auftraegeFiltered);
    this.fakeCRUDItem.auftraegeFiltered.forEach(a => {
      let isAuftragVisibleInMapAndList = this.isAuftragVisibleInMapAndList(a);
      if(isAuftragVisibleInMapAndList.addMap == true) { // nur die zählen, die auch sichtbar sind
        let marker = a['___marker'];  
        if(marker != null && marker != undefined) {
          let mPt = this.map.latLngToLayerPoint(a['_leafletLocation']);
          if(this.ptDistanceSq(mPt, markerPt) < pxSq) {
            nearbyAuftraege.push(a);
          }
        }
        else { // Auftrag ohne Marker (Status O ?)
          if(this.debugMode == true) {
            console.log("map.countAuftragSameLocation: auftrag ohne ___marker: auftrag.id, a", auftrag.id, a);
          }
        }
      }
    });
    let sameLocationCounter = nearbyAuftraege.length;

    if(this.debugMode == true) {
      console.log("map.countAuftragSameLocation() auftrag.id/sameLocationCounter/nearbyAuftraege: ", auftrag.id, sameLocationCounter, nearbyAuftraege);
    }

    return sameLocationCounter;
  }
  
  ptDistanceSq(pt1, pt2) { // siehe countAuftragSameLocation - ebenfalls aus: https://github.com/jawj/OverlappingMarkerSpiderfier-Leaflet/blob/master/lib/oms.coffee
    let dx = pt1.x - pt2.x;
    let dy = pt1.y - pt2.y;
    return dx * dx + dy * dy;
  } 

  refreshAuftraegeMarker() {
    console.log('Map.refreshAuftraegeMarker() app.filterDienstleisterSelected:', this.app.filterDienstleisterSelected);

    let thisInstance = this;
    let dienstleisterCommaSeparated = "";
    if(this.app.topbarComponent.isDienstleisterAuswahlAvailable()) {
      if(thisInstance.app.filterDienstleisterSelected != null) {
        thisInstance.app.filterDienstleisterSelected.forEach(dienstleister => {
          if(dienstleisterCommaSeparated.length > 0) dienstleisterCommaSeparated += ",";
          dienstleisterCommaSeparated += dienstleister.id;
        });
      }
      else {
        dienstleisterCommaSeparated = "0"; 
      }
    }
    else {
      dienstleisterCommaSeparated += this.benutzer.dienstleisterId;
    }

    if(dienstleisterCommaSeparated != this.auftraegeLastRetrieveFilter) {
      console.log('Map.refreshAuftraegeMarker() Dienstleister-Filter hat sich geändert -> full refresh!');
      if(dienstleisterCommaSeparated != null && dienstleisterCommaSeparated.trim().length > 0 && dienstleisterCommaSeparated != '0')
        localStorage.setItem("SELECTED_DIENSTLEISTER", dienstleisterCommaSeparated); // damit nach F5 noch erhalten
      else
        localStorage.removeItem("SELECTED_DIENSTLEISTER"); // damit nach F5 noch erhalten
      this.router.navigateByUrl('/dummy', {skipLocationChange: true}).then(() => {
        setTimeout(() => {
         if(this.mode == 'map') {
           this.router.navigate(['/map']);
         }
         else {
           this.router.navigate(['/list']);
         }
         }, 10);		
      });

    }
    else {
      console.log('Map.refreshAuftraegeMarker() Dienstleister-Filter hat sich NICHT geändert -> setAuftraegeMarker()');
      this.setAuftraegeMarker();
    }
  }

  refreshAuftraegeMarkerAndCounter() {
    this.app.blockUI = true;
    setTimeout(() => { // ohne Timeout funktioniert der blockUI nicht!
      this.refreshAuftraegeMarker();
      this.refreshMarkerCounter();
      this.app.blockUI = false;
    }, 50);		
  }

  getLeistungForAuftrag(auftrag: IAuftragsdaten) {
    let leistung = this.app.leistungen.find( f => f.leistung == this.lastclickedAuftrag.leistung );
    //console.log('Map.getIconForAuftrag() leistung:', leistung);
    return leistung;
  }

  ///// AuftragsListe

  splitterMoved() {
    console.log('Map.splitterMoved()');
  }

  onCrudItemsValueChangeAuftraege($event) {
    console.log('Map.onCrudItemsValueChangeAuftraege()'); // wird nie passieren, da die Aufträge in der Lsite nicht editiert weren können
  }

  hideList() {
    console.log("map.hideList() splitter:", this.splitter);
  }

  auftragsListRefresh() {
    console.log("map.auftragsListRefresh()")
    if(this.auftragsListAlreadyRefreshed == false) {
      if(this.auftragsdatenList != null) {
        if(this.fakeCRUDItem != null) {
          if(this.fakeCRUDItem.auftraegeFiltered != null) {
            this.auftragsListAlreadyRefreshed = true;
            //setTimeout(function() {
              // Warum auch immer refresht sich die List nicht, daher
              this.auftragsdatenList.crudItems = this.fakeCRUDItem.auftraegeFiltered;
              this.auftragsdatenList.getCRUDItems();
            //}, 2000);
          }
        }
      }
    }
  }

  public selectMarker(auftragId) { // wird aus der Auftragslist aufgerufen
    console.log("map.selectMarker() auftragId:", auftragId);
    let auftrag = this.fakeCRUDItem.auftraegeFiltered.filter(auftrag => auftrag.id == auftragId)[0];
    let marker = auftrag['___marker'];
    if(marker != null && marker != undefined) {
      console.log("map.selectMarker() marker:", marker);
      console.log("map.selectMarker() marker.icon:", marker.icon);
      console.log("map.selectMarker() marker._icon:", marker['_icon']);

      //marker.options.icon.options.className = "blinking";
      //marker.options._icon.style.animation = "blinker 1s step-start infinite;";
      //marker.options._icon.className = "blinking";

      let latlng = marker.getLatLng();
      console.log("map.selectMarker() latlng:", latlng);
      this.map.panTo(latlng);
      window['___lastclickedMarker'] = marker
      //this.markerOnClick(auftragId);

      if(this.leafletMode == 'markercluster' && this.map._zoom <= 8) {
        setTimeout(() => {
          //this.map.setZoom(8); // zumindest so weit ranzoomen, damit er nicht auf einer supergrossen Karte spiderfied
          //this.map.panTo(latlng);
          this.map.setView(latlng, 8); // Wichtig: der setZoom allein könnte evl. den View wieder verschieben, daher diese Kombi aus View+Zoom!
          setTimeout(() => {
            this.selectMarker_pt2_spiderfy(marker, 0);
          }, 500);		
        }, 10);		
      }
      else {
        this.selectMarker_pt2_spiderfy(marker, 0);
      }
    }
  }

  public selectMarker_pt2_spiderfy(marker, retryCounter) {
    if(this.leafletMode == 'markercluster') {
      let visibleObj = this.markerCluster.getVisibleParent(marker);
      if(visibleObj != null) {
        //console.log(visibleObj.getLatLng());
        setTimeout(() => {
          try {
            console.log("map.selectMarker() visibleObj of marker:", visibleObj);
            //console.log("map.selectMarker() visibleObj.hasLayer(marker):", visibleObj.hasLayer(marker));
            //visibleObj.spiderfy(); // https://github.com/Leaflet/Leaflet.markercluster/issues/659 // funktioniert aber nicht zuverlässig! - besser:
            this.markerCluster.zoomToShowLayer(marker, this.selectMarker_pt2_5_callback);
          }
          catch(e) {
            console.log("map.selectMarker() warning during visibleObj.spiderfy/zoomToShowLayer():", e); // vermutlich kein Fehler, sondern der Marker ist einfach bereits sichtbar!
          }
          setTimeout(() => {
            // TODO: Besser wäre das blinken nach callback zu machen
            this.selectMarker_pt3_blink(marker);
          }, 250);
        }, 10);
      }
      else { // aktuell kein visible Parent !!! - vermutlich weil er das im Moment wegen Neu-Zoom neu aufbaut - oder ausserhalb sichtbarem Bereich > retry!
        if(retryCounter < 20) {
          console.log("map.selectMarker() no visibleObj of marker! - retry!");
          let latlng = marker.getLatLng();
          console.log("map.selectMarker() latlng:", latlng);
          this.map.panTo(latlng);
          setTimeout(() => {
            this.selectMarker_pt2_spiderfy(marker, retryCounter+1);
          }, 250);
        }
        else {
          console.log("map.selectMarker() no visibleObj of marker! - end of retries!");
        }
      }
    }
    else {
      this.selectMarker_pt3_blink(marker);
    }
  }

  public selectMarker_pt2_5_callback(marker) {
    // not in use - bekommt kein marker
    console.log("map.selectMarker_pt2_5_callback() marker:", marker);
  }

  public selectMarker_pt3_blink(marker) {
    let markerIcon = marker.options.icon;
    let markerIconBlinking = cloneDeep(markerIcon);
    console.log("map.selectMarker() markerIconBlinking:", markerIconBlinking);
    markerIconBlinking.options.className += " blinking";
    marker.setIcon(markerIconBlinking);
  
    setTimeout(() => {
      marker.setIcon(markerIcon);
    }, 1500);		
  }

  public setViewType(type: string /* null = kleine List + Map  / 'mapOnly' / 'listOnly'*/) {
    console.log("map.setViewType() type:", type);
    if(this.app.inAppMode == true) {
      return null;
    }
    try {
      this.viewType = type;

      //this.splitter['panels'].shift();
      //this.panelSizes = [1,99];
      console.log("map.setViewType() splitter:", this.splitter);
  
      let _splitters = document.getElementsByClassName(this.mode == 'map' ? "p-splitter-horizontal" : "p-splitter-vertical");
      let _panels/*:HTMLCollection[]*/ = _splitters[0].getElementsByClassName("p-splitter-panel");
      let _leftpanel = _panels[0];
      let _rightpanel = _panels[1];
      let _gutters = _splitters[0].getElementsByClassName("p-splitter-gutter");
      let _gutter = _gutters[0];
  
      console.log("map.setViewType() _panels:", _panels);
      console.log("map.setViewType() _leftpanel:", _leftpanel);
      console.log("map.setViewType() _rightpanel:", _rightpanel);
      console.log("map.setViewType() _gutters:", _gutters);
  
      //let debug:HTMLElement = null;
      //debug.style['flex-basis'] = null;
      //debug.style.width = '0px !important';
  
      // für ein evtl. wieder-einblenden die alten Werte sichern
      if(this.alreadyAusgeblendet == false 
        //|| type == 'listWithHiddenList' && this.alreadyAusgeblendetListInListMode == false) { // wechsel innerhalb der Liste zu Spezialfall "Liste ausblenden"
        ) {
        this._save_leftpanel_style_flex_basis = _leftpanel['style']['flex-basis'];
        this._save_leftpanel_style_display = _leftpanel['style']['display'];
        this._save_rightpanel_style_flex_basis = _rightpanel['style']['flex-basis'];
        this._save_rightpanel_style_display = _rightpanel['style']['display'];
        this._save_gutter_style_display = _gutter['style']['display'];
        this._save_panelMaxHeight = this.panelMaxHeight;
      }
  
      if(type == 'mapOnly' // wechsel von map mit Liste zu map ohne Liste
        || type == 'listWithHiddenList') { // wechsel innerhalb der Liste zu Spezialfall "Liste ausblenden"
        _leftpanel['style']['flex-basis'] = "0px";
        _leftpanel['style']['display'] = "none";
        //_rightpanel['style']['flex-basis'] = "100%";
        //_rightpanel['style']['display'] = "block";
        _gutter['style']['display'] = "none";
        this.panelMaxHeight = "unset";
      }
      else if(type == 'map') { // wechsel von map ohne Liste zu map mit Liste
        _leftpanel['style']['flex-basis'] = this._save_leftpanel_style_flex_basis;
        _leftpanel['style']['display'] = this._save_leftpanel_style_display;
        _gutter['style']['display'] = this._save_gutter_style_display;
        this.panelMaxHeight = this._save_panelMaxHeight;
      }
      else if(type == 'listOnly') { // wechsel von Liste mit Karte zu Liste ohne Karte
        _rightpanel['style']['flex-basis'] = "0px";
        _rightpanel['style']['display'] = "none";
        //_rightpanel['style']['flex-basis'] = "100%";
        //_rightpanel['style']['display'] = "block";
        _gutter['style']['display'] = "none";
        this.panelMaxHeight = "unset";
      }
      else if(type == 'list') { // wechsel von Liste ohne Karte zu Liste mit Karte
        // leftpanel auch restoren wegen SpezialFall List innerhalb ListMode ausblenden
        _leftpanel['style']['flex-basis'] = this._save_leftpanel_style_flex_basis;
        _leftpanel['style']['display'] = this._save_leftpanel_style_display;
  
        _rightpanel['style']['flex-basis'] = this._save_rightpanel_style_flex_basis;
        _rightpanel['style']['display'] = this._save_rightpanel_style_display;
        _gutter['style']['display'] = this._save_gutter_style_display;
        this.panelMaxHeight = this._save_panelMaxHeight;
      }
  
      //setTimeout(() => {
      //  this.tiles.redraw();
      //}, 100);		
      //this.map.removeLayer(this.tiles);
      //setTimeout(() => {
      //  this.tiles.addTo(this.map);
      //}, 100);		
  
      let _map_container = document.getElementById("mapComponentMapContainer");
      console.log("map.setViewType() _map_container:", _map_container);
      //_map_container.style.width = '99%';
  
      //let viewParms = this.getViewParms();
      //this.initMap(viewParms);
    }
    catch(e) {
      console.error("map.setViewType() catched exception:", e);
    }

    // innerhalb des (veränderten) splitters muss leaflet die Grösse neu errechnen
    setTimeout(() => {
      try {
        this.map.invalidateSize();
      }
      catch(e) {
        console.log("map.setViewType() catched exception during invalidateSize():", e);
      }
    }, 100);		

  }

  public getCurrentViewParms():any {
    //console.log("map.getCurrentViewParms() map:", this.map);
    let zoom = this.map.getZoom();
    //console.log("map.getCurrentViewParms() zoom:", zoom);
    let center = this.map.getCenter();
    //console.log("map.getCurrentViewParms() center:", center);
    //let urlParms = "zoom="+zoom+"&lat="+center.lat+"&lng="+center.lng;
    //return urlParms;
    let urlParamsObj = {
      zoom: zoom,
      lat: center.lat,
      lng: center.lng
    };
    console.log("map.getCurrentViewParms() urlParamsObj:", urlParamsObj);

    // debug: per Punkt anzeigen!
    /*let circle = L.circle([urlParamsObj.lat, urlParamsObj.lng], {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 1,
      radius: 500,
      className: 'myLeafletCircle'
    });
    circle.addTo(this.map);*/

    return urlParamsObj;
  }

  aufgabeStartenClicked(auftragid) {
    console.log("map.debug() aufgabeStartenClicked");

    if(this.offlineMode == true) {
      setTimeout(() => {
        let eventData = {
              eventType: "aufgabeStart",
              data: JSON.stringify(
              {
                auftragId: auftragid
              })
        };
        
        this.app.sendMessageToDotNet(eventData);
      }, 10);		
    }
    else {
      this.app.blockUI = true;
      setTimeout(() => {
        //this.router.navigate(['/auftragscheckliste/1']);
        let routeBuilderAuftragsCheckliste = new RouteBuilderAuftragsCheckliste(this.app, this.router, this.kopfdatenService, this.dokumenteService, this.messageWrapperService)
        routeBuilderAuftragsCheckliste.routeTo(auftragid, this.mode);
        routeBuilderAuftragsCheckliste = null;
        //this.app.blockUI = false; // macht RouteBuilderAuftragsCheckliste.routeTo() !
      }, 100);		
    }
  }

  stammdatenAendernClicked(auftrag: IAuftragsdaten) {
    console.log("map.debug() stammdatenAendernClicked");
    if(this.app.inAppMode == true) {
      setTimeout(() => {
        let eventData = {
          eventType: "stammdatenKorrekturStart",
          data: JSON.stringify(
          {
            auftragId: auftrag.id,
          })
        };
        this.app.sendMessageToDotNet(eventData);
      }, 10);
    }
    else {
      this.router.navigate(['/stammdaten-korrektur/0'], { queryParams: { kundenNr: auftrag.kundennummer, behaelterNr: auftrag.behaelternummer, auftragsId: auftrag.id, nav: this.mode} });
    }
  }

  setInAppPanelSizes() {
    console.log("Map.setInAppPanelSizes()");
    //let landscape: Boolean = false;
    this.landscape = screen.availWidth > screen.availHeight;
    if(this.auftraegeListAppModeShow == true) { // mit list
      if(!this.landscape) { // portrait
        this.inAppModeStyleGrid = "grid-template-columns: 100vW";

        this.inAppModeStyleMapContainer = "min-height: calc(50vH - 0px); top: 0px; min-width: 100%;"; 
        this.inAppModeStyleMapFrame = "min-height: calc(50vH - 0px); top: 0px; min-width: 100%;"; 
        this.inAppModeStyleMapMap = "min-height: calc(50vH - 2px); min-width: 100%;" 

        this.inAppModeStyleListContainer = "max-height: calc(50vH - 0px); top: calc(50vH - 0px); width: 100%;"; 
      }
      else { // landscape
        this.inAppModeStyleGrid = "grid-template-columns: 50vW 50vW";

        this.inAppModeStyleMapContainer = "min-height: calc(100vH - 0px); top: 0px; min-width: 100%;"; 
        this.inAppModeStyleMapFrame = "min-height: calc(100vH - 0px); top: 0px; min-width: 100%;"; 
        this.inAppModeStyleMapMap = "min-height: calc(100vH - 2px); min-width: 100%;" 

        this.inAppModeStyleListContainer = "max-height: calc(100vH - 0px); top: calc(50vH - 0px); width: 100%;"; 
      }
    }
    else { // ohne list
      if(!this.landscape) { // portrait
        // DEFAULT: Portrait ohne List:
        this.inAppModeStyleGrid = "grid-template-columns: 100vW";

        this.inAppModeStyleMapContainer = "min-height: calc(100vH - 0px); top: 0px; min-width: 100%;"; // mit topbar wären 50px
        this.inAppModeStyleMapFrame = "min-height: calc(100vH - 0px); top: 0px; min-width: 100%;"; // mit topbar wären 50px
        this.inAppModeStyleMapMap = "min-height: calc(100vH - 2px); min-width: 100%;" // mit topbar wären 50px, TODO: warum -2 ??? frame ???

        this.inAppModeStyleListContainer = "height: 0px; top: 0px; width: 0px;"; 
      }
      else { // landscape - GLEICH!
        this.inAppModeStyleGrid = "grid-template-columns: 100vW";

        this.inAppModeStyleMapContainer = "min-height: calc(100vH - 0px); top: 0px; min-width: 100%;"; // mit topbar wären 50px
        this.inAppModeStyleMapFrame = "min-height: calc(100vH - 0px); top: 0px; min-width: 100%;"; // mit topbar wären 50px
        this.inAppModeStyleMapMap = "min-height: calc(100vH - 2px); min-width: 100%;" // mit topbar wären 50px, TODO: warum -2 ??? frame ???

        this.inAppModeStyleListContainer = "height: 0px; top: 0px; width: 0px;"; 
      }
    }

    setTimeout(() => {
      try {
        this.map.invalidateSize();
      }
      catch(e) {
        console.error("map.setInAppPanelSizes() catched exception during invalidateSize():"+ e);
      }
    }, 10);		

  }

  showList_changed(buttonLM?: string, forceListRedraw?: boolean) { // buttonLM: "L" = Button Liste ein/ausblenden geklickt  / ansonsten blank oder "M" (wegen SpezialFall "Liste ausblenden in List Mode")
    console.log("Map.showList_changed() buttonLM:", buttonLM);
    if(this.app.inAppMode == true) {
      if(buttonLM == 'L') this.auftraegeListAppModeShow = true;
      else this.auftraegeListAppModeShow = false;

      this.setInAppPanelSizes();

      setTimeout(() => {
        if(this.auftragsdatenList != null) this.auftragsdatenList.sizeTable();
      }, 250);		
    }
    else { // Desktop Mode
      //if(this.mode == 'map') this.setViewType('mapOnly');
      //if(this.mode == 'list') this.setViewType('listOnly');

      console.log("Map.showList_changed() auftragsdatenList:", this.auftragsdatenList);
      console.log("Map.showList_changed() auftragsdatenList.killTable_notInUse:", this.auftragsdatenList.killTable_notInUse);
      console.log("Map.showList_changed() auftragsdatenList.cols:", this.auftragsdatenList.cols);
      console.log("Map.showList_changed() auftragsdatenList.crudItems", this.auftragsdatenList.crudItems);
      switch(true) {
        case this.mode == 'list' && this.alreadyAusgeblendet == false:
          this.setViewType(buttonLM != null && buttonLM == "L" ? 'listWithHiddenList' : 'listOnly'); // SpezialFall "Liste ausblenden in List Mode"
          break;
        case this.mode == 'map' && this.alreadyAusgeblendet == false:
          this.setViewType('mapOnly');
          break;
        case this.mode == 'list' && this.alreadyAusgeblendet == true:
          this.setViewType('list');
          break;
        case this.mode == 'map' && this.alreadyAusgeblendet == true:
          this.setViewType('map');
          break;
   
        default:
          break;
      }

      this.alreadyAusgeblendet = !this.alreadyAusgeblendet;
      this.alreadyAusgeblendetLastML = buttonLM != null ? buttonLM : 'M'; // null ist wie M!

      console.log("map.showList_changed() scrollHeight BEFORE:", this.auftragsdatenList.scrollHeight);  
      setTimeout(() => {
        if(this.auftragsdatenList != null) this.auftragsdatenList.sizeTable(forceListRedraw);
      }, 250);		
    }
  }

  sizeFrame_inAppMode() {
    console.log("map.sizeFrame_inAppMode()");
    if(this.app.inAppMode != true) return; // ist nur für inAppMode relevant

    setTimeout(() => {
      try {
        this.setInAppPanelSizes();
        //this.map.invalidateSize(); // wird sowieso von setInAppPanelSizes aufgerufen
        //setTimeout(() => {
        //  if(this.auftragsdatenList != null) this.auftragsdatenList.sizeTable(); // wird sowieso vom handler in auftragsdatenList aufgerufen!
        //}, 250);	
      }
      catch(e) {
        console.error("map.sizeFrame_inAppMode() catched exception during invalidateSize():"+ e);
      }
    }, 100);		
  }

  refreshLastclickedAuftrag_Checkliste(auftrag: IAuftragsdaten) {
    this.lastclickedAuftrag_Checkliste = null;
    if(auftrag != null) {
      let thisInstance = this;
      this.kopfdatenService.getKopfdatenIdAndChecklisteIdAndDokumentIdForAuftragsId(auftrag.id).subscribe(response => {
          console.log("Map.refreshLastclickedAuftrag_Checkliste() response:", response);
  
          this.checklisteService.getCheckliste(response.checklisteId, true /*cropDokument*/, true /*cropLeitfaden*/).subscribe(function (response) {
            console.log("Map.refreshLastclickedAuftrag_Checkliste() response from getting Checkliste. response:", response);
            thisInstance.lastclickedAuftrag_Checkliste = response;
          }, function (error) { 
            console.error("Map.refreshLastclickedAuftrag_Checkliste() error getting Checkliste. error:", error);
            ///*return*/ this.handleError(error); 
          });
  
      }, error => {
          console.error("Map.refreshLastclickedAuftrag_Checkliste() error:", error);
          ///*return*/ this.handleError(error); 
      });
    }
  }

  notizAendernClicked() {
    this.dialogAuftragShow = false;
    this.dialogEditNotizShow = true;
  }

  notizSpeichernClicked() {
    console.log("Map.notizSpeichernClicked()");
    let mapComponent = this;
    this.auftragsdatenService.updateAuftragsdatenForNotizDienstleister(this.lastclickedAuftrag.id, this.lastclickedAuftrag.notizDienstleister).subscribe(function (response) {
      console.log("Map.notizSpeichernClicked() response from updateAuftragsdaten Auftragsdaten. response:", response);
      mapComponent.messageWrapperService.postTimedMessage({ severity: 'success', summary: "Speichern erfolgreich", detail: "Notiz" }) // TO DO
    }, function (error) { 
      console.error("Map.notizSpeichernClicked() error from updateAuftragsdaten. error:", error);
      return this.handleError(error); 
    });

    this.dialogEditNotizShow = false;
  }

  public dialogAuftragHide() { // Aufruf aus RouteBuilderAuftragsCheckliste
    console.log("map.dialogAuftragHide()");
    this.dialogAuftragShow = false;
  }

  public dialogAuftragUnhide() { // Aufruf aus AuftragsChecklisteDetail
    console.log("map.dialogAuftragUnhide()");
    this.dialogAuftragShow = true;
  }

  /*offlineStorageLayer(baseLayer, layerswitcher) { // aus https://github.com/allartk/leaflet.offline/blob/master/docs/src/index/storageLayer.js
    let layer;
  
    let getGeoJsonData = () =>
    LO.getStorageInfo(this.tileUrlTemplate).then((tiles) =>
      LO.getStoredTilesAsJson(baseLayer.getTileSize(), tiles),
    );

    let addStorageLayer = () => {
      getGeoJsonData().then((geojson) => {
        layer = L.geoJSON(geojson).bindPopup(
          (clickedLayer) => clickedLayer['feature']['properties'].key,
        );
        layerswitcher.addOverlay(layer, 'offline tiles');
      });
    };

    addStorageLayer();

    baseLayer.on('storagesize', (e) => {
      if (layer) {
        layer.clearLayers();
        getGeoJsonData().then((data) => {
          layer.addData(data);
        });
      }
    });
  }*/

  offlineToggleInit() {
    console.log("map.offlineToggleInit()");
    let offlineToggleSaved = localStorage.getItem("OFFLINETOGGLE");
    if(offlineToggleSaved == null) offlineToggleSaved = /*this.offlineToogleActualSystemState*/'auto';

    this.offlineToggleSwitchTo(offlineToggleSaved); // setzt nur die GUI
    try {
      navigator[this.simulateNetworkIOS ? 'NOTEXISTINGAPI' : 'connection'].addEventListener('change', this.offlineToggleConnectionChanged_from_API);
    }
    catch (e) {
      console.error("map.offlineToggleInit() Error implementing navigator.connection listener (1): ", e);
    }

    if(offlineToggleSaved == "auto") { // bei Auto: initial gemäß aktueller Netzwerkverbindung aufrufen (das macht den offlineToggleSwitchTo() mit)
      try {
        setTimeout(() => {
          this.offlineToggleUpdateAuto(this, this.offlineToogleActualSystemState == 'on' || this.offlineToogleActualSystemState == 'off' ? this.offlineToogleActualSystemState : null); // bei iOS: den startWert aus queryParms mitgeben
        }, 200);
      }
      catch (e) {
        console.error("map.ngAfterViewInit() Error implementing navigator.connection listener (2): ", e);
        //this.offlineToggleRemoveAuto(); // nicht deaktivieren, iOS wird events senden
        if(offlineToggleSaved == null) this.offlineToggleSwitchTo("off");
      }
    }
    else {
      this.offlineToogleChanged(null);
    }
  }

  offlineToggleUpdateAuto(mapComponent: MapComponent, offlineToggleAutoAtTheMoment: string) {
    console.log("map.offlineToggleUpdateAuto()");
    if(mapComponent.offlineToggleSelected != null && mapComponent.offlineToggleSelected.value == 'auto' /*&& mapComponent.offlineToggleAutoAtTheMoment != offlineToggleAutoAtTheMoment*/) {
      if(offlineToggleAutoAtTheMoment == "on") mapComponent.offlineToggleGoOnline();
      else mapComponent.offlineToggleGoOffline();
    }
    else {
      console.log("map.offlineToggleUpdateAuto() skip changing mode...");
    }
    //mapComponent.offlineToggleAutoAtTheMoment = offlineToggleAutoAtTheMoment;
    mapComponent.offlineToggleShowValueForAuto(mapComponent);
  }

  offlineToogleChanged(event) {
    console.log("map.offlineToogleChanged() event: ", event);
    localStorage.setItem("OFFLINETOGGLE", this.offlineToggleSelected.value);
    this.offlineToggleSwitchOnOff();

    // wenn nicht auto, dann den underline bei offline/online rausnehmen
    if(this.offlineToggleSelected.value != "auto") this.offlineToggleShowValueForAutoUnderline(null);
    else {
      // z.B. wenn iOS mit localstorage off gestartet, wieder mit off reingekommen, dann manuell auf auto geklickt
      if(this.offlineToogleActualSystemState != null && this.offlineToogleActualSystemState != 'auto') this.offlineToggleShowValueForAutoUnderline(this.offlineToogleActualSystemState);
    }
}

  offlineToggleSwitchTo(offOnAuto: string) {
    console.log("map.offlineToggleSwitchTo() offOnAuto: ", offOnAuto);
    let found = this.offlineToggleOptions.find(f => f.value == offOnAuto);
    if(found != null) this.offlineToggleSelected = found;
  }

  offlineToggleRemoveAuto() {
    console.log("map.offlineToggleRemoveAuto()");
    this.offlineToggleOptions = this.offlineToggleOptions.filter(f => f.value != "auto");
  }

  public offlineToggleShowValueForAuto(mapComponent: MapComponent) {
    //console.log("map.offlineToggleShowValueForAuto() offlineToggleAutoAtTheMoment:", mapComponent.offlineToggleAutoAtTheMoment);
    console.log("map.offlineToggleShowValueForAuto() offlineToogleActualSystemState:", mapComponent.offlineToogleActualSystemState);
    let divMitAllenToggleButtons = document.getElementsByClassName("offlineToggle");
    let offlineToggleButtonOffline = divMitAllenToggleButtons[0].children[0];
    let offlineToggleButtonOnline = divMitAllenToggleButtons[0].children[2];
    if(mapComponent./*offlineToggleAutoAtTheMoment*/offlineToogleActualSystemState == "on") {
      mapComponent.offlineToggleShowValueForAutoUnderline("on");
    }
    else if(mapComponent./*offlineToggleAutoAtTheMoment*/offlineToogleActualSystemState == "off") {
      mapComponent.offlineToggleShowValueForAutoUnderline("off");
    }
    else {
      mapComponent.offlineToggleShowValueForAutoUnderline(null);
    }
  }

  offlineToggleShowValueForAutoUnderline(onOffNull: string) {
    console.log("map.offlineToggleShowValueForAutoUnderline() onOffNothing:", onOffNull);
    let divMitAllenToggleButtons = document.getElementsByClassName("offlineToggle");
    let offlineToggleButtonOffline = divMitAllenToggleButtons[0].children[0];
    let offlineToggleButtonOnline = divMitAllenToggleButtons[0].children[2];
    if(onOffNull == "on") {
      offlineToggleButtonOffline['style'].textDecoration = null;
      offlineToggleButtonOnline['style'].textDecoration = "underline";
    }
    else if(onOffNull == "off") {
      offlineToggleButtonOffline['style'].textDecoration = "underline";
      offlineToggleButtonOnline['style'].textDecoration = null;
    }
    else {
      offlineToggleButtonOffline['style'].textDecoration = null;
      offlineToggleButtonOnline['style'].textDecoration = null;
    }
  }

  offlineToggleConnectionChanged_from_API(/*initialCall:?boolean*/evtUNUSED?: any) {
    try {
      let mapComponent = window["___mapComponent"];

      let offlineToggleAutoAtTheMoment = mapComponent.offlineToggleGetActualNetworkStateFromAPI(mapComponent);
      mapComponent.offlineToogleActualSystemState = offlineToggleAutoAtTheMoment;

      mapComponent.offlineToggleUpdateAuto(mapComponent, offlineToggleAutoAtTheMoment);

      mapComponent.offlineToogleActualSystemState = offlineToggleAutoAtTheMoment; // soll den gleichen Effekt haben, wie von vorne herein so aufgerufen
    }
    catch(e) {
      console.error("map.offlineToggleConnectionChanged_from_API() error:", e);
    }
  }

  private offlineToggleGetActualNetworkStateFromAPI(mapComponent: MapComponent): string {
    console.log("map.offlineToggleGetActualNetworkStateFromAPI()");

    let actualNetworkState = "off";
    try {
      let connection: any = navigator[mapComponent.simulateNetworkIOS ? 'NOTEXISTINGAPI' : 'connection'];
      console.log("map.offlineToggleGetActualNetworkStateFromAPI() connection.downlink/connection.effectiveType:", connection.downlink, connection.effectiveType);
  
      let connection_effectiveType = mapComponent.simulateNetwork2g == true ? '2g' : connection.effectiveType;
      if(connection.downlink > 0 && connection_effectiveType != 'slow-2g' && connection_effectiveType != '2g') {
        actualNetworkState = "on";
        console.log("map.offlineToggleGetActualNetworkStateFromAPI() it's online / >2g!");
      }
      else {
        console.log("map.offlineToggleGetActualNetworkStateFromAPI() it's offline / 2g!");
      }
    }
    catch(e) {
      console.error("map.offlineToggleGetActualNetworkStateFromAPI() error reading navigator.connection:", e);
      actualNetworkState = null;
    }

    console.log("map.offlineToggleGetActualNetworkStateFromAPI() returns: ", actualNetworkState);
    return actualNetworkState;
  }

  public offlineToggleConnectionChanged_from_MAUI(offlineToggleAutoAtTheMoment: string) {
    //console.log("map.offlineToggleConnectionChanged_from_MAUI() offlineToggleAutoAtTheMoment:", offlineToggleAutoAtTheMoment);
    try {
      let mapComponent = window["___mapComponent"];
      mapComponent.offlineToogleActualSystemState = offlineToggleAutoAtTheMoment;

      mapComponent.offlineToggleUpdateAuto(mapComponent, offlineToggleAutoAtTheMoment);

      mapComponent.offlineToogleActualSystemState = offlineToggleAutoAtTheMoment; // soll den gleichen Effekt haben, wie von vorne herein so aufgerufen
    }
    catch(e) {
      console.error("map.offlineToggleConnectionChanged_from_MAUI() error:", e);
    }
  }

  debug() {
    console.log("map.debug() fakeCRUDItem:", this.fakeCRUDItem);
    console.log("map.debug() oms:", this.oms);
  }

  /*dbg_showOfflineStorage() {
    console.log("map.dbg_showOfflineStorage()", this.map.getBounds());
    let _northEast = this.map.getBounds()._northEast;
    let _southWest = this.map.getBounds()._southWest;
    let centerLat = ((_northEast.lat - _southWest.lat) / 2) + _southWest.lat;
    let centerLng = ((_northEast.lng - _southWest.lng) / 2) + _southWest.lng;
    console.log("map.dbg_showOfflineStorage()", centerLat, centerLng);
    let summary: number = 0;
    LO.getStorageInfo(this.tileUrlTemplate).then((r) => {
      for (let i = 0; i < r.length; i += 1) {
        //console.log("i: "+i+" "+r[i].url+" bytes: "+r[i].blob.size);
        summary += r[i].blob.size;
      };
      let kb = summary / 1000; // 1024?
      let mb = kb / 1000; // 1024?
      console.log("summary: bytes: "+summary+" = mb: "+mb);
      alert("summary: tiles: "+r.length+" mb: "+mb);
    });
  }*/

  offlineToggleSwitchOnOff() {
    console.log("map.offlineToggleSwitchOnOff()");
    if(this.offlineToggleSelected == null) {
      console.error("map.offlineToggleSwitchOnOff() offlineToggleSelected = null!");
    }
    else {
      if(this.offlineToggleSelected.value == "on") this.offlineToggleGoOnline();
      else if(this.offlineToggleSelected.value == "off") this.offlineToggleGoOffline();
      else {
        //this.offlineToggleConnectionChanged_from_API();
        let mapComponent = window["___mapComponent"];
        mapComponent.offlineToggleUpdateAuto(mapComponent, mapComponent.offlineToogleActualSystemState);
      }
    }
  }

  offlineToggleGoOnline() {
    console.log("map.offlineToggleGoOnline()");
    this.tiles.options.maxNativeZoom = 18;
    //this.map.options.maxNativeZoom = 18;
    setTimeout(() => {
      //this.tiles.redraw();
      /*let zoom = this.map.getZoom();
      if(zoom < 18) {
        this.map.setZoom(zoom+1);
      }
      else {
        this.map.setZoom(zoom-1);
      }*/
      this.map.removeLayer(this.tiles);
      setTimeout(() => {
        //this.map.setZoom(zoom);
        this.tiles.addTo(this.map);

        if(this.debugMode==true) this.setGreyScale(false);
      }, 100);
    }, 10);		
  }

  setGreyScale(on: boolean) {
    console.log("map.setGreyScale() on:", on);
    let mapDiv = document.getElementById("map");
    mapDiv.style.filter = on==true ? 'grayscale(1)' : null;
  }

  deleteAllObjectsInObject(obj: object) { // innerhalb eines objects alle objekte löschen - z.B. um z.B. vor Zurücksenden an die API alle Rekursivitäten zu vermeiden
    for(var propertyName in obj) {
      if(typeof obj[propertyName] === 'object') {
        delete obj[propertyName];
      }
    }
  }

  /*offlineSaveTiles() {
    console.log("map.offlineLoadTiles()");
    this.offlineSaveControl._saveTiles();
  }

  offlineRemoveTiles() {
    console.log("map.offlineLoadTiles()");
    this.offlineSaveControl._rmTiles();
  }

  offlineHideControls() {
    console.log("map.offlineHideControls()");
    let elements = document.getElementsByClassName("savetiles");
    if(elements != null && elements.length > 0) {
      elements[0]['style'].display = "none";
    }

    let elementsLayers = document.getElementsByClassName("leaflet-right");
    if(elementsLayers != null && elementsLayers.length > 0) {
      elementsLayers[0]['style'].display = "none";
    }
  }

  offlineShowControls() {
    console.log("map.offlineShowControls()");
    let elements = document.getElementsByClassName("savetiles");
    if(elements != null && elements.length > 0) {
      elements[0]['style'].display = "unset";
    }

    let elementsLayers = document.getElementsByClassName("leaflet-right");
    if(elementsLayers != null && elementsLayers.length > 0) {
      elementsLayers[0]['style'].display = "unset";
    }
  }

  offlineZoomD() {
    let mapDiv = document.getElementById('map');
    mapDiv.style.minWidth = 'unset';
    mapDiv.style.minHeight = 'unset';
    setTimeout(() => {
      mapDiv.style.width = '210px';
      mapDiv.style.height = '290px';
  
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
  
        setTimeout(() => {
          this.map.setView(new L.LatLng(51.34921788779875, 10.446875), 5);
        }, 10);			
    
      }, 10);
    }, 10);
  }*/

  offlineToggleGoOffline() {
    console.log("map.offlineToggleGoOffline()");

    this.tiles.options.maxNativeZoom = 9;
    //this.map.options.maxNativeZoom = 9;
    setTimeout(() => {
      this.map.removeLayer(this.tiles);
      setTimeout(() => {
        this.tiles.addTo(this.map);

        if(this.debugMode==true) this.setGreyScale(true);
      }, 100);
    }, 10);			
  }

  debugLastClickedAuftrag() {
    console.log("map.debugLastClickedAuftrag() lastclickedAuftrag:", this.lastclickedAuftrag);
  }

  dbg_localStorage_removeOfflineToggle() {
    console.log("map.dbg_localStorage_removeOfflineToggle()");
    localStorage.removeItem("OFFLINETOGGLE");
  }
  dbg_start_on() {
    console.log("map.dbg_start_on()");
    location.href = "http://localhost:4800/?offline=T&route=map&network=on";
  }
  dbg_start_off() {
    console.log("map.dbg_start_off()");
    location.href = "http://localhost:4800/?offline=T&route=map&network=off";
  }
  dbg_start_without_on_off() {
    console.log("map.dbg_start_without_on_off()");
    location.href = "http://localhost:4800/?offline=T&route=map";
  }
  dbg_zoom_12() {
    console.log("map.dbg_zoom_12()");
    window['___mapComponent'].map.setZoom(12);
  }
  dbg_ConnectionChanged_from_MAUI_off() {
    console.log("map.dbg_ConnectionChanged_from_MAUI_off()");
    window['___mapComponent'].offlineToggleConnectionChanged_from_MAUI('off');
  }
  dbg_ConnectionChanged_from_MAUI_on() {
    console.log("map.dbg_ConnectionChanged_from_MAUI_on()");
    window['___mapComponent'].offlineToggleConnectionChanged_from_MAUI('on');
  }

}
