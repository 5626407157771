
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

const httpOptions =
  new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });

@Injectable()
export class AppconfigService {

  private config: Object = null;
  private env: Object = null;

  constructor(private httpClient: HttpClient) {

  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public get(key: any) {
    if(key == "dataMode" && this.config[key] == undefined) return "online"; // dataMode ggf. "online" zurückgeben - um es von der offline-Version zu unterscheiden
    else return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  // public load() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get('config/env.json').map(res => res.json()).catch((error: any): any => {
  //       console.log('Configuration file "env.json" could not be read');
  //       console.log(error);
  //       resolve(true);
  //       return observableThrowError(error.error || 'Server error');
  //     }).subscribe((envResponse) => {
  //       this.env = envResponse;
  //       let request: any = null;

  //       switch (envResponse.env) {
  //         case 'production': {
  //           request = this.http.get('config/config.' + envResponse.env + '.json');
  //         } break;

  //         case 'development': {
  //           request = this.http.get('config/config.' + envResponse.env + '.json');
  //         } break;

  //         case 'default': {
  //           console.error('Environment file is not set or invalid');
  //           resolve(true);
  //         } break;
  //       }

  //       if (request) {
  //         request
  //           .map(res => res.json())
  //           .catch((error: any) => {
  //             console.error('Error reading ' + envResponse.env + ' configuration file');
  //             resolve(error);
  //             return observableThrowError(error.error || 'Server error');
  //           })
  //           .subscribe((responseData) => {
  //             this.config = responseData;
  //             resolve(true);
  //           });
  //       } else {
  //         console.error('Env config file "env.json" is not valid');
  //         resolve(true);
  //       }
  //     });

  //   }); 
  // }

  public load() {
    return new Promise((resolve, reject) => {
      this.httpClient.get<any>('config/env.json', { observe: 'body' })
        .pipe(
          map(res => res),
          catchError((error) => {
            console.log('Configuration file "env.json" could not be read');
            resolve(true);
            return observableThrowError(error.error || 'Server error');
          }
          ))
        .subscribe((envResponse) => {
          console.log('AppConfig load() envResponse:', envResponse);
          this.env = envResponse;
          let request: any = null;

          switch (envResponse.env) {
            case 'production': {
              request = this.httpClient.get('config/config.' + envResponse.env + '.json', { observe: 'body' });
            } break;

            case 'development': {
              request = this.httpClient.get('config/config.' + envResponse.env + '.json', { observe: 'body' });
            } break;

            case 'default': {
              console.error('Environment file is not set or invalid');
              resolve(true);
            } break;
          }

          if (request) {
            request
              .pipe(
                map(res => res),
                catchError((error: any) => {
                  console.error('Error reading ' + envResponse.env + ' configuration file');
                  resolve(error);
                  return observableThrowError(error.error || 'Server error');
                })
              )
              .subscribe((responseData) => {
                this.config = responseData;
                resolve(true);
              });
          } else {
            console.error('Env config file "env.json" is not valid');
            resolve(true);
          }
        });

    });
  }
}
