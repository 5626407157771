<!-- START CRUD STANDARD -->
<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->

          <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
          <div class="titlebar-button-grid">
            <div>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
            </div>
            <div style="text-align: center;">
              <span style="line-height: 35px; font-size: medium; font-weight: 500;">{{pageTitle}}</span>
            </div>
            <div>
              <span class="floatRight">
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
              </span>
            </div>
          </div>

        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card" style="display: none;"><!--MODI: kein Footer-->
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>
<!-- ENDE CRUD STANDARD -->


<ng-template #CRUDChildTemplate>
  <div class="detail-grid">

    <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
      <span class="p-float-label">
        <input style="width:90%" type="text" pInputText formControlName="reihenfolge">
        <label>{{'Reihenfolge'|translate:true}}</label>
      </span>
    </div>
    <div class="detail-grid-sub">
      <div class="errorMessage">
        {{displayMessage.reihenfolge}}
      </div>
    </div>

            <div [formGroup]="CRUDForm" *ngIf="typ == 'dropdown'" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="bezeichnung">
                <label>{{'Bezeichnung'|translate:true}}</label>
              </span>
            </div>
            <div class="detail-grid-sub" *ngIf="typ == 'dropdown'">
              <div class="errorMessage">
                {{displayMessage.bezeichnung}}
              </div>
            </div>

  <!--          <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="checklistePositionenFelderId">
                <label>{{'Checkliste Positionen Felder Id'|translate:true}} *</label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.checklistePositionenFelderId}}
              </div>
            </div>
          -->
            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="feldwert">
                <label>{{'Feldwert'|translate:true}}</label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.feldwert}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="mangel">
                <label>{{'Mangel'|translate:true}}</label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.mangel}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="kennzeichen">
                <label>{{'Kennzeichen'|translate:true}}</label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.kennzeichen}}
              </div>
            </div>
  </div>
</ng-template>
    
