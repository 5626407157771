<!--<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>-->
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument || benutzer == null || benutzer.name == null || benutzer.name.length < 1" class="blockedUI">
  <!--<i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>-->
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="saveWithValidation()" novalidate><!--MODI: save with Validation-->
        <div #divCardToolbar class="card" style="margin-bottom: 0.125rem;">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->

          <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
          <div [ngClass]="{'titlebar-button-grid': !app.inAppMode, 'titlebar-button-grid-inAppMode': app.inAppMode}">
            <div>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <!--<button [disabled]="positionsdatenFotoIsUploading" style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>-->
              <button [disabled]="positionsdatenFotoIsUploading" style="width: auto;" pButton type="button" (click)="saveWithoutValidation()" [icon]="'fa fa-floppy-disk'" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" [label]="app.inAppMode ? null : translate.instant('Zwischenspeichern',true)"></button><!--MODI [disabled]="positionsdatenFotoIsUploading" / Zwischenspeichern ohne Prüfung -->
            </div>
            <!--MODI ggü IMKE: statt pageTitle: LeistungsTitle + Name des Kunden -->
            <div style="text-align: center;">
              <span style="font-size: medium; font-weight: 500;" *ngIf="app != null && app.leistungen != null && app.leistungen.length >0 && auftragsdaten != null">
                <!--{{pageTitle}}-->
                <i class="fa fa-{{ getLeistungForAuftrag(auftragsdaten).icon }}" [class.fa-xl]="app.inAppMode != true"></i>&nbsp;
                {{ getLeistungForAuftrag(auftragsdaten).bezeichnung }}:&nbsp;
                {{auftragsdaten.name_1Behaelterstandort}}
                {{auftragsdaten.name_2Behaelterstandort != null ? ' ' : ''}}{{auftragsdaten.name_2Behaelterstandort}}
                &nbsp;-&nbsp;{{auftragsdaten.ortBehaelterstandort}}
                {{auftragsdaten.ortzusatzBehaelterstandort != null ? ' ' : ''}}{{auftragsdaten.ortzusatzBehaelterstandort}}
              </span>
              <!--<div style="min-height: 1em;"></div>--><!--Abstand - verlagert in den exZone-Container! -->
              <!--<span *ngIf="!app.inAppMode">
                Bitte achten Sie darauf mobile Endgeräte nur außerhalb der Ex-Zone zu verwenden
              </span>-->
            </div>
            <div>
              <span class="floatRight">
              <button *ngIf="debugMode==true" pButton type="button" class="p-button-danger" style="min-width: 5em; font-size: 6px;" (click)="openLeistungForDebug()">Leistung</button>  
              <button *ngIf="debugMode==true" pButton type="button" class="p-button-danger" style="min-width: 5em; font-size: 6px;" (click)="openChecklisteForDebug()">Checkliste</button>  
              <button *ngIf="checkliste != null && checkliste.leitfadenId != null && checkliste.leitfadenId != 0 && app.inAppMode != true" pButton type="button" class="p-button-outlined" style="min-width: 5em;" (click)="leitfadenClicked()">Leitfaden</button>
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" [icon]="app.inAppMode ? 'fa fa-xmark' : null" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" [label]="app.inAppMode ? null : translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
              </span>
            </div>
          </div>

          <div class="exZone-container">
            <div class="exZone-zeile1 exZone-zeile1-links">
                <span class="p-float-label" *ngIf="zaehlerEnabled == true && app.inAppMode == false">
                  <!-- "readonlyInput" heisst: schon änderbar! aber eben nicht per text-input! -->
                  <p-calendar [readonlyInput]="app.inAppMode" [locale]="DE" dateFormat="dd.mm.yy" [showTime]="false" [stepMinute]="01" [showSeconds]="true" [defaultDate]="dateNow" formControlName="_Auftragsdaten_Wiedervorlage" appendTo="body" 
                  [style]="{'width' : '100%', 'max-height': '2.25em'}"
                  [inputStyle]="{'padding-left': '0', 'padding-right': '0'}"
                  ></p-calendar>
                  <label class="exZoneLabel">{{'Wiedervorlage'|translate:true}}</label>
                </span>
            </div>
            <div class="exZone-zeile1 exZone-zeile1-rechts"><!-- dummy: muss es geben, damit der Text in der 1. Zeile centered ist --></div>
            Bitte achten Sie darauf mobile Endgeräte nur außerhalb der Ex-Zone zu verwenden
          </div>
        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card" style="display: none;"><!--MODI: kein Footer-->
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<!--<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>-->

<ng-template #CRUDChildTemplate>
  <p-tabView #tabView [activeIndex]="tabSelected" (onChange)="tabSelectedChanged()" [styleClass]="debugMode ? 'auftragsChecklisteTabView_Debug' : 'auftragsChecklisteTabView'">
    <p-tabPanel header="Start" *ngIf="tabShowStart == true">
    <div [style]="{'overflow-y': 'auto'}"><!--funktioniert bei Pos, hat hier aber keine Auswirkung-->
      <div class="details-grid-container" *ngIf="app != null && app.leistungen != null && app.leistungen.length >0 && auftragsdaten != null">
        <!-- zeile 1: Behälterstandort -->
        <div>
            <i class="fa fa-location"></i>
        </div>
        <div>
            {{auftragsdaten.name_1Behaelterstandort}} {{auftragsdaten.name_2Behaelterstandort}}<br>
            {{auftragsdaten.strasseBehaelterstandort}}<br>
            {{auftragsdaten.plzBehaelterstandort}} {{auftragsdaten.ortBehaelterstandort}} {{auftragsdaten.ortzusatzBehaelterstandort}}
        </div>

        <!-- nächste: Bemerkungen -->
        <div  style="color: red;" *ngIf="auftragsdaten.auftragsbemerkung != null && auftragsdaten.auftragsbemerkung.length > 0">
          <i class="fa-solid fa-circle-info" style="color: {{app.colorButtons}};"></i>
        </div>
        <div  style="color: red; font-weight: bold;" *ngIf="auftragsdaten.auftragsbemerkung != null && auftragsdaten.auftragsbemerkung.length > 0">
          {{auftragsdaten.auftragsbemerkung}}
        </div>

        <!-- nächste: Notizen -->
        <div *ngIf="auftragsdaten.notizDienstleister != null && auftragsdaten.notizDienstleister.length > 0">
          <i class="fa-solid fa-circle-info" style="color: {{app.colorButtons}};"></i>
        </div>
        <div *ngIf="auftragsdaten.notizDienstleister != null && auftragsdaten.notizDienstleister.length > 0">
          {{auftragsdaten.notizDienstleister}}
        </div>

        <!-- nächste: Kunden-Nr. / Behälter / Fälligkeit -->
        <div>
          &nbsp;
        </div>
        <div>
          <div [ngClass]="{'details-grid-tablelike-container': !app.inAppMode, 'details-grid-tablelike-container-inAppMode': app.inAppMode}">

            <!-- 1. zeile: Kunden-Nr. + Beh.Nr. -->
            <div>
              Kunden-Nr.:
            </div>
            <div>
              {{auftragsdaten.kundennummer}}
            </div>
            <div>
              Behälter-Nr.:
            </div>
            <div>
              {{auftragsdaten.behaelternummer}}
            </div>
            <!-- nä. zeile: Behälter-BJ. + Inhalt -->
            <div>
              Baujahr:
            </div>
            <div>
              {{auftragsdaten.baujahr | datumOhneZeit}}
            </div>
            <div>
              Nettoinhalt:
            </div>
            <div>
              {{auftragsdaten.fuellvolumen}} Liter
            </div>
            <!-- nä. zeile: Hersteller und Lagerungsart -->
            <div>
              Hersteller:
            </div>
            <div>
              {{auftragsdaten.auftragsdatenHersteller.bezeichnung}}
            </div>
            <div>
              {{auftragsdaten.lagerungsart == '01' ? 'Tankfarbe:' : null}}<div *ngIf="auftragsdaten.lagerungsart == '01'" style="font-size: 0.25em;"><br></div>
              <p-checkbox *ngIf="auftragsdaten.lagerungsart == '01'" label="oberirdisch" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
              <p-checkbox *ngIf="!(auftragsdaten.lagerungsart == '01')" label="oberirdisch" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
            </div>
            <div>
              {{auftragsdaten.lagerungsart == '01' ? auftragsdaten.tankfarbe : null}}<div *ngIf="auftragsdaten.lagerungsart == '01'" style="font-size: 0.25em;"><br></div>
              <p-checkbox *ngIf="auftragsdaten.lagerungsart >= '02'" label="erdgedeckt" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
              <p-checkbox *ngIf="!(auftragsdaten.lagerungsart >= '02')" label="erdgedeckt" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
            </div>

          </div>

        </div>

        <!-- evtl. nä. zeile: Hinweistext -->
        <div *ngIf="checkliste != null && checkliste.hinweistext != null && checkliste.hinweistext.length > 0">
          <i class="fa fa-circle-info" style="color: {{app.colorButtons}};"></i>
        </div>
        <div *ngIf="checkliste != null && checkliste.hinweistext != null && checkliste.hinweistext.length > 0">
          <span  style="white-space: pre-line;">
            {{checkliste.hinweistext}}
          </span>
        </div>

      </div>

      <br><br>

      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" style="margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <!-- "readonlyInput" heisst: schon änderbar! aber eben nicht per text-input! -->
            <p-calendar [readonlyInput]="app.inAppMode" [locale]="DE" dateFormat="dd.mm.yy" [showTime]="false" [stepMinute]="01" [showSeconds]="true" [defaultDate]="dateNow" [utc]="true" formControlName="pruefdatum" appendTo="body" [style]="{'width' : '100%'}"></p-calendar>
            <label>{{'Prüfdatum'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.pruefdatum}}
          </div>
        </div>

        <!--<div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <crud-basic-autocomplete [type]="'anlagenbeschreibung'" formControlName="anlagenbeschreibungSelected"
            [style]="{'width': '100%', 'height': '36px'}" [inputStyle]="{'width': '100%', 'height': '36px'}"
            ></crud-basic-autocomplete>
            <label>{{'Anlagenart'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.anlagenbeschreibungSelected}}
          </div>
        </div>-->

        <!-- 
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm" [style.display]="leistung != null && leistung.anstrich != true ? 'unset' : 'none'">
          <span class="p-float-label">
            <crud-basic-input formControlName="behaelterdruck" [ngStyle]="{'width':'100%'}" [type]="'numeric'"
            [decimals]="1" [quantityUnit]="'bar'" [required]="false"></crud-basic-input>
            <label>{{'Behälterdruck'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub" [style.display]="leistung != null && leistung.anstrich != true ? 'unset' : 'none'">
          <div class="errorMessage">
            {{displayMessage.behaelterdruck}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm" [style.display]="leistung != null && leistung.anstrich != true ? 'unset' : 'none'">
          <span class="p-float-label">
            <crud-basic-input formControlName="behaelterfuellgrad" [ngStyle]="{'width':'100%'}" [type]="'numeric'"
            [decimals]="0" [quantityUnit]="'%'" [required]="false"></crud-basic-input>
            <label>{{'Behälterfüllgrad'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub" [style.display]="leistung != null && leistung.anstrich != true ? 'unset' : 'none'">
          <div class="errorMessage">
            {{displayMessage.behaelterfuellgrad}}
          </div>
        </div>
      -->

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm" [style.display]="leistung != null && leistung.pumpentyp == true ? 'unset' : 'none'">
          <span class="p-float-label">
            <crud-basic-autocomplete [noTextInput]="app.inAppMode" [options]="pumpentypOptions" formControlName="pumpentypSelected"
            [style]="{'width': '100%', 'height': '36px'}" [inputStyle]="{'width': '100%', 'height': '36px'}"
            ></crud-basic-autocomplete>
            <label>{{'Pumpentyp'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub"  [style.display]="leistung != null && leistung.pumpentyp == true ? 'unset' : 'none'">
          <div class="errorMessage">
            {{displayMessage.pumpentypSelected}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm" [style.display]="leistung != null && leistung.anstrich == true ? 'unset' : 'none'">
          <span class="p-float-label">
            <crud-basic-autocomplete [noTextInput]="app.inAppMode" [type]="'anstrich'" formControlName="anstrichSelected"
            [style]="{'width': '100%', 'height': '36px'}" [inputStyle]="{'width': '100%', 'height': '36px'}"
            ></crud-basic-autocomplete>
            <label>{{'Neuer Anstrich in Farbe'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub" [style.display]="leistung != null && leistung.anstrich == true ? 'unset' : 'none'">
          <div class="errorMessage">
            {{displayMessage.anstrichSelected}}
          </div>
        </div>

        <br>

      </div>
      <br><br><!--ohne das kann man ggf. nicht weit genug bis zum letzten Eingabefeld runterscrollen-->
    </div>

    </p-tabPanel>
    <p-tabPanel header="Positionen" *ngIf="CRUDItem != null && CRUDItem.positionsdaten != null">
      <div id="posTab" [style]="{'overflow-y': 'auto'}">
        <div style="display: inline-block; width: 0.865em;"><!--checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen -->
        </div>
        <strong>Position {{posSelected+1}}/{{CRUDItem.positionsdaten.length}}</strong><!-- Reihenfolge: ({{CRUDItem.positionsdaten[posSelected].reihenfolge}})-->
        &nbsp; 
        <i *ngIf="(CRUDItem.positionsdaten[posSelected]._touched != true && (CRUDItem.positionsdaten[posSelected].id == null || CRUDItem.positionsdaten[posSelected].id == 0)) || CRUDItem.positionsdaten[posSelected]._valid != true" class="fa fa-circle-o" style="color:red"></i>
        <i *ngIf="(CRUDItem.positionsdaten[posSelected]._touched == true || (CRUDItem.positionsdaten[posSelected].id != null && CRUDItem.positionsdaten[posSelected].id != 0)) && CRUDItem.positionsdaten[posSelected]._valid == true" class="fa fa-circle-check" style="color:green"></i>
        {{(CRUDItem.positionsdaten[posSelected]._touched == true || (CRUDItem.positionsdaten[posSelected].id != null && CRUDItem.positionsdaten[posSelected].id != 0)) && CRUDItem.positionsdaten[posSelected]._valid == true ? 'abgeschlossen' : 'offen'}}
        &nbsp;
        <i *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.hinweistext != null && CRUDItem.positionsdaten[posSelected].checklistePosition.hinweistext.length > 0" class="fa fa-circle-info" style="font-size: 2em; float:right; color: {{app.colorButtons}};" [tabindex]="app.inAppMode ? '0' : null" [tooltipEvent]="app.inAppMode ? 'focus' : 'hover'" pTooltip="{{CRUDItem.positionsdaten[posSelected].checklistePosition.hinweistext}}"></i>
        <br>
        <br>

        <div [ngClass]="{'detail-with-middle-grid': !app.inAppMode, 'detail-with-middle-grid-inAppMode': app.inAppMode}" style="margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->

          <div class="detail-with-middle-grid-sub inputTextForm">
            <div class="checkbox-fix-grid"><!--checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen -->
              <div><!--style="display: inline-block; width: 0.550em;"-->
              </div>
              <div>
                {{CRUDItem.positionsdaten[posSelected].checklistePosition.bezeichnung}} <i *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.mussfeld == true" class="{{_app.mussFeldMarkerIconClass}}"></i>
              </div>
            </div>
            <br>
            <div [ngClass]="{'positionen-grid-container-io-align-left': !app.inAppMode, 'positionen-grid-container-io-align-left-inAppMode': app.inAppMode}" *ngIf="posSelected != null && CRUDItem.positionsdaten[posSelected].checklistePosition != null"
            style="margin-top:0.5em;">
              <div [style.visibility]="CRUDItem.positionsdaten[this.posSelected].checklistePosition.bewertung == true ? 'unset' : 'hidden'">
                <!--<p-checkbox *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.bewertung == true || true" label="i.O." pTooltip="in Ordnung" [(ngModel)]="CRUDItem.positionsdaten[posSelected].bewertungIO" (ngModelChange)="posMarkAsTouchedAndReCalc()" [binary]="true"></p-checkbox>-->
                <p-toggleButton *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.bewertung == true" [onLabel]="'in Ordnung'" [offLabel]="'in Ordnung'" styleClass="{{(CRUDItem.positionsdaten[posSelected].bewertungIO == true ? 'BewertungIOTrue' : 'BewertungIOFalse')+(app.inAppMode ? '-inAppMode' : '')}}" iconPos="right" [(ngModel)]="CRUDItem.positionsdaten[posSelected].bewertungIO" (ngModelChange)="posCheckBoxIOClicked($event)"
                style="margin-left: 0.75em;"></p-toggleButton><!--onIcon="pi pi-check"--><!--checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen -->
              </div>
              <div [style.visibility]="CRUDItem.positionsdaten[this.posSelected].checklistePosition.bewertung == true ? 'unset' : 'hidden'">
                <!--<p-checkbox *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.bewertung == true || true" label="n.i.O." pTooltip="nicht in Ordnung" [(ngModel)]="CRUDItem.positionsdaten[posSelected].bewertungNiO" (ngModelChange)="posCheckBoxNiOClicked()" [binary]="true" [style]="{'margin-left': '0.76em'}"></p-checkbox>-->
                <p-toggleButton *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.bewertung == true" [onLabel]="'n.i.O.'" [offLabel]="'n.i.O.'" styleClass="{{(CRUDItem.positionsdaten[posSelected].bewertungNiO == true ? 'BewertungNIOTrue' : 'BewertungNIOFalse')+(app.inAppMode ? '-inAppMode' : '')}}" iconPos="right" [(ngModel)]="CRUDItem.positionsdaten[posSelected].bewertungNiO" (ngModelChange)="posCheckBoxNiOClicked()"></p-toggleButton>
              </div>
              <div [style.visibility]="CRUDItem.positionsdaten[this.posSelected].checklistePosition.bewertung == true ? 'unset' : 'hidden'">
                <!--<p-checkbox *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.bewertung == true || true" label="behoben" pTooltip="behoben" [(ngModel)]="CRUDItem.positionsdaten[posSelected].bewertungBehoben" (ngModelChange)="posMarkAsTouchedAndReCalc()" [binary]="true" [style]="{'margin-left': '0.76em'}"
                [disabled]="CRUDItem.positionsdaten[posSelected].bewertungNiO != true"></p-checkbox>-->
                <p-toggleButton *ngIf="CRUDItem.positionsdaten[posSelected].checklistePosition.bewertung == true" [onLabel]="'Behoben'" [offLabel]="'Behoben'" styleClass="{{(CRUDItem.positionsdaten[posSelected].bewertungBehoben == true ? 'BewertungBehobenTrue' : 'BewertungBehobenFalse')+(app.inAppMode ? '-inAppMode' : '')}}" iconPos="right" [(ngModel)]="CRUDItem.positionsdaten[posSelected].bewertungBehoben" (ngModelChange)="posCheckBoxBehobenClicked()"
                [disabled]="CRUDItem.positionsdaten[posSelected].bewertungNiO != true"></p-toggleButton>
              </div>
              <!--<p-selectButton [options]="posIO_NIO_BehobenOptions" formControlName="posBewertungValue" optionLabel="label" optionValue="value"></p-selectButton>-->
              <div></div>
            </div>
          </div>
          <div class="detail-with-middle-grid-sub">
            <!---->
          </div>
          <div class="detail-with-middle-grid-sub">
            <br>
            <ng-container *ngFor="let error of posErrors">
              <div class="errorMessage" *ngIf="error.checklistePositionenFeldId == null"><!-- null = io/nio/behoben -->
                {{error.message}}
              </div>
            </ng-container>
          </div>

        </div>

        <br>
        <!--<div *ngFor="let feld of CRUDItem.positionsdaten[posSelected].checklistePosition.checklistePositionenFelder">-->
        <div [ngClass]="{'detail-with-middle-grid': !app.inAppMode, 'detail-with-middle-grid-inAppMode': app.inAppMode}" style="margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->
          <ng-container *ngFor="let feld of CRUDItem.positionsdaten[posSelected].positionsdatenFelder">

            <div class="detail-with-middle-grid-sub inputTextForm checkboxes">
              <span class="p-float-label">
                <div *ngIf="feld.typ == 'bool'" ><!--background-color: #f5f5f5; -->
                  <div style="float: left; margin-top: 1em; margin-left: 0.75em; color: #555">
                    {{feld.bezeichnung}} <i *ngIf="feld.checklistePositionenFeld != null && feld.checklistePositionenFeld.mussfeld == true" class="{{_app.mussFeldMarkerIconClass}}"></i>
                  </div>
                  <div style="float: right;">
                    <p-selectButton #feldSelectButton [options]="posJaNeinOptions" optionLabel="label" optionValue="value" pTooltip="" [(ngModel)]="feld.feldwert" (ngModelChange)="posFeldSelectButtonClicked(feld, feldSelectButton)"></p-selectButton>
                  </div>
                </div>

                <div *ngIf="feld.typ == 'checkbox'" ><!--background-color: #f5f5f5; -->
                  <p-checkbox #feldCheckBox class="aufgaben-checkbox" 
                  pTooltip="" [ngModel]="feld.feldwert != null && feld.feldwert == 'T'" (ngModelChange)="posFeldCheckBoxClicked(feld, feldCheckBox)" [binary]="true"
                  style="margin-left: 0.75em;"><!--checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen -->
                  <!--label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{feld.bezeichnung}} {{feld.checklistePositionenFeld != null && feld.checklistePositionenFeld.mussfeld == true ? '*' : ''}}" -->
                  </p-checkbox>
                  &nbsp;<label class="aufgaben-checkbox-label">{{feld.bezeichnung}} <i *ngIf="feld.checklistePositionenFeld != null && feld.checklistePositionenFeld.mussfeld == true" class="{{_app.mussFeldMarkerIconClass}}"></i></label>
                </div>
  
                <input *ngIf="feld.typ == 'string' || feld.typ == ''" style="width:100%;" type="text" pInputText [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()">

                <textarea *ngIf="feld.typ == 'text'" style="width: 100%; max-width: 100%;" [rows]="4" pInputTextarea [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()"></textarea>

                <crud-basic-input *ngIf="feld.typ == 'int'" [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()" [ngStyle]="{'width':'100%'}" [type]="'numeric'"
                [decimals]="0" [quantityUnit]="''" [required]="false"></crud-basic-input>
  
                <crud-basic-input *ngIf="feld.typ == 'decimal'" [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()" [ngStyle]="{'width':'100%'}" [type]="'numeric'"
                [decimals]="2" [quantityUnit]="''" [required]="false"></crud-basic-input>
  
                <!-- typ date ohne felddefinition (&& feld.checklistePositionenFeld.felddefinition == null) = volles Datum -->
                <crud-basic-input-calendar *ngIf="feld.typ == 'date'" [noTextInput]="app.inAppMode" dateFormat="dd.mm.yy" [showTime]="false" [stepMinute]="01" [showSeconds]="true" 
                [defaultDate]="feld.feldwert" [utc]="true" [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()" [style]="{'width' : '100%'}"></crud-basic-input-calendar>
                <!-- typ date mit MM/YYYY (&& feld.checklistePositionenFeld.felddefinition == 'MM/YYYY') -->
                <!-- "readonlyInput" heisst: schon änderbar! aber eben nicht per text-input! -->
                <p-calendar *ngIf="hideDuringSave != true && feld.typ == 'dateMY'" [readonlyInput]="app.inAppMode" dateFormat="mm/yy" view="month" [locale]="DE" appendTo="body"
                [defaultDate]="feld.feldwert" [utc]="true" [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()" [style]="{'width' : '100%'}"></p-calendar>
                <!-- typ date mit YYYY (&& feld.checklistePositionenFeld.felddefinition == 'YYYY') -->
                <!-- "readonlyInput" heisst: schon änderbar! aber eben nicht per text-input! -->
                <p-calendar *ngIf="hideDuringSave != true && feld.typ == 'dateY'" [readonlyInput]="app.inAppMode" dateFormat="yy" view="year" [locale]="DE" appendTo="body"
                [defaultDate]="feld.feldwert" [utc]="true" [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()" (onBlur)="yearpickerBlur($event, feld)" [style]="{'width' : '100%'}"></p-calendar>
  
                <!-- "readonlyInput" heisst: schon änderbar! aber eben nicht per text-input! -->
                <p-calendar *ngIf="hideDuringSave != true && feld.typ == 'time'" [readonlyInput]="app.inAppMode" appendTo="body" skeepInvalid="true" [style]="{'width' : '100%'}" [locale]="DE" dateFormat="dd.mm.yy" [timeOnly]="true" [showSeconds]="false" [stepMinute]="1" [showTime]="true" [defaultDate]="feld.feldwert" [utc]="true" (onFocus)="posOnFocusTime(feld, $event)" (onSelect)="posOnSelectTime(feld)" (onBlur)="posOnBlurTime(feld)" [(ngModel)]="feld.feldwert" (ngModelChange)="posMarkAsTouchedAndReCalc()"></p-calendar>
  
                <!-- 1. Idee: für autocomplete "simulieren" wir _CHOICELIST, weil wir im dropdown/autocomplete nicht das Objekt der Auswhal speichern wollen, sondern nur den alpha-value, eben wie bei _CHOICELIST, die sonst von der API kommt
                     funktioniert leider nicht, führt beim 2. Aufruf (vor und zurückblättern) zu ExpressionChangedAfterItHasBeenCheckedError
                  <crud-basic-autocomplete *ngIf="feld.typ == 'dropdown'" [(ngModel)]="feld.feldwert"
                  (ngModelChange)="posMarkAsTouchedAndReCalc()" [type]="'_CHOICELIST'"
                  [choiceListServiceInstance]="this"
                  [choiceListMethod]="'getValidChoiceList_PosDropdown'"
                  [placeholder]="" [required]="false"
                  (change)="posMarkAsTouchedAndReCalc()">
                </crud-basic-autocomplete>-->
                <p-dropdown *ngIf="feld.typ == 'dropdown'" [options]="feld.checklistePositionenFeld.checklistePositionenFeldwerte" [style]="{'width' : '100%'}" [styleClass]="'aufgaben-dropdown-trigger'" [(ngModel)]="feld.feldwert" optionLabel="bezeichnung" optionValue="feldwert" [autoDisplayFirst]="false" dropdownIcon="pi pi-caret-down" [showClear]="true" appendTo="body" (ngModelChange)="posMarkAsTouchedAndReCalc()"></p-dropdown>

                <!-- ___checklistePositionenFeldwerte___filtered enthält die checklistePositionenFeldwerte aufbereitet (nur als String!, kein Obj!) - ausserdem wird das in der "completeMethod" gefiltert -->
                <!-- "readonly" heisst: schon änderbar! aber eben nicht per text-input! -->
                <p-autoComplete *ngIf="feld.typ == 'combobox'" [readonly]="app.inAppMode" [(ngModel)]="feld.feldwert" [suggestions]="feld.checklistePositionenFeld.___checklistePositionenFeldwerte___filtered" appendTo="body" [dropdown]="true"
                (ngModelChange)="posMarkAsTouchedAndReCalc()"
                (completeMethod)="posAutoCompleteSearch(feld, $event)"
                dropdownIcon="pi pi-caret-down">
                </p-autoComplete>

                <label *ngIf="feld.typ != 'bool' && feld.typ != 'checkbox'">{{feld.bezeichnung}} <i *ngIf="feld.checklistePositionenFeld != null && feld.checklistePositionenFeld.mussfeld == true" class="{{_app.mussFeldMarkerIconClass}}"></i></label>
              </span>
              <font *ngIf="debugMode==true" style="font-size: 7px; color: grey">
                feld.feldwert: {{feld.feldwert}}
                <ng-container *ngFor="let feldwert of feld.checklistePositionenFeld.checklistePositionenFeldwerte">
                  <br>checklistePositionenFeldwerte.reichenfolge: {{feldwert.reihenfolge}}   checklistePositionenFeldwerte.bezeichnung: {{feldwert.bezeichnung}}   checklistePositionenFeldwerte.feldwert: {{feldwert.feldwert}}
                </ng-container>
              </font>
            </div>
            <div class="detail-with-middle-grid-sub" [tabindex]="app.inAppMode ? '0' : null" [tooltipEvent]="app.inAppMode ? 'focus' : 'hover'" pTooltip="{{feld.checklistePositionenFeld.info}}">
              <i *ngIf="feld.checklistePositionenFeld != null && feld.checklistePositionenFeld.info != null && feld.checklistePositionenFeld.info.length > 0" class="fa fa-circle-info" style="font-size: 1.25em; color: {{app.colorButtons}};"
                [style.left]="feld.typ == 'checkbox' && feldTypCheckBoxMaxWidthInPx > 0? feldTypCheckBoxMaxWidthInPx+feldTypCheckBoxAdditionalSpace+'px' : 'unset'"
                [style.position]="feld.typ == 'checkbox' && feldTypCheckBoxMaxWidthInPx > 0 ? 'absolute' : 'unset'"
                ></i>
            </div>
            <div class="detail-with-middle-grid-sub">
              <ng-container *ngFor="let error of posErrors">
                <div class="errorMessage" *ngIf="error.checklistePositionenFeldId == feld.checklistePositionenFeldId">
                  <i class="pi pi-exclamation-circle" id="infoBtn"
                  style="font-size: 16px; color: #ffffff;"></i>
                  {{error.message}}
                </div>
              </ng-container>
            </div>

          </ng-container>
        </div>

        <div *ngIf="tabSelected == this.tabNRPos && CRUDItem.positionsdaten[posSelected].bewertungNiO == true"><!--wenn NiO-Accordion sichtbar, noch ein paar Zeilen extra, sonst verschwindet der untere Teil hinter dem Footer-->
          <br>
        </div>
        <p-accordion [multiple]="true" [iconPos]="'start'" [style.display]="tabSelected == this.tabNRPos && CRUDItem.positionsdaten[posSelected].bewertungNiO == true ? 'unset' : 'none'">
          <p-accordionTab header="Mangel" [(selected)]="positionsdatenFotoAccordionSelected_mangel" 
          styleClass="auftrags-checkliste-bilder-accordion"><!--styleClass: weil "checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen": dazu auch das accordion ausrichten -->
            <ng-template pTemplate="header">

              <div style="position: absolute; right: 0em; min-width: calc(50% - 0.5em);"><!--Breite der anderen Fehlermeldungen (im Grid!) nachbilden-->

                <div class="errorMessage" *ngIf="positionsdatenFotoAccordionSelected_mangel==false && posErrorsHaveMangelErrors(posErrors)==true">
                  <i class="pi pi-exclamation-circle" id="infoBtn"
                  style="font-size: 16px; color: #ffffff;"></i>
                  Bitte Daten des Mangels korrigieren
                </div>
              </div>

            </ng-template>            
          <!--<p-fieldset legend="Mangel" [toggleable]="true" [collapsed]="positionsdatenFotoAccordionSelected_mangel">-->

            <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" style="margin-top: 1em; margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->
              <div class="detail-grid-sub">
                <span class="p-float-label">
                  <textarea #bemerkungPos style="width:100%; padding-top: 0.5em; padding-bottom: 0.5em;" [rows]="4" pInputTextarea 
                  [(ngModel)]="CRUDItem.positionsdaten[posSelected].bemerkung" (ngModelChange)="posMarkAsTouchedAndReCalc()"
                  ></textarea><!--kein autoResize="autoResize" sonst optisches Problem beim wieder-reinkommen-->
                  <label>Beschreibung des Mangels <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
                </span>
              </div>
              <div class="detail-grid-sub">
                <ng-container *ngFor="let error of posErrors">
                  <div class="errorMessage" *ngIf="error.checklistePositionenFeldId == posErrorChecklistePositionenFeldId_BemerkungDesMangels"><!-- -1 = bemerkung -->
                    <i class="pi pi-exclamation-circle" id="infoBtn"
                    style="font-size: 16px; color: #ffffff;"></i>
                    {{error.message}}
                  </div>
                </ng-container>
              </div>
            </div>
            <div style="margin-bottom: 2em;"></div>
    
            <div>
              <div style="display: inline-block; width: 0.75em;"><!--checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen -->
              </div>
              <strong>Bilder des Mangels</strong> <i class="{{_app.mussFeldMarkerIconClass}}"></i>
              <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" style="margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->
                <!--TODO: camera capture on MOBILE: http://anssiko.github.io/html-media-capture/ -> Example 4 works! - aber wie macht man das mit primeng -->
                <div #fileuploadDiv id="fileuploadDiv"><!--die max-height wird noch überschrieben-->
                  <!--{{resizeFileUpload() ? null : null}}-->
                  <p-fileUpload #positionsdatenFotosFileUpload_mangel [files]="positionsdatenFotosFileUpload_files_mangel" id="dateiHochladen" chooseLabel="Datei auswählen"
                  maxFileSize="999999999" [showUploadButton]="false" [showCancelButton]="false"
                  (onSelect)="onNGFileInputSelect($event, 'M')" (onRemove)="onNGFileInputRemove($event)"
                  multiple="multiple" accept="image/*" maxFileSize="99999999">
                  <ng-template pTemplate="toolbar">
                    <div style="float:right">
                      <button pButton type="button" label="Kamera" icon="fa-solid fa-camera" iconPos="left" style="margin-right: -0.25em;" (click)="uploadFromCamera(positionsdatenFotosFileUpload_mangel)"></button><!--(click)=""-->
                    </div>
                  </ng-template>
                  <ng-template let-file pTemplate="file">
                    <div class="ui-fileupload-row ng-star-inserted bilder-grid-container">
                        <div>
                          <img [src]="file._objectURL != undefined ? file._objectURL : file.objectURL" width="75" class="ng-star-inserted">
                        </div>
                        <div>
                          <br><br>
                          {{file._positionsdatenFoto != undefined ? file._positionsdatenFoto.bemerkung : file.name}}
                        </div>
                        <div [style.visibility]="file._percentUploaded == 100 ? 'hidden' : 'unset'">
                          <br><br>
                          {{file._percentUploaded}}%
                        </div>
                        <div>
                            <br>
                            <button pButton *ngIf="file._percentUploaded == 100" type="button" label="{{app.inAppMode ? '' : 'Löschen'}}" icon="fa-solid fa-trash" iconPos="left" style="float:right; margin-right: 0.25em;" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" (click)="positionenBilderDeleteFile(file,'M')"></button>
                            <!--<button pButton type="button" label="Debug" icon="fa-solid fa-debug" iconPos="left" style="float:right; margin-right: 0.25em;" (click)="positionenBilderDebug(file)"></button>-->
                        </div>
                    </div>
                    <span>
                        <!--<p-progressBar *ngIf="true || file._alreadyUploaded != null && file._alreadyUploaded == false && file._percentUploaded>0" [value]="file._percentUploaded"></p-progressBar>-->
                    </span>
                  </ng-template>
                  </p-fileUpload>
                </div>  
    
                <div class="detail-grid-sub">
                  <ng-container *ngFor="let error of posErrors">
                    <div class="errorMessage" *ngIf="error.checklistePositionenFeldId == posErrorChecklistePositionenFeldId_FotoDesMangels"><!-- -2 = bilder -->
                      <i class="pi pi-exclamation-circle" id="infoBtn"
                      style="font-size: 16px; color: #ffffff;"></i>
                      {{error.message}}
                    </div>
                  </ng-container>
                </div>
      
              </div><!--class="detail-grid"-->
            </div><!--div für die Bilder-->
    
          </p-accordionTab>
          <p-accordionTab header="Behebung" [(selected)]="positionsdatenFotoAccordionSelected_behoben" [style.display]="tabSelected == this.tabNRPos && CRUDItem.positionsdaten[posSelected].bewertungBehoben == true ? 'unset' : 'none'"
          styleClass="auftrags-checkliste-bilder-accordion"><!--styleClass: weil "checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen": dazu auch das accordion ausrichten -->
            <ng-template pTemplate="header">

              <div style="position: absolute; right: 0em; min-width: calc(50% - 0.5em);"><!--Breite der anderen Fehlermeldungen (im Grid!) nachbilden-->
                <div class="errorMessage" *ngIf="positionsdatenFotoAccordionSelected_behoben==false && posErrorsHaveBehebungErrors(posErrors)==true">
                  <i class="pi pi-exclamation-circle" id="infoBtn"
                  style="font-size: 16px; color: #ffffff;"></i>
                  Bitte Daten der Behebung korrigieren
                </div>
              </div>

            </ng-template>            

          <!--</p-fieldset>
          <p-fieldset legend="Behebung" [toggleable]="true" [collapsed]="positionsdatenFotoAccordionSelected_behoben" [style.display]="tabSelected == this.tabNRPos && CRUDItem.positionsdaten[posSelected].bewertungBehoben == true ? 'unset' : 'none'">-->

            <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" style="margin-top: 1em; margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->
              <div class="detail-grid-sub">
                <span class="p-float-label">
                  <textarea #bemerkungPos style="width:100%; padding-top: 0.5em; padding-bottom: 0.5em;" [rows]="4" pInputTextarea 
                  [(ngModel)]="CRUDItem.positionsdaten[posSelected].bemerkungBehoben" (ngModelChange)="posMarkAsTouchedAndReCalc()"
                  ></textarea><!--kein autoResize="autoResize" sonst optisches Problem beim wieder-reinkommen-->
                  <label>Beschreibung der Behebung <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
                </span>
              </div>
              <div class="detail-grid-sub">
                <ng-container *ngFor="let error of posErrors">
                  <div class="errorMessage" *ngIf="error.checklistePositionenFeldId == posErrorChecklistePositionenFeldId_BemerkungDerBehebung"><!-- -3 -->
                    <i class="pi pi-exclamation-circle" id="infoBtn"
                    style="font-size: 16px; color: #ffffff;"></i>
                    {{error.message}}
                  </div>
                </ng-container>
              </div>
            </div>
            <div style="margin-bottom: 2em;"></div>
    
            <div>
              <div style="display: inline-block; width: 0.75em;"><!--checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen -->
              </div>
              <strong>Bilder der Behebung</strong> <i class="{{_app.mussFeldMarkerIconClass}}"></i>
              <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" style="margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->
                <!--TODO: camera capture on MOBILE: http://anssiko.github.io/html-media-capture/ -> Example 4 works! - aber wie macht man das mit primeng -->
                <div #fileuploadDiv id="fileuploadDiv"><!--die max-height wird noch überschrieben-->
                  <!--{{resizeFileUpload() ? null : null}}-->
                  <p-fileUpload #positionsdatenFotosFileUpload_behoben [files]="positionsdatenFotosFileUpload_files_behoben" id="dateiHochladen" chooseLabel="Datei auswählen"
                  maxFileSize="999999999" [showUploadButton]="false" [showCancelButton]="false"
                  (onSelect)="onNGFileInputSelect($event, 'B')" (onRemove)="onNGFileInputRemove($event)"
                  multiple="multiple" accept="image/*" maxFileSize="99999999">
                  <ng-template pTemplate="toolbar">
                    <div style="float:right">
                      <button pButton type="button" label="Kamera" icon="fa-solid fa-camera" iconPos="left" style="margin-right: -0.25em;" (click)="uploadFromCamera(positionsdatenFotosFileUpload_behoben)"></button><!--(click)=""-->
                    </div>
                  </ng-template>
                  <ng-template let-file pTemplate="file">
                    <div class="ui-fileupload-row ng-star-inserted bilder-grid-container">
                        <div>
                          <img [src]="file._objectURL != undefined ? file._objectURL : file.objectURL" width="75" class="ng-star-inserted">
                        </div>
                        <div>
                          <br><br>
                          {{file._positionsdatenFoto != undefined ? file._positionsdatenFoto.bemerkung : file.name}}
                        </div>
                        <div [style.visibility]="file._percentUploaded == 100 ? 'hidden' : 'unset'">
                          <br><br>
                          {{file._percentUploaded}}%
                        </div>
                        <div>
                            <br>
                            <button pButton *ngIf="file._percentUploaded == 100" type="button" label="{{app.inAppMode ? '' : 'Löschen'}}" icon="fa-solid fa-trash" iconPos="left" style="float:right; margin-right: 0.25em;" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" (click)="positionenBilderDeleteFile(file,'B')"></button>
                            <!--<button pButton type="button" label="Debug" icon="fa-solid fa-debug" iconPos="left" style="float:right; margin-right: 0.25em;" (click)="positionenBilderDebug(file)"></button>-->
                        </div>
                    </div>
                    <span>
                        <!--<p-progressBar *ngIf="true || file._alreadyUploaded != null && file._alreadyUploaded == false && file._percentUploaded>0" [value]="file._percentUploaded"></p-progressBar>-->
                    </span>
                  </ng-template>
                  </p-fileUpload>
                </div>  
    
                <div class="detail-grid-sub">
                  <ng-container *ngFor="let error of posErrors">
                    <div class="errorMessage" *ngIf="error.checklistePositionenFeldId == posErrorChecklistePositionenFeldId_FotoDerBehebung"><!-- -4 -->
                      <i class="pi pi-exclamation-circle" id="infoBtn"
                      style="font-size: 16px; color: #ffffff;"></i>
                      {{error.message}}
                    </div>
                  </ng-container>
                </div>
      
              </div><!--class="detail-grid"-->
            </div><!--div für die Bilder-->

          <!--</p-fieldset>-->
          </p-accordionTab>
        </p-accordion>

        <!-- Allgemeine Bilder (Wenn in der Pos aktiviert) START -->
        <!--[style.visibility]="CRUDItem.positionsdaten[this.posSelected].checklistePosition.fotos == true ? 'unset' : 'hidden'"-->
        <div [style.display]="CRUDItem.positionsdaten[this.posSelected].checklistePosition.fotos == true ? 'initial' : 'none'">
        <div style="display: inline-block; width: 0.75em;"><!--checkbox und IO/NIO/Behoben und Text weiter nach Rechts, wo auch die Inputs anfangen -->
        </div>
        <strong>Bilder</strong>
        <div [ngClass]="{'detail-with-middle-grid': !app.inAppMode, 'detail-with-middle-grid-inAppMode': app.inAppMode}" style="margin-right: 1em;"><!--ohne margin-right: 1em; kommt es manchmal zum horiz. scrollbar-->
          <!--TODO: camera capture on MOBILE: http://anssiko.github.io/html-media-capture/ -> Example 4 works! - aber wie macht man das mit primeng -->
            <div #fileuploadDiv id="fileuploadDiv">
                <!--die max-height wird noch überschrieben-->
                <!--{{resizeFileUpload() ? null : null}}-->
                <!--<input id="manuell_programmierte_dateiauswahl" type="file">--><!--US 33968: Test Android Kamera -->
                <p-fileUpload #positionsdatenFotosFileUpload_allgemein [files]="positionsdatenFotosFileUpload_files_allgemein" id="dateiHochladen" chooseLabel="Datei auswählen"
                              maxFileSize="999999999" [showUploadButton]="false" [showCancelButton]="false"
                              (onSelect)="onNGFileInputSelect($event, 'A')" (onRemove)="onNGFileInputRemove($event)"
                              multiple="multiple" accept="image/*,application/pdf" maxFileSize="99999999">
                    <ng-template pTemplate="toolbar">
                        <div style="float:right">
                            <!--<button pButton type="button" label="Sim" icon="fa-solid fa-camera" iconPos="left" style="margin-right: -0.25em; background-color: red;" (click)="debug_simulateNativeUploadFromCamera('A')"></button>-->
                            <button pButton type="button" label="Kamera" icon="fa-solid fa-camera" iconPos="left" style="margin-right: -0.25em;" (click)="uploadFromCamera(positionsdatenFotosFileUpload_allgemein)"></button><!--(click)=""-->
                        </div>
                    </ng-template>
                    <ng-template let-file pTemplate="file">
                        <div class="ui-fileupload-row ng-star-inserted bilder-grid-container">
                            <div>
                                <img [src]="file.name.toLowerCase().endsWith('pdf') ? 'assets/DLP/dateitypen/pdf.png' : (file._objectURL != undefined ? file._objectURL : file.objectURL)" width="75" class="ng-star-inserted">
                            </div>
                            <div>
                                <br><br>
                                {{file._positionsdatenFoto != undefined ? file._positionsdatenFoto.bemerkung : file.name}}
                            </div>
                            <div [style.visibility]="file._percentUploaded == 100 ? 'hidden' : 'unset'">
                                <br><br>
                                {{file._percentUploaded}}%
                            </div>
                            <div>
                                <br>
                                <button pButton *ngIf="file._percentUploaded == 100" type="button" label="{{app.inAppMode ? '' : 'Löschen'}}" icon="fa-solid fa-trash" iconPos="left" style="float:right; margin-right: 0.25em;" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" (click)="positionenBilderDeleteFile(file,'A')"></button>
                                <!--<button pButton type="button" label="Debug" icon="fa-solid fa-debug" iconPos="left" style="float:right; margin-right: 0.25em;" (click)="positionenBilderDebug(file)"></button>-->
                            </div>
                        </div>
                        <span>
                            <!--<p-progressBar *ngIf="true || file._alreadyUploaded != null && file._alreadyUploaded == false && file._percentUploaded>0" [value]="file._percentUploaded"></p-progressBar>-->
                        </span>
                    </ng-template>
                </p-fileUpload>
            </div>  

          <div class="detail-with-middle-grid-sub">
          </div>  

          <div class="detail-with-middle-grid-sub">
            <ng-container *ngFor="let error of posErrors">
              <div class="errorMessage" *ngIf="error.checklistePositionenFeldId == posErrorChecklistePositionenFeldId_FotoAllgemein"><!-- -5 -->
                <i class="pi pi-exclamation-circle" id="infoBtn"
                style="font-size: 16px; color: #ffffff;"></i>
                {{error.message}}
              </div>
            </ng-container>
          </div>

        </div><!--class="detail-grid"-->
        </div>
        <!-- Allgemeine Bilder ENDE -->

        <div *ngIf="posLetzteSichtbare != null && posSelected == posLetzteSichtbare" class="detail-grid-sub inputTextForm" style="margin-top: 1em; margin-bottom: 2em;">
          <div style="display: inline-block; width: 0.75em;"><!--... weiter nach Rechts, wo auch die Inputs anfangen -->
          </div>
          <!-- button mit stacked icons -->
          <button [ngClass]="{'p-button-success': offeneMaengelExist!=true, 'p-button-danger': offeneMaengelExist==true}" style="width: auto; padding: 0.25em; padding-right: 1em;" pButton type="button" (click)="offeneMaengelDialogShow=true" [label]="translate.instant('Offene Mängel',true)">
            <span class="fa-stack">
              <i class="fa-solid fa-gear fa-stack-1x"></i>
              <i class=" fa-solid fa-slash fa-stack-1x"></i>
            </span>
          </button>
        </div>
        <div class="detail-grid-sub">
        </div>

        <div style="background-color: #fdfdfd; height: 4em; width: 1px;">
          <!-- extra Abstand: vor allem mobile / besonders bei iOS kann man sonst nicht bis ganz runter scrollen 
          Wie hoch? Höhe des Footers wäre 6.5em; (89,5 px) - das ist aber gar nicht nötig, weil sowieso schon etwas unterer Rand ist.
          ca. 4em scheinen ausreichend zu sein!
          -->
        </div>

        <div *ngIf="tabSelected == this.tabNRPos && (CRUDItem.positionsdaten[posSelected].bewertungNiO == true || CRUDItem.positionsdaten[posSelected].checklistePosition.fotos == true)"><!--wenn NiO-Accordion sichtbar (oder allg. Fotos), noch ein paar Zeilen extra, sonst verschwindet der untere Teil hinter dem Footer-->
          <br>
          <br>
          <br>
        </div>
      </div>

    </p-tabPanel>

    <p-tabPanel header="Kopfdaten">
      <strong>Bemerkungen:</strong>
      <br><br>
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}">
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <!--<input style="width:100%" type="text" pInputText formControlName="bemerkung">-->
            <textarea #bemerkung style="width: 100%" [rows]="4" pInputTextarea
            formControlName="bemerkung"></textarea><!--kein autoResize="autoResize" sonst optisches Problem beim wieder-reinkommen-->
            <label>{{'Bemerkung'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.bemerkung}}
          </div>
        </div>
  
        <!--<div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <input style="width:100%" type="text" pInputText formControlName="betriebskennzeichen">
            <label>{{'Betriebskennzeichen'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.betriebskennzeichen}}
          </div>
        </div>
  
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <p-calendar [locale]="DE" dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="01" [showSeconds]="true" [defaultDate]="dateNow" [utc]="true" formControlName="datumNachpruefungBis" [style]="{'width' : '100%'}"></p-calendar>
            <label>{{'Datum Nachprüfung Bis'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.datumNachpruefungBis}}
          </div>
        </div>
      -->
      <!--  <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <p-calendar [locale]="DE" dateFormat="dd.mm.yy" [showTime]="false" [stepMinute]="01" [showSeconds]="true" [defaultDate]="dateNow" [utc]="true" formControlName="datumNaechsteAnlagenpruefung" [style]="{'width' : '100%'}"></p-calendar>
            <label>{{'Datum Nächste Anlagenprüfung'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.datumNaechsteAnlagenpruefung}}
          </div>
        </div>-->
  <!--
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <p-calendar [locale]="DE" dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="01" [showSeconds]="true" [defaultDate]="dateNow" [utc]="true" formControlName="datumNaechsteFeststellungspruefung" [style]="{'width' : '100%'}"></p-calendar>
            <label>{{'Datum Nächste Feststellungsprüfung'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.datumNaechsteFeststellungspruefung}}
          </div>
        </div>
  
        <!--<div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <input style="width:100%" type="text" pInputText formControlName="eMailversandProtokoll">
            <label>{{'E Mailversand Protokoll'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.eMailversandProtokoll}}
          </div>
        </div>
  
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <input style="width:100%" type="text" pInputText formControlName="nachpruefungErforderlich">
            <label>{{'Nachprüfung Erforderlich'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.nachpruefungErforderlich}}
          </div>
        </div>-->
  
        <!--<div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <input style="width:100%" type="text" pInputText formControlName="pruefer">
            <label>{{'Prüfer'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.pruefer}}
          </div>
        </div>
  
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <p-calendar [locale]="DE" dateFormat="dd.mm.yy" [showTime]="true" [stepMinute]="01" [showSeconds]="true" [defaultDate]="dateNow" [utc]="true" formControlName="rueckmeldedatum" [style]="{'width' : '100%'}"></p-calendar>
            <label>{{'Rückmeldedatum'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.rueckmeldedatum}}
          </div>
        </div>

      <div [formGroup]="CRUDForm" class="detail-grid-sub checkboxes">
          <span class="p-float-label">
            <crud-basic-checkbox formControlName="eMailversand" [nullable]="true" [label]="translate.instant('E Mailversand',true)"></crud-basic-checkbox>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.eMailversand}}
          </div>
        </div>
  -->
      </div>
    </p-tabPanel>

<!--     <p-tabPanel header="Abschlussfragen" *ngIf="tabShowAbschluss == true" [disabled]="CRUDItem == null || CRUDItem.positionsdaten == null || posCountTouchedOrAlreadySavedAndValid < CRUDItem.positionsdaten.length && debugMode!=true">
      
      <strong>Abschlussfragen:</strong>
      <br><br>
      
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}">
        <div>
          Maßnahmen abgeschlossen <i class="{{_app.mussFeldMarkerIconClass}}"></i>
        </div>
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <!-.Nein &nbsp;<p-inputSwitch formControlName="massnahmen_abgeschlossen"></p-inputSwitch>&nbsp;Ja.->
          <p-selectButton [options]="jaNeinOptions" formControlName="massnahmenAbgeschlossen" optionLabel="label" optionValue="value"></p-selectButton>
        </div>
      </div>
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}">
        <div>
          <!-. linke Hälfte leer.->
        </div>
        <div class="errorMessage">
          {{displayMessage.massnahmenAbgeschlossen}}
        </div>
      </div>

      <br>
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" *ngIf="leistung != null && leistung.pruefergebnis != true"><!-. wenn leistung.pruefergebnis nicht gesetzt: nur Ja/Nein Frage: .->
        <div>
          Gegen den Weiterbetrieb der Anlage gibt es keine Bedenken <i class="{{_app.mussFeldMarkerIconClass}}"></i>
        </div>
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <p-selectButton [options]="jaNeinOptions" formControlName="weiterbetrieb" optionLabel="label" optionValue="value"></p-selectButton>
        </div>
      </div>
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" *ngIf="leistung != null && leistung.pruefergebnis != true"><!-. wenn leistung.pruefergebnis nicht gesetzt: nur Ja/Nein Frage: .->
        <div>
          <!-. linke Hälfte leer.->
        </div>
        <div class="errorMessage">
          {{displayMessage.weiterbetrieb}}
        </div>
      </div>

      <!-. wenn leistung.pruefergebnis gesetzt: Auswahl! -> entfällt! (eigenes Feld) .->
      <!-.<div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" *ngIf="leistung != null && leistung.pruefergebnis == true">
        <div>
          {{'Prüfergebnis'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i>
        </div>
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label" [style]="{'margin-right': '1em'}">
            <crud-basic-autocomplete [options]="pruefergebnisOptions" formControlName="pruefergebnisSelected"
            [style]="{'width': '100%', 'height': '36px'}" [inputStyle]="{'width': '100%', 'height': '36px'}"
            ></crud-basic-autocomplete>
          </span>
        </div>
      </div>
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}" *ngIf="leistung != null && leistung.pruefergebnis == true">
        <div>
        </div>
        <div class="errorMessage">
          {{displayMessage.pruefergebnisSelected}}
        </div>
      </div>.->

    </p-tabPanel>
 -->
    <p-tabPanel *ngIf="leistung != null && leistung.unterschriftKunde == true" header="Unterschrift Kunde" [disabled]="CRUDItem == null || CRUDItem.positionsdaten == null || posCountTouchedOrAlreadySavedAndValid < CRUDItem.positionsdaten.length && debugMode!=true">
      <strong>Unterschrift Kunde:</strong>
      <br><br>
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}">

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <input pInputText formControlName="unterschriftKundeName" [ngStyle]="{'width':'100%'}" [required]="true">
            <label>{{'Name Kunde'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.unterschriftKundeName}}
          </div>
        </div>
        
        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm" style="background: #f5f5f5 no-repeat;"><!--background vom input-->
          <br>
          <span class="p-float-label">
            <!--signaturePad per ngif ein und ausblenden, weil ansonsten existieren 2 gleichzeitig -> das 2. funktioniert warum auch immer nicht-->
            <crud-basic-signature #signaturePadKunde *ngIf="CRUDItem != null && tabSelected == this.tabNRUnterschriftKunde"
            [value]="CRUDItem.unterschriftKunde" (onChange)="unterschriftOnChangeKunde()">
          </crud-basic-signature>
          <!--unsichtbares Input-Feld mit Werten auf signaturepad - gefüllt über unterschriftOnChangeXXX() -> damit der Validator funktioniert!-->
            <input formControlName="unterschriftKunde" [ngStyle]="{'opacity':'0.5'}" [style.display]="debugMode ? 'unset' : 'none'">
            <label style="top: -8px;"><small>{{'Unterschrift Kunde'|translate:true}} <i *ngIf="unterschriftKundeRequired==true" class="{{_app.mussFeldMarkerIconClass}}"></i></small></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.unterschriftKunde}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <input pInputText formControlName="unterschriftKundeEMail" [ngStyle]="{'width':'100%'}" [required]="false">
            <label>{{'E-Mail Kunde'|translate:true}}</label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.unterschriftKundeEMail}}
          </div>
        </div>

      </div>

    </p-tabPanel>

    <p-tabPanel header="Unterschrift befähigte Person" [disabled]="CRUDItem == null || CRUDItem.positionsdaten == null || posCountTouchedOrAlreadySavedAndValid < CRUDItem.positionsdaten.length && debugMode!=true"
    >

      <strong>Unterschrift befähigte Person:</strong>
      <br><br>
      <div [ngClass]="{'detail-grid': !app.inAppMode, 'detail-grid-inAppMode': app.inAppMode}">

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <input pInputText formControlName="unterschriftPrueferName" [ngStyle]="{'width':'100%'}" [required]="true">
            <label>{{'Name befähigte Person'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.unterschriftPrueferName}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm" style="background: #f5f5f5 no-repeat;"><!--background vom input-->
          <br>
          <span class="p-float-label">
            <!--signaturePad per ngif ein und ausblenden, weil ansonsten existieren 2 gleichzeitig -> das 2. funktioniert warum auch immer nicht-->
            <crud-basic-signature #signaturePadPruefer *ngIf="CRUDItem != null && tabSelected == this.tabNRUnterschriftPruefer"
            [value]="CRUDItem.unterschriftPruefer" (onChange)="unterschriftOnChangePruefer()">
          </crud-basic-signature>
            <!--unsichtbares Input-Feld mit Werten auf signaturepad - gefüllt über unterschriftOnChangeXXX() -> damit der Validator funktioniert!-->
            <input formControlName="unterschriftPruefer" [ngStyle]="{'opacity':'0.5'}" [style.display]="debugMode ? 'unset' : 'none'">
            <label style="top: -8px;"><small>{{'Unterschrift befähigte Person'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></small></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.unterschriftPruefer}}
          </div>
        </div>

        <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
          <span class="p-float-label">
            <p-chips formControlName="zusaetzlicheEMailAdressenArr" [separator]="','" [inputStyle]="{'width':'100%'}"
            [addOnBlur]="true" [addOnTab]="true"></p-chips>
            <label>{{'zusätzliche E-Mail-Adressen'|translate:true}} <i class="{{_app.mussFeldMarkerIconClass}}"></i></label>
          </span>
        </div>
        <div class="detail-grid-sub">
          <div class="errorMessage">
            {{displayMessage.zusaetzlicheEMailAdressenArr}}
          </div>
        </div>

      </div>

      <br>
      <button [disabled]="positionsdatenFotoIsUploading" style="width: auto;" class="p-button-success" pButton type="submit" [icon]="'fa fa-floppy-disk'" [label]="translate.instant('Speichern & Abschliessen',true)"></button>
      <br><!--ohne die ist der Button nicht sichtbar!-->
      <br>
      <br>
      <br>

    </p-tabPanel>

</p-tabView>


<footer>
  <div class="footer">

    <div #divCardToolbar id="divCardToolbar" class="card">
      <font *ngIf="debugMode==true" style="font-size: 7px; color: grey">tabSelected: {{tabSelected}} | tabNRStart: {{tabNRStart}} | tabNRPos: {{tabNRPos}} | tabNRKopf: {{tabNRKopf}} | tabNRAbschluss: {{tabNRAbschluss}} | tabNRUnterschriftKunde: {{tabNRUnterschriftKunde}} | tabNRUnterschriftPruefer: {{tabNRUnterschriftPruefer}} | offeneMaengelExist: {{offeneMaengelExist}} | posLetzteSichtbare: {{posLetzteSichtbare}} | posSelected: {{posSelected}} | CRUDItem.positionsdaten.length: {{CRUDItem != null ? CRUDItem.positionsdaten.length : null}}<br></font> 
      <!-- linke buttons -->
      <!-- links KEIN überspringen Button! stattdessen den normalen zurück immer wie überspringen behandeln -->
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRPos && (this.tabShowStart == true || this.posSelected > 0) ? 'unset' : 'none'" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" label="{{debugMode ? '(p)':''}}{{app.inAppMode ? '' : 'zurück'}}" icon="fa-solid fa-arrow-left" iconPos="left" (click)="zurueckClicked(true)"></button>
      <!--<button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRPos && (this.tabShowStart == true || this.posSelected > 0) && posErrors.length > 0 ? 'unset' : 'none'" label="{{debugMode ? '(p)':''}}überspringen" icon="fa-solid fa-arrow-left" iconPos="left" style="margin-left: 0.5em; background-color: red;" (click)="zurueckClicked(true)"></button>-->

      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRKopf ? 'unset' : 'none'" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" label="{{debugMode ? '(pp)':''}}{{app.inAppMode ? '' : 'zurück'}}" icon="fa-solid fa-arrow-left" iconPos="left" (click)="zurueckZuPosClicked()"></button><!--(click)=""-->
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRAbschluss ? 'unset' : 'none'" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" label="{{debugMode ? '(pk)':''}}{{app.inAppMode ? '' : 'zurück'}}" icon="fa-solid fa-arrow-left" iconPos="left" (click)="zurueckZuKopfClicked()"></button><!--(click)=""-->
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRUnterschriftKunde ? 'unset' : 'none'" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" label="{{debugMode ? '(pa)':''}}{{app.inAppMode ? '' : 'zurück'}}" icon="fa-solid fa-arrow-left" iconPos="left" (click)="zurueckZuAbschlussClicked()"></button><!--(click)=""-->
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRUnterschriftPruefer ? 'unset' : 'none'" [style.width]="app.inAppMode ? '2.5rem' : 'unset'" label="{{debugMode ? '(pu)':''}}{{app.inAppMode ? '' : 'zurück'}}" icon="fa-solid fa-arrow-left" iconPos="left" (click)="zurueckZuUnterschriftKundeClicked()"></button><!--(click)=""-->

      <!-- in der "Mitte" immer ein Dummy/unsichtbares Button - um sicherzustellen, dass der Space in jedem Fall reserviert bleibt, auch wenn Rechts und Links kein Button-->
      <button pButton type="button" label="debug" icon="fa-solid fa-debug" iconPos="left" style="float:none; background-color: darkmagenta; margin-left: 0.5em; opacity: 0.5;" [style.visibility]="debugMode ? 'unset' : 'hidden'" (click)="debug(tabView)"></button>

      <!-- rechte Buttons -->
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRPos && this.posSelected < this.CRUDItem.positionsdaten.length -1 ? 'unset' : 'none'" label="{{debugMode ? '(p)':''}}weiter&nbsp;" class="p-button-success" icon="fa-solid fa-arrow-right" iconPos="right" style="float:right;" (click)="weiterClicked()"></button>
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRPos && this.posSelected < this.CRUDItem.positionsdaten.length -1 && posErrors.length > 0 ? 'unset' : 'none'" label="{{debugMode ? '(p)':''}}überspringen&nbsp;" icon="fa-solid fa-arrow-right" iconPos="right" style="float: right; margin-right: 0.5em; background-color: red;" (click)="weiterClicked(true)"></button>

      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRStart && CRUDItem != null && CRUDItem.positionsdaten != null ? 'unset' : 'none'" label="{{debugMode ? '(s)':''}}Start&nbsp;&nbsp;" class="p-button-success" icon="fa-solid fa-arrow-right" iconPos="right" style="float:right;" (click)="startClicked()"></button><!--(click)=""-->
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRStart && CRUDItem != null && CRUDItem.positionsdaten != null && startLastValidationWasInvalid == true ? 'unset' : 'none'" label="{{debugMode ? '(s)':''}}überspringen&nbsp;" icon="fa-solid fa-arrow-right" iconPos="right" style="float:right; margin-right: 0.5em; background-color: red;" (click)="startClicked(true)"></button><!--(click)=""-->

      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRPos && this.posSelected == this.CRUDItem.positionsdaten.length - 1 ? 'unset' : 'none'" label="{{debugMode ? '(pk)':''}}weiter&nbsp;" class="p-button-success" icon="fa-solid fa-arrow-right" iconPos="right" style="float:right;" (click)="weiterZuKopfClicked()"></button>
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRPos && this.posSelected == this.CRUDItem.positionsdaten.length - 1 && posErrors.length > 0 ? 'unset' : 'none'" label="{{debugMode ? '(pk)':''}}überspringen&nbsp;" icon="fa-solid fa-arrow-right" iconPos="right" style="float: right; margin-right: 0.5em; background-color: red;" (click)="weiterZuKopfClicked(true)"></button>

      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRKopf ? 'unset' : 'none'" label="{{debugMode ? '(a)':''}}weiter&nbsp;" class="p-button-success" icon="fa-solid fa-arrow-right" iconPos="right" style="float:right;" (click)="weiterZuAbschlussClicked()"></button>
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRAbschluss ? 'unset' : 'none'" label="{{debugMode ? '(uk)':''}}weiter&nbsp;" class="p-button-success" icon="fa-solid fa-arrow-right" iconPos="right" style="float:right;" (click)="weiterZuUnterschriftKundeClicked()"></button>
      <button pButton type="button" [disabled]="positionsdatenFotoIsUploading" [style.display]="tabSelected == this.tabNRUnterschriftKunde ? 'unset' : 'none'" label="{{debugMode ? '(up)':''}}weiter&nbsp;" class="p-button-success" icon="fa-solid fa-arrow-right" iconPos="right" style="float:right;" (click)="weiterZuUnterschriftPrueferClicked()"></button>

      <div style="margin-top: 0.75em;" (click)="progressBarClicked()">
        <p-progressBar *ngIf="CRUDItem != null && CRUDItem.positionsdaten != null" [value]="(posCountTouchedOrAlreadySavedAndValid * 100 / CRUDItem.positionsdaten.length).toFixed(0)" [style]="{'height': '16px', 'font-size': app.inAppMode ? '0.8em' : 'unset'}" [showValue]="true" unit=" %&nbsp;&nbsp;&nbsp;({{posCountTouchedOrAlreadySavedAndValid}}/{{CRUDItem.positionsdaten.length}})">
          <!--https://stackoverflow.com/questions/57246782/how-can-i-format-primefaces-progressbars-value-to-always-show-2-decimal-digits
            <ng-template let-percent pTemplate="label">
            meinCustomLabel
          </ng-template>-->
        </p-progressBar>
      </div>
    </div>    
  </div>
</footer>





<p-dialog [(visible)]="wellDoneDialogShow" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" 
[style]="{'width': 'calc(100vW - 2rem)', 'max-height': '100%', 'height': 'calc(100vH - 4rem)', 'top': '1.5rem'}" 
modal="true" [closable]="false">
  <ng-template pTemplate="header">
  </ng-template>
  <div style="height: 100%; display: flex; align-items: center; /* Vertical center alignment */ justify-content: center; /* Horizontal center alignment */ text-align: center;">
    <span>
      <h4>Super gemacht, Bericht wurde erfolgreich übermittelt. Vielen Dank für Ihre Arbeit.</h4>
      <br>
      <img src="{{urlToLogo}}" style="width: 20em;" alt=""><!--assets/DLP/PRIMAGAS Logo 4c_ohne Claim.jpg-->
      <!--<h2>Primagas DLP</h2>-->
      <br>
      <br>
      <br>
      <br>
      <button pButton type="button" label="weiter" class="p-button-success" icon="" iconPos="left" (click)="wellDoneDialogWeiterClicked()"></button>
    </span>
  </div>
    <ng-template pTemplate="footer">
      
  </ng-template>
</p-dialog>


<p-dialog [(visible)]="offeneMaengelDialogShow" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" modal="true">
    <ng-template pTemplate="header">
      <div>
        <span class="fa-stack">
          <i class="fa-solid fa-gear fa-stack-1x"></i>
          <i class=" fa-solid fa-slash fa-stack-1x"></i>
        </span>
        <big>Offene Mängel:</big>
      </div>
  </ng-template>
  <div class="">
    
    <div *ngIf="CRUDItem != null && CRUDItem.positionsdaten != null && CRUDItem.positionsdaten.length > 0">
      <ng-container *ngFor="let position of CRUDItem.positionsdaten; index as i">
          <div *ngIf="position.checklistePosition.bewertung == true && position.bewertungNiO == true && position.bewertungBehoben != true && position['_enabled'] != false">
              <div>
                <div class="offeneMaengel-grid-container">
                  <div style="margin-bottom: 0.75em;">
                    <strong>Position {{i+1}}/{{CRUDItem.positionsdaten.length}}</strong><!-- Reihenfolge: ({{CRUDItem.positionsdaten[posSelected].reihenfolge}})-->
                  </div>
                  <div style="margin-bottom: 0.75em;">
                    {{position.checklistePosition.bezeichnung}}
                    <div *ngIf="position.bemerkung != null" style="margin-top: 0.375em;">
                      {{position.bemerkung}}
                    </div>
                  </div>
                </div>
                
              </div>
          </div>
      </ng-container>
      <div *ngIf="offeneMaengelExist != true">
        Es sind keine offene/nicht behobene Mängel vorhanden.
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
  </ng-template>
</p-dialog>

</ng-template>
    
