//import { Component, OnInit, AfterViewInit, OnDestroy, ViewChildren, ViewChild, ElementRef, HostListener, Inject, forwardRef, Injector, ComponentFactoryResolver } from '@angular/core';
//import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
//import { ActivatedRoute, Router, ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy } from '@angular/router';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { AppComponent } from '../app.component';

//import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from './auth.service';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service'; 
import { AppconfigService } from '../_services/appconfig.service';
import { _SystemService } from '../_services/_system.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginStep = 1; // 1 = User + PW / 2 = 2FA code

  loginForm: FormGroup;
  disableFields: boolean = false;

  displayMessage: { [key: string]: string } = {};

  returnUrl: string;

  urlToLogo: string = null;

  debug_LoginButton: boolean = false;

  constructor(@Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private authService: AuthService, private formBuilder: FormBuilder, private router: Router, 
    public translate: TranslateService, public messageWrapperService: MessageWrapperService,
    private route: ActivatedRoute,
    /*private*/protected config: AppconfigService,
    private _systemService: _SystemService
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl']/* || '/'*/;

    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
      twoFA1: [''],
      twoFA2: [''],
      twoFA3: [''],
      twoFA4: [''],
      twoFA5: [''],
      twoFA6: [''],
    });

    this.debug_LoginButton = this.config.get('debug_LoginButton');

    this.urlToLogo = this._systemService.getLogoDownloadUrl();
  }

  ngAfterViewInit() {
    //setTimeout(() => {
      //document.getElementById("username").focus();
    //}, 10);	

    let eventData = {
      eventType: "loginLoaded",
      data: //null
      /*{
        someMoreTestAttribute1: false,
        someMoreTestAttribute2: "some text",
        someMoreTestAttribute3: 4711
      }*/
      "textTestTextTest"
    };
    this._app.sendMessageToDotNet(eventData);
  }

  get f() { return this.loginForm.controls; }

  login1FA_NOT_IN_USE(username, password) {
    console.log("Login.login() username: ", username);
    //console.log("Login.login() f: ", this.f);
    delete this.displayMessage._lastLoginErrorMessage;

    this.authService.login1FA(
      {
        username: /*this.f.username.value*/username,
        password: /*this.f.password.value*/password
      }
    )
    .subscribe(success => {
      if (success) {
        //this._app.benutzer = this.authService.getBenutzer();

        this._app.initialize();
        if(this.returnUrl != null) {
          console.log("Login.login() route to returnUrl:", this.returnUrl);
          this.router.navigateByUrl('/'+this.returnUrl);
          //this.router.navigate([this.returnUrl]);
        }
        else {
          console.log("Login.login() route to /home!");
          this.router.navigate(['/map']);
        }
      }
    },
    (error: any) => {
      //this.handleError(error);
      //console.log("error: ", error);
      let additionalInfo: string = null;
      try {
        additionalInfo = error.error;
      }
      catch(e) {}

      let errorMessage: string = null;
      if(additionalInfo == "Login failed (0x06)") {
        errorMessage = "Fehler bei der Anmeldung. Der Benutzer ist gesperrt.";
      }
      else {
        errorMessage = "Fehler bei der Anmeldung. Bitte prüfen sie Benutzername und Passwort."/*+"\n"+additionalInfo*/;
      }

      this.displayMessage._lastLoginErrorMessage = errorMessage;
    });
  }

  loginForDebugApp() {
    console.log("Login.demoLogin()");

    this.authService.login1FAForDebug(
      {
        username: this.config.get('debug_LoginButtonUser'),
        password: this.config.get('debug_LoginButtonPW'),
      }
    )
    .subscribe(response => {
      console.log("Login.loginForDebug() response:", response);
      this._app.loginFromApp(response);
    },
    (error: any) => {
      //this.handleError(error);
      //console.log("error: ", error);
      let additionalInfo: string = null;
      try {
        additionalInfo = error.error;
      }
      catch(e) {}

      let errorMessage: string = null;
      if(additionalInfo == "Login failed (0x06)") {
        errorMessage = "Fehler bei der Anmeldung. Der Benutzer ist gesperrt.";
      }
      else {
        errorMessage = "Fehler bei der Anmeldung. Bitte prüfen sie Benutzername und Passwort."/*+"\n"+additionalInfo*/;
      }

      this.displayMessage._lastLoginErrorMessage = errorMessage;
    });

  }

  loginForDebugWeb() {
    console.log("Login.demoLogin()");

    this.authService.login2FA_Step1(
      {
        username: this.config.get('debug_LoginButtonUser'),
        password: this.config.get('debug_LoginButtonPW'),
      }
    )
    .subscribe(success => {
      if (success) {
        let c = success.benutzer.zweiFaktorCode;
        this.login2FA_step2(this.config.get('debug_LoginButtonUser'), this.config.get('debug_LoginButtonPW'), c);
      }
    },
    (error: any) => {
      //this.handleError(error);
      //console.log("error: ", error);
      let additionalInfo: string = null;
      try {
        additionalInfo = error.error;
      }
      catch(e) {}

      let errorMessage: string = null;
      errorMessage = "Fehler bei der Anmeldung. Bitte prüfen sie Benutzername und Passwort."+"\n"+additionalInfo;

      this.displayMessage._lastLoginErrorMessage = errorMessage;
    });



    this.authService.login1FAForDebug(
      {
        username: this.config.get('debug_LoginButtonUser'),
        password: this.config.get('debug_LoginButtonPW'),
      }
    )
    .subscribe(response => {
      console.log("Login.loginForDebug() response:", response);
      this._app.loginFromApp(response);
    },
    (error: any) => {
      //this.handleError(error);
      //console.log("error: ", error);
      let additionalInfo: string = null;
      try {
        additionalInfo = error.error;
      }
      catch(e) {}

      let errorMessage: string = null;
      if(additionalInfo == "Login failed (0x06)") {
        errorMessage = "Fehler bei der Anmeldung. Der Benutzer ist gesperrt.";
      }
      else {
        errorMessage = "Fehler bei der Anmeldung. Bitte prüfen sie Benutzername und Passwort."/*+"\n"+additionalInfo*/;
      }

      this.displayMessage._lastLoginErrorMessage = errorMessage;
    });

  }

  login2FA(username, password, code1, code2, code3, code4, code5, code6) {
    if(this.loginStep == 2) {
      this.login2FA_step2(username, password, code1+code2+code3+code4+code5+code6);
    }
    else {
      this.login2FA_step1(username, password);
    }
  }

  login2FA_step1_again() {
    console.log("Login.login2FA_step1()");
    this.loginStep = 1;
    setTimeout(() => {
      //document.getElementById("username").focus();
      this.login2FA_step1(this.loginForm.controls.username.value, this.loginForm.controls.password.value);
    }, 10);	
  }

  login2FA_step1(username, password) {
    console.log("Login.login2FA_step1() username: ", username);

    this.loginForm.patchValue({
      username: username,
      password: password
    });

    //console.log("Login.login() f: ", this.f);
    delete this.displayMessage._lastLoginErrorMessage;

    this.authService.login2FA_Step1(
      {
        username: /*this.f.username.value*/username,
        password: /*this.f.password.value*/password
      }
    )
    .subscribe(success => {
      if (success) {

        //this.messageWrapperService.postStaticMessage({ severity: 'success', summary: "Anmeldung", detail: "Sie haben per E-Mail einen Code erhalten. Bitte geben Sie den hier ein." }); 
        this.loginStep = 2;
        setTimeout(() => {
          document.getElementById("twoFA1").focus();
        }, 10);	
      }
    },
    (error: any) => {
      //this.handleError(error);
      //console.log("error: ", error);
      let additionalInfo: string = null;
      try {
        additionalInfo = error.error;
      }
      catch(e) {}

      let errorMessage: string = null;
      if(additionalInfo == "Login failed (0x06)") {
        errorMessage = "Fehler bei der Anmeldung. Der Benutzer ist gesperrt.";
      }
      else {
        errorMessage = "Fehler bei der Anmeldung. Bitte prüfen sie Benutzername und Passwort."/*+"\n"+additionalInfo*/;
      }

      this.displayMessage._lastLoginErrorMessage = errorMessage;
    });
  }

  login2FA_step2(username, password, code) {
    console.log("Login.login2FA_step2() username: ", username);
    //console.log("Login.login() f: ", this.f);
    delete this.displayMessage._lastLoginErrorMessage;

    this.authService.login2FA_Step2(
      {
        username: /*this.f.username.value*/username,
        password: /*this.f.password.value*/password,
        code: /*this.f.password.value*/code
      }
    )
    .subscribe(success => {
      if (success) {
        //this._app.benutzer = this.authService.getBenutzer();

        this._app.initialize();
        if(this.returnUrl != null) {
          console.log("Login.login() route to returnUrl:", this.returnUrl);
          this.router.navigateByUrl('/'+this.returnUrl);
          //this.router.navigate([this.returnUrl]);
        }
        else {
          console.log("Login.login() route to /home!");
          this.router.navigate(['/map']);
        }
      }
    },
    (error: any) => {
      //this.handleError(error);
      console.log("error: ", error);
      let additionalInfo: string = null;
      try {
        additionalInfo = error.error;
      }
      catch(e) {}

      let errorMessage: string = null;
      //if(additionalInfo == "Login failed (0x06)") {
      //  errorMessage = "Fehler bei der Anmeldung. Der Benutzer ist gesperrt.";
      //}
      //else {
        errorMessage = "Fehler bei der Anmeldung. Bitte prüfen sie den Code."/*+"\n"+additionalInfo*/;
      //}

      this.displayMessage._lastLoginErrorMessage = errorMessage;
    });
  }


  getUrl() {
    return window.location.href;
  }

  twoFAChange(feld: any, wert: string) {
    console.log("Login.twoFAChange() feld / wert:", feld, wert);
    if(wert != null) {
      if(wert.length == 1) { // Einzelnes Zeichen eingegeben
        feld.value = feld.value.toUpperCase();
        let nextEl = this.findNextTabStop(feld);
        nextEl.focus();
      }
    }
  }

  twoFAPaste($event) {
    console.log("Login.twoFAPaste() $event:" , $event);
    // https://stackoverflow.com/questions/2176861/javascript-get-clipboard-data-on-paste-event-cross-browser
    let clipboardData = null, pastedData = null;

    // Stop data actually being pasted into div
    $event.stopPropagation();
    $event.preventDefault();
  
    // Get pasted data via clipboard API
    clipboardData = $event.clipboardData || window['clipboardData'];
    pastedData = clipboardData.getData('Text');
  
    // Do whatever with pasteddata
    console.log("Login.twoFAPaste() pastedData:" , pastedData);

    if(pastedData.length >= 6) { // 6 Zeichen aus Mail gepasted ?
      this.loginForm.patchValue({
        twoFA1: pastedData.substring(0, 1),
        twoFA2: pastedData.substring(1, 2),
        twoFA3: pastedData.substring(2, 3),
        twoFA4: pastedData.substring(3, 4),
        twoFA5: pastedData.substring(4, 5),
        twoFA6: pastedData.substring(5, 6)
      });
    }
  }

  findNextTabStop(el) { // https://stackoverflow.com/questions/7208161/focus-next-element-in-tab-index
    let universe = document.querySelectorAll('input, button, select, textarea, a[href]');
    let list = Array.prototype.filter.call(universe, function(item) {return item.tabIndex >= "0"});
    let index = list.indexOf(el);
    return list[index + 1] || list[0];
  }

  erase2FAInput() {
    this.loginForm.patchValue({
      twoFA1: null,
      twoFA2: null,
      twoFA3: null,
      twoFA4: null,
      twoFA5: null,
      twoFA6: null
    });
  }

  /*handleError(error: any) {
    console.log("Login.handleError error", error);
    //this.globalService.addFeedbackByClone("error " + this.CRUDItemBezeichnungSingularCapitalized, "CRUDBasicDetailComponent.handleError()", error); // feedback

    //this.loading = false;
    //this.blockedDocument = false;
    let summary = this.translate.instant('Fehler', true);
    let errorMessage:string = null;

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      errorMessage = error.message;
    }

    //if(this.errorMessage != null) this.errorMessage = this.errorMessage.replace(/\n/g, "<br>");
    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: errorMessage }); // TO DO
  }*/

}
