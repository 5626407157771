import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs/Observable';
import { Observable, of } from 'rxjs';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/do';
//import 'rxjs/add/operator/catch';
import { IPagination } from '../_interfaces/pagination'
import { IKopfdaten } from '../_interfaces/kopfdaten';
//import { IChoiceList } from '../_interfaces/_choice-list';
import * as moment from 'moment'; // DateTimeOffset-Fix
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

import { AppconfigService } from '../_services/appconfig.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { CRUDBasicService, httpOptions } from '../_services/crud-basic.service';

import { ICheckliste } from '../_interfaces/checkliste';
import {ChecklisteService} from './checkliste.service';

import { IPositionsdaten } from '../_interfaces/positionsdaten';
import {PositionsdatenService} from './positionsdaten.service';

import { IPositionsdatenFelder } from '../_interfaces/positionsdaten-felder';
import {PositionsdatenFelderService} from './positionsdaten-felder.service';

  // MODIs gegenüber IMKE-Standard IN ALLEN crudbuilder-SERVICES angepasst:
  // getXXX(id: number)
  //   this.apiBaseUrl + 'xxx?id=' + id       statt     this.apiBaseUrl + 'xxx/' + id
  //   , withCredentials: true
  //   //response
  //   {
  //     console.log("KopfdatenService.getKopfdaten() response: ", response)
  //     return response[0];
  //   }
  // updateXXX(...)
  //   this.apiBaseUrl + 'xxx'      statt     this.apiBaseUrl + 'xxx/' + id

@Injectable()
export class KopfdatenService extends CRUDBasicService {

  constructor(
    /*private*/protected httpClient: HttpClient,
    //protected translate: TranslateService,
    /*private*/protected config: AppconfigService,
    private checklisteService: ChecklisteService,
    private positionsdatenService: PositionsdatenService,
    private positionsdatenFelderService: PositionsdatenFelderService,
    ) 
  {
    super(httpClient, config);
    console.log("KopfdatenService THIS: ", this)

    this.apiBaseUrl = this.config.get('apiBaseUrl');
  }

  //typeOf deprecated -> CRUDBasicService.propertyTypeOf
  /*typeOf(propertyName: string) {  // welchen Typs sind die Properties IN DER API ?
    let property = this.propertyTypes.find(f => f.name == propertyName);
    if(property != null) return property.type;
    else return null;    
  }*/
  propertyTypes = [ // welchen Typs sind die Properties IN DER API ?
    {name: 'id', type: 'int'},
    {name: 'createdBy', type: 'string'},
    {name: 'created', type: 'DateTimeOffset'},
    {name: 'modifiedBy', type: 'string'},
    {name: 'modified', type: 'DateTimeOffset'},
    {name: 'rowVersion', type: 'byte[]'},
    {name: 'pruefdatum', type: 'DateTimeOffset'},
    {name: 'pruefer', type: 'string'},
    {name: 'rueckmeldedatum', type: 'DateTimeOffset'},
    {name: 'ergebnis', type: 'string'},
    {name: 'betriebskennzeichen', type: 'string'},
    {name: 'datumNaechsteAnlagenpruefung', type: 'DateTimeOffset'},
    {name: 'datumNaechsteFeststellungspruefung', type: 'DateTimeOffset'},
    {name: 'nachpruefungErforderlich', type: 'string'},
    {name: 'datumNachpruefungBis', type: 'DateTimeOffset'},
    {name: 'bemerkung', type: 'string'},
    {name: 'eMailversand', type: 'bool'},
    {name: 'eMailversandProtokoll', type: 'string'},
    {name: 'unterschriftPruefer', type: 'byte[]'},
    {name: 'unterschriftKunde', type: 'byte[]'},
    {name: 'unterschriftKundeName', type: 'string'},
    {name: 'unterschriftKundeEMail', type: 'string'},
    {name: 'unterschriftPrueferName', type: 'string'},
    {name: 'zusaetzlicheEMailAdressen', type: 'string'},
    {name: 'behaelterdruck', type: 'decimal'},
    {name: 'behaelterfuellgrad', type: 'decimal'},
    {name: 'pumpentyp', type: 'string'},
    {name: 'weiterbetrieb', type: 'bool'},
    {name: 'massnahmenAbgeschlossen', type: 'bool'},

    {name: '_Auftragsdaten_Wiedervorlage', type: 'DateTimeOffset'},
  ];
    
  getKopfdatenCollection(pageNumber: number, pageSize: number, searchQuery: string): Observable<{ kopfdaten: IKopfdaten[], pagination: IPagination }> {
    return this.httpClient.get(this.apiBaseUrl + 'kopfdaten?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&searchQuery=' + searchQuery, { headers: httpOptions, observe: 'response', withCredentials: true })
      .pipe(
        map((response) => {
          return { kopfdaten: <IKopfdaten[]>response.body, pagination: <IPagination>JSON.parse(response.headers.get('x-pagination')) };
        }), catchError(this.handleError))
  }


  getKopfdatenIdAndChecklisteIdAndDokumentIdForAuftragsId(auftragId: number): Observable<any> { // { response z.B. {kopfdatenId = 0 (wenn es noch kein Kopf zu dem Auftrag gibt), checklisteId = 2}
    if (auftragId === 0) {
      alert("KopfdatenService.getKopfdatenIdAndChecklisteIdForAuftragsId() Error: id = 0!"); // sollte nie vorkommen!
    }

    return this.httpClient.get<IKopfdaten>(this.apiBaseUrl + 'kopfdaten/GetKopfIdAndChecklisteIdAndDokumentIdForAuftragsId/' + auftragId, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response
      ), catchError(this.handleError));
  }


  getKopfdaten(id: number): Observable<IKopfdaten> {

    if (id === 0) {
      // return Observable.create((observer: any) => {
      //   observer.next(this.initializeKopfdaten());
      //   observer.complete();
      // })
      return of(this.initializeKopfdaten());
    }

    return this.httpClient.get<IKopfdaten>(this.apiBaseUrl + 'kopfdaten?id=' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => 
      //response
      {
        console.log("KopfdatenService.getKopfdaten() response: ", response)
        return response[0];
      }
      ), catchError(this.handleError));

    /*let kopfdaten = this.initializeKopfdaten();
    kopfdaten.checkliste = this.checklisteService.___temp_getdummyChecklist();
    kopfdaten.checkliste.checklistePositionen.forEach(checklistePosition => {
      let positionsdaten = this.positionsdatenService.initializePositionsdaten();
      positionsdaten.checklistePosition = checklistePosition;
      //positionsdaten.bezeichnung = checklistePosition.bezeichnung;
      //positionsdaten.reihenfolge = checklistePosition.reihenfolge;

      checklistePosition.checklistePositionenFelder.forEach(checklistePositionFeld => {
        let positionsdatenFeld = this.positionsdatenFelderService.initializePositionsdatenFelder();
        positionsdatenFeld.checklistePositionenFeld = checklistePositionFeld;
        //positionsdatenFeld.bezeichnung = ...;
        positionsdaten.positionsdatenFelder.push(positionsdatenFeld);
      });

      kopfdaten.positionsdaten.push(positionsdaten);
    });
    return of(kopfdaten);
    */

  }

  saveKopfdaten(kopfdaten: IKopfdaten, abschliessen?: boolean): Observable<IKopfdaten> {
    // DateTimeOffset-Fix
    let itemToSave = cloneDeep(kopfdaten); // clonen, um sicherzustellen, dass das Original-Objekt nicht ver�ndert wird, evtl. passiert nach dem Save noch etwas damit ?
    //console.log("KopfdatenService.saveSingularCapitalized#() itemToSave before DateTimeOffset-Fix:", itemToSave);
    this.propertyTypes.filter(f => f.type.toLowerCase() == 'datetimeoffset').forEach(propertyType => {
      if (itemToSave[propertyType.name] != null) {
        itemToSave[propertyType.name] = moment(itemToSave[propertyType.name]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // alle datetimeoffset-felder entspr. formatieren, dass in der API auch der Offset ankommt!
      }
    });
    //console.log("KopfdatenService.saveSingularCapitalized#() itemToSave after DateTimeOffset-Fix:", itemToSave);

    if (kopfdaten.id === 0) {
      return this.createKopfdaten(/*kopfdaten*/itemToSave, abschliessen);
    }

    return this.updateKopfdaten(/*kopfdaten*/itemToSave, abschliessen)

  }

  saveKopfdatenAbgeschlossen(kopfdaten: IKopfdaten): Observable<IKopfdaten> { // save mit parameter ?abgeschlossen=true
    return this.saveKopfdaten(kopfdaten, true);
  }

  updateKopfdaten(kopfdaten: IKopfdaten, abschliessen?: boolean): Observable<IKopfdaten> {

    return this.httpClient.put<IKopfdaten>(this.apiBaseUrl + 'kopfdaten'/*/' + kopfdaten.id*/ + (abschliessen == true ? '?abschliessen=true' : ''), kopfdaten, { headers: httpOptions, observe: 'body', withCredentials: true })
    .pipe(catchError(this.handleError))

  }

  deleteKopfdaten(id: number) {

    return this.httpClient.delete(this.apiBaseUrl + 'kopfdaten/' + id, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(
        catchError(this.handleError)
      )
  }

  createKopfdaten(kopfdaten: IKopfdaten, abschliessen?: boolean): Observable<IKopfdaten> {
    return this.httpClient.post<IKopfdaten>(this.apiBaseUrl + 'kopfdaten' + (abschliessen == true ? '?abschliessen=true' : ''), kopfdaten, { headers: httpOptions, observe: 'body', withCredentials: true })
      .pipe(map((response) => response), catchError(this.handleError))
  }

  initializeKopfdaten(): IKopfdaten {
    return {
      id: 0,
      createdBy: '',
      created: /*''*/'0001-01-01T00:00:00.000Z', // fix
      modifiedBy: '',
      modified: /*''*/'0001-01-01T00:00:00.000Z', // fix
      rowVersion: '',
      pruefdatum: null,
      pruefer: '',
      rueckmeldedatum: null,
      ergebnis: '',
      betriebskennzeichen: '',
      datumNaechsteAnlagenpruefung: null,
      datumNaechsteFeststellungspruefung: null,
      nachpruefungErforderlich: '',
      datumNachpruefungBis: null,
      bemerkung: '',
      eMailversand: false,
      eMailversandProtokoll: '',
      unterschriftPruefer: '',
      unterschriftKunde: '',
      unterschriftKundeName: '',
      unterschriftKundeEMail: '',
      unterschriftPrueferName: '',
      zusaetzlicheEMailAdressen: null,
      behaelterdruck: null,
      behaelterfuellgrad: null,
      pumpentyp: null,
      anstrich: null,
      anstrichBezeichnung: null,
      massnahmenAbgeschlossen: null,
      weiterbetrieb: null,

      checkliste: null,
      positionsdaten: [],
      positionsdatenFotos: [],

      _Auftragsdaten_Wiedervorlage: null
    };
  }


}
