import { Component, OnInit, Inject, forwardRef, Injector } from '@angular/core';
import { AppComponent } from '../app.component';

import { MessageWrapperService } from '../_services/message-wrapper.service';
import { TranslateService } from '../_services/translate.service';
import { IDokumente } from '../_interfaces/dokumente';
import { DokumenteService } from '../_services/dokumente.service';

@Component({
  selector: 'app-leitfaeden-list',
  templateUrl: './leitfaeden-list.component.html',
  styleUrls: ['./leitfaeden-list.component.scss']
})
export class LeitfaedenListComponent implements OnInit {

  crudItems: IDokumente[];
  errorMessage: string;
  
  constructor(
    //@Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private translate: TranslateService,
    private messageWrapperService: MessageWrapperService,
    private crudItemService: DokumenteService
    ) { }

  ngOnInit(): void {
    this.crudItemService.getDokumenteCollection(1, 0, "")
    .subscribe(
      //response => this.onCRUDItemsRetrieved(response.titel, response.pagination),
      response => {
        let onlyLeitfaeden = response.dokumente.filter(f => f.typ == 'LF' || f.typ == 'Lnk' || f.typ == 'Ueb');

        let sorted = onlyLeitfaeden.sort((a, b) => {
          if (a.reihenfolge > b.reihenfolge) {
            return 1;
          }
          if (a.reihenfolge < b.reihenfolge) {
            return -1;
          }
          return 0;
        });

        this.crudItems = sorted;
      },
      error => this.handleError(error)
    );
  }

  /*getDownloadURL(rowData: IDokumente) {
    return this.crudItemService.apiBaseUrl + 'dokumente/download/' + rowData.id;
  }*/

  downloadToBlob(rowData: IDokumente) {
    console.log("LeitfaedenListComponent.downloadToBlob rowData:", rowData);
    this.crudItemService.downloadDokumentViaBlob(rowData.id, 'D');
  }

  handleError(error: any) {
    console.log("LeitfaedenListComponent.handleError error", error);
    //this.loading = false;

    let summary = this.translate.instant('Fehler', true);
    if (error.status === 422) {
      summary += ' (422)';
      if (error.error != null) {
        this.errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        this.errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      this.errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      this.errorMessage = <any>error
    }

    if((error.message != null && error.message != '') && error.message.indexOf("Unknown Error") > 0){
      this.errorMessage = error.message;
    }

    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: this.errorMessage });
  }

}
