<!-- START CRUD STANDARD -->
<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->

          <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
          <div class="titlebar-button-grid">
            <div>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
            </div>
            <div style="text-align: center;">
              <span style="line-height: 35px; font-size: medium; font-weight: 500;">{{pageTitle}}</span>
            </div>
            <div>
              <span class="floatRight">
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
              </span>
            </div>
          </div>

        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card" style="display: none;"><!--MODI: kein Footer-->
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>
<!-- ENDE CRUD STANDARD -->

<ng-template #CRUDChildTemplate>
  <div class="detail-grid">

    <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
      <span class="p-float-label">
        <input style="width:90%" type="text" pInputText formControlName="bezeichnung">
        <label>{{'Bezeichnung'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
      </span>
    </div>
    <div class="detail-grid-sub">
      <div class="errorMessage">
        {{displayMessage.bezeichnung}}
      </div>
    </div>

            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="beschreibung">
                <label>{{'Beschreibung'|translate:true}}</label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.beschreibung}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <!--<input style="width:90%" type="text" pInputText formControlName="leitfaden">-->
                <!--<crud-basic-checkbox formControlName="leitfaden" [nullable]="true" [label]="translate.instant('Leitfaden',true)"></crud-basic-checkbox>-->

                <crud-basic-autocomplete #typ formControlName="typ" [type]="'_CHOICELIST'"
                [choiceListServiceInstance]="_crudItemService" [choiceListMethod]="'getValidChoiceList_Typ'" [placeholder]="translate.instant('Typ', true)"
                [required]="false" [autoCompleteStyle]="{'width':'90%'}"
                [disabled]="dataId != null && dataId != 0"></crud-basic-autocomplete>

                <!--<label>{{'Leitfaden'|translate:true}}</label>-->
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.typ}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <!--<input [readonly]="CRUDForm.controls.typ.value == null" style="width:90%" type="text" pInputText formControlName="reihenfolge">-->
                <input *ngIf="CRUDForm.controls.typ.value == null" disabled style="width:90%" type="text" pInputText formControlName="reihenfolge">
                <input *ngIf="CRUDForm.controls.typ.value != null"          style="width:90%" type="text" pInputText formControlName="reihenfolge">
                <label>{{'Reihenfolge'|translate:true}}</label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.reihenfolge}}
              </div>
            </div>

            <div [style.display]="CRUDForm.controls.typ.value == 'Lnk' ? 'unset' : 'none'" [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="link">
                <label>{{'https://'|translate:false}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div [style.display]="CRUDForm.controls.typ.value == 'Lnk' ? 'unset' : 'none'" class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.link}}
                {{displayMessageForm.bitte_link_hinterlegen}}
              </div>
            </div>


<!--            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="dokument">
                <label>{{'Dokument'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.dokument}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="kopfdaten">
                <label>{{'Kopfdaten'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="detail-grid-sub">
              <div class="errorMessage">
                {{displayMessage.kopfdaten}}
              </div>
            </div>
-->

<div [style.display]="CRUDForm.controls.typ.value != 'Lnk' && CRUDForm.controls.typ.value != 'Ueb' ? 'unset' : 'none'" [formGroup]="CRUDForm" class="detai-grid-sub inputTextForm">
  <span class="md-inputfield">
    <!--<label>{{'Dokument'|translate:true}}</label>-->
  </span>
</div>
<div [style.display]="CRUDForm.controls.typ.value != 'Lnk' && CRUDForm.controls.typ.value != 'Ueb' ? 'unset' : 'none'" class="detai-grid-sub">
  <div class="errorMessage">
  </div>
</div>
<div [style.display]="CRUDForm.controls.typ.value != 'Lnk' && CRUDForm.controls.typ.value != 'Ueb' ? 'unset' : 'none'" [formGroup]="CRUDForm" class="detai-grid-sub inputTextForm">
  <span class="md-inputfield">
    <!--<label>{{'Gewerbeschein'|translate:true}}</label>-->
    <div class="gewerbescheinDiv">
      <app-dokument-datei-upload #dokument formControlName="dokument"
      [labelNoImage]="translate.instant('Es ist kein Dokument hinterlegt',true)"
      (iconClicked)="iconClicked()"
      ></app-dokument-datei-upload>
    </div>
  </span>
</div>
<div [style.display]="CRUDForm.controls.typ.value != 'Lnk' && CRUDForm.controls.typ.value != 'Ueb' ? 'unset' : 'none'" class="detai-grid-sub">
  <button style="width: 90%;" pButton type="button" icon="fa fa-upload"
      (click)="upload()"
      [label]="CRUDForm.value.dokument!=null ? translate.instant('Dokument ersetzen',true) : translate.instant('Dokument hinterlegen',true)"></button>
      <!--                [label]="CRUDForm.value._datei_Gewerbeschein!=null && ''+CRUDForm.value._datei_Gewerbeschein.length > 0 ? translate.instant('Gewerbeschein ersetzen',true) : translate.instant('Gewerbeschein hinterlegen',true)"-->
  <div class="errorMessage">
    {{displayMessageForm.bitte_dokument_hinterlegen}}
  </div>
</div>

  </div>            
</ng-template>
    
