<!-- US 15146: unsere Logik für Verarbeitung der Kurzformen (DDMMYY) hing bisher am onBlur() 
     der onBlur() triggert aber auch, wenn man innerhalb des datePickers klickt -> dann soll der blur() aber ingnoriert werden!
     Innerhalb des onBlur() aber ist nicht zu unterscheiden, warum der blur() passiert!
     Daher andere Lösung:
     - NACH Verlassen des Textfeldes geht auch der datePicker zu und triggert onClose()
     - onClose() triggert zwar auch wenn man im datePicker was ausgewählt hat (weil dann der datePicker zu geht), aber
       onClose() triggert dann erst NACHdem der geklickte Wert ins Input-Feld übernommen wurde.
       > damit funktioniert der Klick wieder sauber.
     
     -> wir müssen unsere Logik also statt an onBlur() an onClose() hängen!
    
        Das hat nur einen Haken: angenommen der datePicker ist gar nicht sichtbar, dann triggert auch kein onClose()!
        In dem Fall doch wiederum bei onBlur() (wie vor US 15146) die Kurzform-(DDMMYY)-Logik verarbeiten!
-->
<!-- "readonlyInput" heisst: schon änderbar! aber eben nicht per text-input! -->
<p-calendar #calendar [readonlyInput]="noTextInput" [keepInvalid]="enableInputHelper" (onClose)="onClose($event)" (onBlur)="onBlur($event)" 
(onShow)="onShow($event)" 
(onFocus)="onFocus($event)" 
[dateFormat]="dateFormat" [showTime]="showTime" [stepMinute]="stepMinute" [showSeconds]="showSeconds" 
[defaultDate]="defaultDate" 
[inputStyle]="inputStyle" [style]="style" 
[(ngModel)]="value"
appendTo="body"
></p-calendar>
<!--MODI primeNG>=11:[locale]="DE"-->
<!--MODI:appendTo-->
<!--(onSelect)="handleSelect($event)"-->