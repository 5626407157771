<p-blockUI [blocked]="true">
<div style="position:absolute;top:25%;left:40%">

<form [formGroup]="loginForm" #form autocomplete="off" novalidate (ngSubmit)="login2FA(username.value, password.value, twoFA1.value, twoFA2.value, twoFA3.value, twoFA4.value, twoFA5.value, twoFA6.value)">
  <div class="align-self-center mt-auto mb-auto" style="width: 25em;">
      <div class="pages-panel card flex flex-column">
          <!--<h4>DLP</h4>-->
          <div style="height: 100%; display: flex; align-items: center; /* Vertical center alignment */ justify-content: center; /* Horizontal center alignment */ text-align: center;">
              <img src="{{urlToLogo}}" style="width: 14em;" alt=""><!--assets/DLP/PRIMAGAS Logo 4c_ohne Claim.jpg-->
          </div>
          <br>

          <div class="pages-detail mb-6 px-6">Willkommen beim Dienstleisterportal</div>

          <div [style.display]="loginStep == 1 ? 'unset' : 'none'">
              <div class="input-panel flex flex-column px-3">
                  <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                          <i class="pi pi-envelope"></i>
                      </span>
                      <span class="p-float-label">
                          <input type="text" #username pInputText loginFormControl formControlName="username"
                                 autocomplete="off" required id="username">
                          <label for="email">Benutzername</label>
                      </span>
                  </div>

                  <div class="p-inputgroup mt-3 mb-6">
                      <span class="p-inputgroup-addon">
                          <i class="pi pi-lock"></i>
                      </span>
                      <span class="p-float-label">
                          <input loginFormControl id="password" type="password" #password formControlName="password"
                                 autocomplete="off" required pInputText>
                          <label for="password">Password</label>
                      </span>
                  </div>

                  <div class="errorMessage" style="margin-bottom: 1em; margin-top: -2em; margin-right: 0em;">
                      {{displayMessage._lastLoginErrorMessage}}
                  </div>

                  <p-button id="loginButton" class="login-button mb-1 px-3" label="Anmelden" [icon]="'pi pi-sign-in'" type="submit"></p-button>

                  <br>
                  <a *ngIf="getUrl().substr(0,21)=='http://localhost:4700'" (click)="login2FA_step1('adm-am','test-1234')" style="cursor: pointer;">adm-am</a>

                  <br>
                  <a *ngIf="getUrl().substr(0,21)=='http://localhost:4700'" (click)="login2FA_step1('am','test-1234')" style="cursor: pointer;">am</a>
              </div>

          </div><!--Ende LoginStep 1-->

          <div [style.display]="loginStep == 2 ? 'unset' : 'none'">
              Sie haben per E-Mail einen Code erhalten. <br>
              Bitte geben Sie den hier ein: <br><br>
              <span class="p-float-label">
                  <input type="text" #twoFA1 pInputText formControlName="twoFA1" autocomplete="off" required class="twoFASingle" (input)="twoFAChange(twoFA1, $event)" (paste)="twoFAPaste($event)" id="twoFA1">
                  &nbsp;
                  <input type="text" #twoFA2 pInputText formControlName="twoFA2" autocomplete="off" required class="twoFASingle" (input)="twoFAChange(twoFA2, $event)" (paste)="twoFAPaste($event)">
                  &nbsp;
                  <input type="text" #twoFA3 pInputText formControlName="twoFA3" autocomplete="off" required class="twoFASingle" (input)="twoFAChange(twoFA3, $event)" (paste)="twoFAPaste($event)">
                  &nbsp;
                  <input type="text" #twoFA4 pInputText formControlName="twoFA4" autocomplete="off" required class="twoFASingle" (input)="twoFAChange(twoFA4, $event)" (paste)="twoFAPaste($event)">
                  &nbsp;
                  <input type="text" #twoFA5 pInputText formControlName="twoFA5" autocomplete="off" required class="twoFASingle" (input)="twoFAChange(twoFA5, $event)" (paste)="twoFAPaste($event)">
                  &nbsp;
                  <input type="text" #twoFA6 pInputText formControlName="twoFA6" autocomplete="off" required class="twoFASingle" (input)="twoFAChange(twoFA6, $event)" (paste)="twoFAPaste($event)">
                  <small>&nbsp;&nbsp;<i class="fa-regular fa-xmark" style="opacity: 0.6;" (click)="erase2FAInput()"></i></small>
              </span>

              <div class="errorMessage" style="margin-bottom: 1em; margin-top: 1em; margin-right: 0em;">
                  {{displayMessage._lastLoginErrorMessage}}
              </div>
              <p-button id="loginButton2FA2" class="login-button mb-1 px-3" label="Anmelden" [icon]="'pi pi-sign-in'" type="submit"></p-button>
              <br><br>
              <!--<p-button class="p-button-link" type="button" [label]="'Code nochmal senden.'" (click)="login2FA_step1_again()"></p-button>-->
              <a (click)="login2FA_step1_again()" style="cursor: pointer;">Code nochmal senden.</a>

          </div><!--Ende LoginStep 2-->
          <p-button *ngIf="debug_LoginButton" id="demoLoginButton" class="login-button mb-1 px-3" label="loginForDebug" [icon]="'pi pi-sign-in'" (click)="loginForDebugWeb()"></p-button>
      </div>
  </div>

  </form>

</div>
</p-blockUI>
