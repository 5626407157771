<!-- START CRUD STANDARD -->
<p-confirmDialog [key]="'CRUDBasicDetailConfirmDialog_'+CRUDItemBezeichnungPluralCapitalized" [appendTo]="confirmDialogAppendTo()" [acceptLabel]="translate.instant('Ja',true)" [rejectLabel]="translate.instant('Nein',true)"></p-confirmDialog>
<!-- [style]="{'z-index': '9999'}" -->
<p-blockUI [blocked]="blockedDocument">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="position:absolute;top:25%;left:50%"></i>
</p-blockUI>
<ng-container *ngTemplateOutlet="CRUDTemplatePluginTop"></ng-container>
<div class="ui-g ui-fluid">
  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-lg-12" style="padding-top:0">
      <form [formGroup]="CRUDForm" #form (ngSubmit)="validateAndSaveCRUDItem(true)" novalidate>
        <div #divCardToolbar class="card">
          <!-- [ngClass]="CRUDTemplatePluginTop == null ? 'card-toolbar' : null" [class.card-toolbar-static-inactive]="(app.isOverlay() || app.isHorizontal() || app.staticMenuDesktopInactive === true || !app.isDesktop()) && !app.isSlim()"
          [class.card-toolbar-horizontal-active]="!app.isOverlay() && app.isHorizontal() && app.isDesktop()"
          [class.card-toolbar-slim-active]="app.isSlim()"-->
          <!-- class="card card-toolbar" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->

          <!--MODI ggü IMKE: Die Buttonleiste ist in einem Grid, weil in der Mitte: pageTitle -->
          <div class="titlebar-button-grid">
            <div>
              <!--<button style="width: auto;" pButton type="submit" icon="ui-icon-save" [label]="translate.instant('Speichern',true)"></button>-->
              <button style="width: auto;" pButton type="submit" [icon]="CRUDButtonSaveIcon" [label]="translate.instant(CRUDButtonSaveLabel,true)"></button>
            </div>
            <div style="text-align: center;">
              <span style="line-height: 35px; font-size: medium; font-weight: 500;">{{pageTitle}}</span>
            </div>
            <div>
              <span class="floatRight">
              <!--<button style="width: auto;" pButton type="button" icon="ui-icon-help" [label]="translate.instant('Hilfe',true)" (click)="showHelp()"></button>-->
              <button style="width: auto; margin-left: 5px;" pButton type="button" icon="ui-icon-close" [label]="translate.instant('Abbrechen',true)"
                (click)="cancelCRUDEdit()"></button><!-- [routerLink]="['/'+CRUDItemRoutePlural]"-->
              </span>
            </div>
          </div>

        </div>

        <div [ngClass]="CRUDTemplatePluginTop == null ? 'card-container' : null"><!-- class="card-container" US 14470 Toolbar nur fixiert, wenn kein CRUDTemplatePluginTop drüber -->
          <div class="card">
            <div class="ui-g form-group" id="parentDiv">

              <!--CHILD_CONTENT-->
              <!--<ng-container *ngIf="true==true; then CRUDChildTemplate">das hier dazw. wird niemals angezeigt!</ng-container>-->
              <ng-container *ngTemplateOutlet="CRUDChildTemplate"></ng-container>

              <!--<div class="ui-g-12 ui-md-12">
                  <div class="ui-g-12 ui-md-6">
                    <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant(this.CRUDItemKurzform+ ' löschen',true)"
                      (click)="deleteCRUDItem()"></button>
                  </div>
              </div>-->

            </div>
          </div>
          <div class="card" style="display: none;"><!--MODI: kein Footer-->
            <!--<app-footer-info *ngIf="CRUDHideModified == false" [data]="CRUDItem" [deleteButtonLabel]="CRUDDisableLoeschen ? null : translate.instant(this.CRUDItemKurzform+ ' löschen',true)" (delete)="deleteCRUDItem()"></app-footer-info>-->

            <div *ngIf="CRUDHideModified == true" class="ui-g">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-6 ui-lg-3">
                  <button pButton type="button" icon="ui-icon-delete" class="ui-button-danger" [label]="translate.instant('Löschen',true)"
                    (click)="deleteCRUDItem()"></button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <!--</fieldset>-->
      </form>
    </div>
  </div>
</div>
<button *ngIf="debugMode == true" pButton (click)="debug(form)" icon="fa-bug" title="debug form"></button>
<!-- ENDE CRUD STANDARD -->

<ng-template #CRUDChildTemplate>
  <div class="detail-grid">

    <div [formGroup]="CRUDForm" class="p-col-6 inputTextForm" >
      <span class="p-float-label">
        <input style="width:90%" type="text" pInputText [formGroup]="CRUDForm" formControlName="userName"
        [readonly]="CRUDItem.id > 0">
        <label>{{'Benutzername'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
      </span>
    </div>
    <div class="p-col-6">
      <div class="errorMessage">
        {{displayMessage.userName}}<!-- funktioniert bei async validator so nicht, weil displayMessage vermutlich erst nach verlassen des Feldes aktualisiert wird, daher:-->
        {{CRUDForm != null && (CRUDForm.controls.userName.dirty || CRUDForm.controls.userName.touched) && CRUDForm.controls.userName.errors != null && CRUDForm.controls.userName.errors.Benutzername_exists != null && displayMessage.userName == "" ? translate.instant('Benutzername_exists',true) : null}}

        {{displayMessageForm.Benutzername_ist_erforderlich}}
        {{displayMessageForm.Benutzername_enthaelt_ungueltige_Zeichen}}
        {{displayMessageForm.Benutzername_enthaelt_ungueltige_Zeichen != null ? ": "+_crudItemService.validateUserName(CRUDForm.value.userName) : null}}
      </div>
    </div>

            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="aspNetUserId">
                <label>{{'Asp Net User Id'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.aspNetUserId}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="dienstleister">
                <label>{{'Dienstleister'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.dienstleister}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="dienstleisterId">
                <label>{{'Dienstleister Id'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.dienstleisterId}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="email">
                <label>{{'Email'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.email}}
              </div>
            </div>

            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="mobil">
                <label>{{'Mobil'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.mobil}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="vorname">
                <label>{{'Vorname'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.vorname}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="name">
                <label>{{'Name'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.name}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="detail-grid-sub inputTextForm" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <span class="p-float-label">
                <!--<input style="width:90%" type="text" pInputText formControlName="leitfaden">-->
                <!--<crud-basic-checkbox formControlName="leitfaden" [nullable]="true" [label]="translate.instant('Leitfaden',true)"></crud-basic-checkbox>-->

                <crud-basic-autocomplete #filterung formControlName="filterung" [type]="'_CHOICELIST'"
                [choiceListServiceInstance]="_crudItemService" [choiceListMethod]="'getValidChoiceList_Filterung'" [placeholder]="translate.instant('Filterung', true)"
                [required]="false" [autoCompleteStyle]="{'width':'90%'}"
                [disabled]="false && (dataId != null && dataId != 0)"></crud-basic-autocomplete>

                <!--<label>{{'Leitfaden'|translate:true}}</label>-->
              </span>
            </div>
            <div class="detail-grid-sub" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <div class="errorMessage">
                {{displayMessage.typ}}
                <!--DEBUG: CRUDForm.value.filterung: {{CRUDForm.value.filterung}}<br>-->
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="prueferKennzeichen">
                <label>{{'Prüfer-Kennzeichen'|translate:true}}</label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <div class="errorMessage">
                {{displayMessage.prueferKennzeichen}}
              </div>
            </div>

            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="sessions">
                <label>{{'Sessions'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.sessions}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm">
              <span class="p-float-label">
                <input style="width:90%" type="text" pInputText formControlName="telefon">
                <label>{{'Telefon'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.telefon}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 checkboxes" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <span class="p-float-label">
                <crud-basic-checkbox formControlName="istAdmin" [nullable]="false" [label]="translate.instant('Administrator',true)" [pTooltip]="'Benutzer ist Administrator'"></crud-basic-checkbox>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <div class="errorMessage">
                {{displayMessage.admin}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 checkboxes" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <span class="p-float-label">
                <crud-basic-checkbox formControlName="aktiv" [nullable]="false" [label]="translate.instant('Aktiv',true)"></crud-basic-checkbox>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6" [style.display]="benutzer == null || benutzer.istAdmin != true ? 'none' : 'unset'">
              <div class="errorMessage">
                {{displayMessage.aktiv}}
              </div>
            </div>

            <!--<div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 checkboxes">
              <span class="p-float-label">
                <crud-basic-checkbox formControlName="istAnsprechpartner" [nullable]="false" [label]="translate.instant('ist Ansprechpartner',true)"></crud-basic-checkbox>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.istAnsprechpartner}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 checkboxes">
              <span class="p-float-label">
                <crud-basic-checkbox formControlName="istBenutzer" [nullable]="false" [label]="translate.instant('ist Benutzer',true)"></crud-basic-checkbox>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6">
              <div class="errorMessage">
                {{displayMessage.istBenutzer}}
              </div>
            </div>-->

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="CRUDItem.id <= 0 ? 'unset' : 'none'">
              <span class="md-inputfield p-float-label">
                  <input formControlName="identifikation" autocomplete="new-password" [type]="passwordVisible ? 'text' : 'password'" class="inputFields"
                  promptLabel="Bitte tragen Sie ein Passwort ein" weakLabel="schwach" mediumLabel="mittel"
                  strongLabel="stark" pPassword style="width: 90% !important" />
                <!-- width 92%, damit noch das auge dahinterpasst -->&nbsp;<i class="fa fa-fw"
                  [ngClass]="{'fa-eye-slash': passwordVisible, 'fa-eye': !passwordVisible}" (click)="passwordVisible = !passwordVisible"
                  title="{{ passwordVisible ? translate.instant('verstecken',true) : translate.instant('anzeigen',true)}}"
                  style="cursor: pointer;"></i>
                  <label>{{'Passwort'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
              </span>
            </div>
            <div class="ui-g-12 ui-md-6" [style.display]="CRUDItem.id <= 0 ? 'unset' : 'none'">
              <div class="errorMessage">
                {{displayMessage.identifikation}}
                {{displayMessageForm.Passwort_ist_erforderlich}}
              </div>
            </div>

            <div [formGroup]="CRUDForm" class="ui-g-12 ui-md-6 inputTextForm" [style.display]="CRUDItem.id <= 0 ? 'unset' : 'none'">
                <span class="md-inputfield p-float-label">
                    <input formControlName="_identifikationBestaetigen" [type]="passwordVisible ? 'text' : 'password'"
                    autocomplete="new-password" [feedback]="false" class="inputFields"
                    pPassword style="width: 90% !important" />
                    <label>{{'Passwort best&auml;tigen'|translate:true}} <i class="fa-regular fa-circle-exclamation fa-xs"></i></label>
                </span>
              </div>
              <div class="ui-g-12 ui-md-6" [style.display]="CRUDItem.id <= 0 ? 'unset' : 'none'">
                <div class="errorMessage">
                  {{displayMessage._identifikationBestaetigen}}
                  {{displayMessageForm.Die_Passwoerter_stimmen_nicht_ueberein}}
                </div>
              </div>



  </div>
</ng-template>
    
