<p-splitter *ngIf="this.app.inAppMode != true" #splitter id="mapComponentSplitter" [style]="{'height': '100%'}" [panelSizes]="panelSizes" [layout]="splitterLayout" [gutterSize]="splitterGutterSize" (onResizeEnd)="splitterMoved">
    <ng-template pTemplate>
        <div style="overflow-y: auto;" [style.maxHeight]="panelMaxHeight" [style.width]="panelWidth" id="auftragslistDiv">
            <app-auftragsdaten-list #auftragsdatenList  
            [style.display]="fakeCRUDItem != null && fakeCRUDItem.auftraegeFiltered != null && viewType != 'mapOnly' ? unset : none"
            [mode]="mode"
            [parentCrudItem]="fakeCRUDItem" [parentComponent]="this"
            [crudItems]="fakeCRUDItem.auftraegefiltered"
            (crudItemsValueChange)="onCrudItemsValueChangeAuftraege($event)" [style.width]="'100%'"></app-auftragsdaten-list>
            <!--*ngIf="viewType != 'mapOnly'"-->
        </div>
        <div class="btnFloating">
            <button pButton type="button" label="debug" icon="fa-solid fa-debug" iconPos="left" style="float:left" (click)="debug()"></button>
        </div>
    </ng-template>
    <ng-template pTemplate>
        <div class="map-container" id="mapComponentMapContainer">
            <div class="map-frame">
                <div id="map"></div>
            </div>
        </div>
    </ng-template>
</p-splitter>
<div *ngIf="this.app.inAppMode == true" style="display: grid; gap: 0px;" style="{{inAppModeStyleGrid}}">
    <div class="map-container" id="mapComponentMapContainer" style="{{inAppModeStyleMapContainer}}"
    [style.display]="mode == 'map' && (fakeCRUDItem != null && fakeCRUDItem.auftraegeFiltered != null) ? 'unset' : 'none'"><!--mit topbar wären 50px-->
        <div class="map-frame" style="{{inAppModeStyleMapFrame}}"><!--mit topbar wären 50px-->
            <div id="map" style="{{inAppModeStyleMapMap}}"></div><!--mit topbar wären 50px, TODO: warum -2 ??? frame ???-->
        </div>
    </div>

    <div style="{{inAppModeStyleListContainer}}">
        <div id="auftragslistDiv" [style.display]="auftraegeListAppModeShow == true ? 'unset' : 'none'">
                <app-auftragsdaten-list #auftragsdatenList  
                [style.display]="fakeCRUDItem != null && fakeCRUDItem.auftraegeFiltered != null ? 'unset' : 'none'"
                [mode]="mode"
                [parentCrudItem]="fakeCRUDItem" [parentComponent]="this"
                [crudItems]="fakeCRUDItem.auftraegefiltered"
                (crudItemsValueChange)="onCrudItemsValueChangeAuftraege($event)" [style.width]="'100%'"></app-auftragsdaten-list>
        </div>
    </div>

    <div class="btnFloating">
        <button pButton *ngIf="auftraegeListAppModeShow == true" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
        type="button" label="Liste" iconPos="left" (click)="showList_changed('M')">
            <span class="fa-stack">
              <i class="fa-solid fa-list fa-stack-1x"></i>
              <i class=" fa-solid fa-slash fa-stack-1x"></i>
            </span>
        </button>
        <button pButton *ngIf="auftraegeListAppModeShow == false" type="button" label="Liste" icon="fa-solid fa-list" iconPos="left" (click)="showList_changed('L')">
        </button>
    </div>
</div>

<!--<button pButton type="button" label="debug" icon="fa-solid fa-debug" iconPos="left" style="float:left" (click)="debug()"></button>-->

<div class="dbgFloating" style="min-width: 17em; z-index: 99999; background-color: unset;" *ngIf="debugMode == true && offlineEnable == true">
<!--
    <p-progressBar [value]="offlineFetchCounterProzent" [style]="{'height': '1em'}" [style.display]="offlineFetchCounterProzent > 0 && offlineFetchCounterProzent < 100 ? 'unset' : 'none'"></p-progressBar>
    <br>
    <button pButton type="button" class="p-button-danger" label="hide" style="font-size: 0.5em; min-width: unset;" (click)="offlineHideControls()">
    </button>
    <button pButton type="button" class="p-button-danger" label="show" style="font-size: 0.5em; min-width: unset;" (click)="offlineShowControls()">
    </button>
    <button pButton type="button" class="p-button-danger" label="D" style="font-size: 0.5em; min-width: unset;" (click)="offlineZoomD()">
    </button>
    <button pButton type="button" class="p-button-danger" label="save" style="font-size: 0.5em; min-width: unset;" (click)="offlineSaveTiles()">
    </button>
    <button pButton type="button" class="p-button-danger" label="erase" style="font-size: 0.5em; min-width: unset;" (click)="offlineRemoveTiles()">
    </button>
    <button pButton type="button" class="p-button-danger" label="onl." style="font-size: 0.5em; min-width: unset;" (click)="dbg_goOnline()">
    </button>
    <button pButton type="button" class="p-button-danger" label="offl." style="font-size: 0.5em; min-width: unset;" (click)="dbg_goOffline()">
    </button>
    <button pButton type="button" class="p-button-danger" label="storage" style="font-size: 0.5em; min-width: unset;" (click)="dbg_showOfflineStorage()">
    </button>
-->
    <span style="background-color: yellow; color: red">simulateNetworkIOS:{{simulateNetworkIOS}} simulateNetwork2g:{{simulateNetwork2g}}</span><br>
    <button pButton type="button" class="p-button-danger" label="rmv LocalStor" style="font-size: 0.5em; min-width: unset;" (click)="dbg_localStorage_removeOfflineToggle()"></button>
    <button pButton type="button" class="p-button-danger" label="start on (ios)" style="font-size: 0.5em; min-width: unset;" (click)="dbg_start_on()"></button>
    <button pButton type="button" class="p-button-danger" label="start off (ios)" style="font-size: 0.5em; min-width: unset;" (click)="dbg_start_off()"></button>
    <button pButton type="button" class="p-button-danger" label="start w/o (and)" style="font-size: 0.5em; min-width: unset;" (click)="dbg_start_without_on_off()"></button>
    <button pButton type="button" class="p-button-danger" label="zoom 12" style="font-size: 0.5em; min-width: unset;" (click)="dbg_zoom_12()"></button>
    <button pButton type="button" class="p-button-danger" label="maui off" style="font-size: 0.5em; min-width: unset;" (click)="dbg_ConnectionChanged_from_MAUI_off()"></button>
    <button pButton type="button" class="p-button-danger" label="maui on" style="font-size: 0.5em; min-width: unset;" (click)="dbg_ConnectionChanged_from_MAUI_on()"></button>
</div>

<div class="offlineToogleFloating leaflet-control" [style.display]="offlineEnable == true ? 'unset' : 'none'">
    <p-selectButton [options]="offlineToggleOptions" [(ngModel)]="offlineToggleSelected" optionLabel="name" styleClass="offlineToggle" [style]="{'max-height': '2.375em'}"
    (onChange)="offlineToogleChanged($event)"></p-selectButton>
</div>
  
<div class="btnFloating" *ngIf="this.app.inAppMode != true">
  <button pButton *ngIf="mode=='map' && alreadyAusgeblendet == false" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
  type="button" label="Liste ausblenden" iconPos="left" (click)="showList_changed('L')">
      <span class="fa-stack">
        <i class="fa-solid fa-list fa-stack-1x"></i>
        <i class=" fa-solid fa-slash fa-stack-1x"></i>
      </span>
  </button>
  <button pButton *ngIf="mode=='map' && alreadyAusgeblendet == true" type="button" label="Liste einblenden" icon="fa-solid fa-list" iconPos="left" (click)="showList_changed('L')">
  </button>

  <!-- Spezialfall: man kann auch im List-Mode die Liste ausblenden! -->
  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == false" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
  type="button" label="Liste ausblenden&nbsp;" iconPos="left" (click)="showList_changed('L')">
      <span class="fa-stack">
        <i class="fa-solid fa-list fa-stack-1x"></i>
        <i class=" fa-solid fa-slash fa-stack-1x"></i>
      </span>
  </button>
  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == true && alreadyAusgeblendetLastML=='L'" type="button" label="Liste einblenden" icon="fa-solid fa-list" iconPos="left" (click)="showList_changed('L')">
  </button>
  <span *ngIf="mode=='list'"><br></span>

  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == false" style="padding: 0.25em; padding-right: 1em;    margin-top: 0.25em;"
  type="button" label="Karte ausblenden" iconPos="left" (click)="showList_changed(undefined, true)">
      <span class="fa-stack">
        <i class="fa-solid fa-map fa-stack-1x"></i>
        <i class=" fa-solid fa-slash fa-stack-1x"></i>
      </span>
  </button>
  <button pButton *ngIf="mode=='list' && alreadyAusgeblendet == true && alreadyAusgeblendetLastML!='L'" type="button" label="Karte einblenden" icon="fa-solid fa-map" iconPos="left" (click)="showList_changed(undefined, true)">
  </button>
</div>

<app-topbar-replacement-for-app [style.display]="app.inAppMode == true ? 'unset' : 'none'"></app-topbar-replacement-for-app>

<p-dialog [(visible)]="dialogAuftragShow" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="details-grid-container">
            <!-- zeile 1 -->
            <div>
                <i class="fa fa-{{ getLeistungForAuftrag(lastclickedAuftrag).icon }} fa-xl"></i>
            </div>
            <div>
                <big>{{ getLeistungForAuftrag(lastclickedAuftrag).bezeichnung }}:</big>
                <small *ngIf="debugMode==true"> {{lastclickedAuftrag.id}}</small>
            </div>
        </div>
    </ng-template>
    <ng-container *ngIf="lastclickedAuftrag != null">
        <div class="details-grid-container">
            <!-- Überschrift -->
            <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'" class="details-ueberschrift-left">
                &nbsp;
            </div>
            <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'" class="details-ueberschrift-right">
                Behälterstandort
            </div>
           
            <!-- zeile 1: Behälterstandort -->
            <div>
                <i class="fa fa-location"></i>
            </div>
            <div pTooltip="Standort" tooltipPosition="left">
                {{lastclickedAuftrag.name_1Behaelterstandort}} {{lastclickedAuftrag.name_2Behaelterstandort}}<br>
                {{lastclickedAuftrag.strasseBehaelterstandort}}<br>
                {{lastclickedAuftrag.plzBehaelterstandort}} {{lastclickedAuftrag.ortBehaelterstandort}} {{lastclickedAuftrag.ortzusatzBehaelterstandort}}
            </div>
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null && lastclickedAuftrag.telefonBehaelterstandort.length > 0">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null && lastclickedAuftrag.telefonBehaelterstandort.length > 0" pTooltip="Telefon" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonBehaelterstandort)">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
            </div>
            <!-- Tel. geschäftlich -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort != null && lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort != telefonBehaelterstandort">
                <i class="fa-solid fa-briefcase"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort != null && lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort != telefonBehaelterstandort" pTooltip="Telefon geschäftlich" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort | phoneCanonical}}">{{lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort)">{{lastclickedAuftrag.telefonGeschaeftlichBehaelterstandort | phoneCanonical}}</a>
            </div>
            <!-- Tel. privat -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatBehaelterstandort != null && lastclickedAuftrag.telefonPrivatBehaelterstandort != telefonBehaelterstandort">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatBehaelterstandort != null && lastclickedAuftrag.telefonPrivatBehaelterstandort != telefonBehaelterstandort" pTooltip="Telefon privat" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonPrivatBehaelterstandort | phoneCanonical}}">{{lastclickedAuftrag.telefonPrivatBehaelterstandort | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonPrivatBehaelterstandort)">{{lastclickedAuftrag.telefonPrivatBehaelterstandort | phoneCanonical}}</a>
            </div>
            <!-- Tel. mobil -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilBehaelterstandort != null && lastclickedAuftrag.telefonMobilBehaelterstandort != telefonBehaelterstandort">
                <i class="fa-solid fa-mobile"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilBehaelterstandort != null && lastclickedAuftrag.telefonMobilBehaelterstandort != telefonBehaelterstandort" pTooltip="Telefon mobil" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonMobilBehaelterstandort | phoneCanonical}}">{{lastclickedAuftrag.telefonMobilBehaelterstandort | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonMobilBehaelterstandort)">{{lastclickedAuftrag.telefonMobilBehaelterstandort | phoneCanonical}}</a>
            </div>
            <!-- eMail -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailBehaelterstandort != null">
                <i class="fa-solid fa-envelope"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailBehaelterstandort != null" pTooltip="E-Mail" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.eMailBehaelterstandort != null" href="mailto:{{lastclickedAuftrag.eMailBehaelterstandort}}">{{lastclickedAuftrag.eMailBehaelterstandort}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag.eMailBehaelterstandort != null" style="cursor: pointer" (click)="eMailClicked(lastclickedAuftrag.eMailBehaelterstandort)">{{lastclickedAuftrag.eMailBehaelterstandort}}</a>
            </div>

        </div>

        <div class="details-grid-container" style="margin-top: 1em;" 
        *ngIf="lastclickedAuftrag.name_1Ansprechpartner != null && lastclickedAuftrag.name_1Ansprechpartner.length > 0 && zaehlerEnabled == true">
            <!-- Überschrift -->
            <div class="details-ueberschrift-left">
                &nbsp;
            </div>
            <div class="details-ueberschrift-right">
                Ansprechpartner
            </div>
        </div>
        <div class="details-grid-container-with-right" *ngIf="lastclickedAuftrag.name_1Ansprechpartner != null && lastclickedAuftrag.name_1Ansprechpartner.length > 0">
            <!-- nächste: Ansprechpartner -->
            <div>
                <div style="min-height: 1.25em;"></div>
                <i class="fa-solid fa-user"></i>
            </div>
            <div pTooltip="Ansprechpartner" tooltipPosition="left">
                <div style="min-height: 1.25em;"></div>
                {{lastclickedAuftrag.name_1Ansprechpartner != null ? lastclickedAuftrag.name_1Ansprechpartner : lastclickedAuftrag.name_1Kunde}} {{lastclickedAuftrag.name_1Ansprechpartner != null ? lastclickedAuftrag.name_2Ansprechpartner : lastclickedAuftrag.name_2Kunde}}<br><!-- das ist absicht, dass die 2. condition ebenfalls auf name1 abfrägt!!!-->
                <!--{{lastclickedAuftrag.strasseKunde}}<br>
                {{lastclickedAuftrag.plzKunde}}<br> {{lastclickedAuftrag.ortKunde}}-->
            </div>
            <div>
                <button pButton type="button" label="techn. Ansprechpartner" icon="fa-solid fa-edit" iconPos="left" style="float:right" (click)="stammdatenAendernClicked(lastclickedAuftrag)"></button>
            </div>
        </div>
        <div class="details-grid-container" style="margin-top: 10px;">
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonAnsprechpartner != null && lastclickedAuftrag.telefonAnsprechpartner.length > 0">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonAnsprechpartner != null && lastclickedAuftrag.telefonAnsprechpartner.length > 0" pTooltip="Telefon" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.telefonAnsprechpartner != null" href="tel:{{lastclickedAuftrag.telefonAnsprechpartner | phoneCanonical}}">{{lastclickedAuftrag.telefonAnsprechpartner | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag.telefonAnsprechpartner != null" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonAnsprechpartner)">{{lastclickedAuftrag.telefonAnsprechpartner | phoneCanonical}}</a>
            </div>
            <!-- Tel. geschäftlich -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner != null && lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner != telefonAnsprechpartner">
                <i class="fa-solid fa-briefcase"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner != null && lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner != telefonAnsprechpartner" pTooltip="Telefon geschäftlich" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner | phoneCanonical}}">{{lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner)">{{lastclickedAuftrag.telefonGeschaeftlichAnsprechpartner | phoneCanonical}}</a>
            </div>
            <!-- Tel. privat -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatAnsprechpartner != null && lastclickedAuftrag.telefonPrivatAnsprechpartner != telefonAnsprechpartner">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatAnsprechpartner != null && lastclickedAuftrag.telefonPrivatAnsprechpartner != telefonAnsprechpartner" pTooltip="Telefon privat" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonPrivatAnsprechpartner | phoneCanonical}}">{{lastclickedAuftrag.telefonPrivatAnsprechpartner | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonPrivatAnsprechpartner)">{{lastclickedAuftrag.telefonPrivatAnsprechpartner | phoneCanonical}}</a>
            </div>
            <!-- Tel. mobil -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilAnsprechpartner != null && lastclickedAuftrag.telefonMobilAnsprechpartner != telefonAnsprechpartner">
                <i class="fa-solid fa-mobile"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilAnsprechpartner != null && lastclickedAuftrag.telefonMobilAnsprechpartner != telefonAnsprechpartner" pTooltip="Telefon mobil" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonMobilAnsprechpartner | phoneCanonical}}">{{lastclickedAuftrag.telefonMobilAnsprechpartner | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonMobilAnsprechpartner)">{{lastclickedAuftrag.telefonMobilAnsprechpartner | phoneCanonical}}</a>
            </div>
            <!-- email -->
            <div *ngIf="lastclickedAuftrag.eMailAnsprechpartner != null && lastclickedAuftrag.eMailAnsprechpartner.length > 0">
                <i class="fa-solid fa-envelope"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.eMailAnsprechpartner != null && lastclickedAuftrag.eMailAnsprechpartner.length > 0" pTooltip="E-Mail" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.eMailAnsprechpartner != null" href="mailto:{{lastclickedAuftrag.eMailAnsprechpartner}}">{{lastclickedAuftrag.eMailAnsprechpartner}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag.eMailAnsprechpartner != null" style="cursor: pointer" (click)="eMailClicked(lastclickedAuftrag.eMailAnsprechpartner)">{{lastclickedAuftrag.eMailAnsprechpartner}}</a>
            </div>

            <!-- nächste: Ansprechpartner Versorger -->
            <div>
                <br>
                <i class="fa-solid fa-building"></i>
            </div>
            <div>
                <br>
                <!--???-->{{versorgerAnsprechpartnerText}}<!--???--><br>
                <!--{{lastclickedAuftrag.name_2Kunde}}<br *ngIf="lastclickedAuftrag.name_2Kunde != null">-->
                <!--{{lastclickedAuftrag.strasseKunde}}<br>
                {{lastclickedAuftrag.plzKunde}}<br> {{lastclickedAuftrag.ortKunde}}-->
            </div>

            <!-- Überschrift -->
            <div *ngIf="zaehlerEnabled == true" class="details-ueberschrift-left">
                &nbsp;
            </div>
            <div *ngIf="zaehlerEnabled == true" class="details-ueberschrift-right">
                Rechnungsadresse
            </div>
            
            <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">
                <!--<br>-->
                <i class="fa-solid fa-money-bill"></i>
            </div>
            <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'" pTooltip="Kunde" tooltipPosition="left">
                <!--<br>-->
                {{lastclickedAuftrag.name_1Kunde}} {{lastclickedAuftrag.name_2Kunde}}<br>
                {{lastclickedAuftrag.strasseKunde}}<br>
                {{lastclickedAuftrag.plzKunde}} {{lastclickedAuftrag.ortKunde}} {{lastclickedAuftrag.ortzusatzKunde}}
            </div>
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonKunde != null">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonKunde != null" pTooltip="Telefon" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag_Checkliste != null" href="tel:{{lastclickedAuftrag_Checkliste.imAuftragTelefon | phoneCanonical}}">{{lastclickedAuftrag_Checkliste.imAuftragTelefon | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag_Checkliste != null" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag_Checkliste.imAuftragTelefon)">{{lastclickedAuftrag_Checkliste.imAuftragTelefon | phoneCanonical}}</a>
            </div>
            <!-- Tel. geschäftlich -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichKunde != null && lastclickedAuftrag.telefonGeschaeftlichKunde != telefonKunde">
                <i class="fa-solid fa-briefcase"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichKunde != null && lastclickedAuftrag.telefonGeschaeftlichKunde != telefonKunde" pTooltip="Telefon geschäftlich" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonGeschaeftlichKunde | phoneCanonical}}">{{lastclickedAuftrag.telefonGeschaeftlichKunde | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonGeschaeftlichKunde)">{{lastclickedAuftrag.telefonGeschaeftlichKunde | phoneCanonical}}</a>
            </div>
            <!-- Tel. privat -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatKunde != null && lastclickedAuftrag.telefonPrivatKunde != telefonKunde">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatKunde != null && lastclickedAuftrag.telefonPrivatKunde != telefonKunde" pTooltip="Telefon privat" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonPrivatKunde | phoneCanonical}}">{{lastclickedAuftrag.telefonPrivatKunde | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonPrivatKunde)">{{lastclickedAuftrag.telefonPrivatKunde | phoneCanonical}}</a>
            </div>
            <!-- Tel. mobil -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilKunde != null && lastclickedAuftrag.telefonMobilKunde != telefonKunde">
                <i class="fa-solid fa-mobile"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilKunde != null && lastclickedAuftrag.telefonMobilKunde != telefonKunde" pTooltip="Telefon mobil" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonMobilKunde | phoneCanonical}}">{{lastclickedAuftrag.telefonMobilKunde | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonMobilKunde)">{{lastclickedAuftrag.telefonMobilKunde | phoneCanonical}}</a>
            </div>
            <!-- email -->
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailKunde != null">
                <i class="fa-solid fa-envelope"></i>
            </div>
            <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailKunde != null" pTooltip="E-Mail" tooltipPosition="left">
                <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.eMailKunde != null" href="mailto:{{lastclickedAuftrag.eMailKunde}}">{{lastclickedAuftrag.eMailKunde}}</a>
                <a *ngIf="app.inAppMode==true && lastclickedAuftrag.eMailKunde != null" style="cursor: pointer" (click)="eMailClicked(lastclickedAuftrag.eMailKunde)">{{lastclickedAuftrag.eMailKunde}}</a>
            </div>

            <!-- Überschrift -->
            <div *ngIf="zaehlerEnabled == true" class="details-ueberschrift-left">
                &nbsp;
                <div style="font-size: 0.25em;">
                    <br>
                </div>
            </div>
            <div *ngIf="zaehlerEnabled == true" class="details-ueberschrift-right">
                Auftragsdetails
                <div style="font-size: 0.25em;">
                    <br>
                </div>
            </div>
            
            <!-- nächste: Disponiert -->
            <div *ngIf="lastclickedAuftrag.dispodatum != null || lastclickedAuftrag.benutzerId != null">
                <div style="font-size: 0.25em;">
                    <br *ngIf="!(zaehlerEnabled == true)"><!--nur wenn nicht schon Abstand durch Überschrift-->
                </div>
                <i class="fa-regular fa-calendar"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.dispodatum != null || lastclickedAuftrag.benutzerId != null" pTooltip="Disponiert" tooltipPosition="left">
                <div style="font-size: 0.25em;">
                    <br *ngIf="!(zaehlerEnabled == true)"><!--nur wenn nicht schon Abstand durch Überschrift-->
                </div>
                Disponiert: {{lastclickedAuftrag.dispodatum | datumOhneZeit}} {{lastclickedAuftrag.benutzerId | benutzer}}
            </div>
            <div *ngIf="lastclickedAuftrag.wiedervorlage != null" pTooltip="Wiedervorlage" tooltipPosition="left">
                <i class="fa-solid fa-alarm-clock"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.wiedervorlage != null">
                Wiedervorlage: {{lastclickedAuftrag.wiedervorlage | datumOhneZeit:['/noUTC']}}
            </div>

            <!-- nächste: Abstand vor "Aufkleber"/Bemerkung / Notiz -->
            <div *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0 || lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0">
                <br *ngIf="!(zaehlerEnabled == true)"><!--nur wenn nicht schon Abstand durch Überschrift-->
            </div>
            <div *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0 || lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0">
                <br *ngIf="!(zaehlerEnabled == true)"><!--nur wenn nicht schon Abstand durch Überschrift-->
            </div>

            <!-- nächste: "Aufkleber" -->
            <div style="color: red;" *ngIf="getLeistungForAuftrag(lastclickedAuftrag).anstrich == true && aufkleberVertragsartenKeinAufkleber != null && aufkleberVertragsartenKeinAufkleber.length > 0 && lastclickedAuftrag != null">
                <i class="fa-solid fa-circle-info"></i>
            </div>
            <div style="color: red; font-weight: bold;" *ngIf="getLeistungForAuftrag(lastclickedAuftrag).anstrich == true && aufkleberVertragsartenKeinAufkleber != null && aufkleberVertragsartenKeinAufkleber.length > 0 && lastclickedAuftrag != null">
                <!--<small>DEBUG: auftragId:{{lastclickedAuftrag.id}} vertragsartenKEINAufkleber:{{aufkleberVertragsartenKeinAufkleber}} vertragsart:{{lastclickedAuftrag.verwaltungsdaten}} textKEINAufkleber:{{aufkleberTextKeinAufkleber}} textAufkleber:{{aufkleberTextAufkleber}}<br></small>-->
                <span innerHTML="{{aufkleberVertragsartenKeinAufkleber.indexOf('\''+lastclickedAuftrag.verwaltungsdaten+'\'') > -1 ? aufkleberTextKeinAufkleber : aufkleberTextAufkleber}}"></span>
            </div>
            
            <!-- nächste: Bemerkung -->
            <div style="color: red;" *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0">
                <i class="fa-solid fa-circle-info"></i>
            </div>
            <div style="color: red; font-weight: bold;" *ngIf="lastclickedAuftrag.auftragsbemerkung != null && lastclickedAuftrag.auftragsbemerkung.length > 0">
                {{lastclickedAuftrag.auftragsbemerkung}}
            </div>

            <!-- nächste: Notizen -->
            <div *ngIf="lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0 || zaehlerEnabled == true && this.app.inAppMode == false">
                <i *ngIf="lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0" class="fa-solid fa-circle-info"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0 || zaehlerEnabled == true && this.app.inAppMode == false">
                {{lastclickedAuftrag.notizDienstleister}}
                <button *ngIf="zaehlerEnabled == true" pButton type="button" label="{{lastclickedAuftrag.notizDienstleister != null && lastclickedAuftrag.notizDienstleister.length > 0 ? 'Notiz ändern' : 'Notiz'}}" icon="fa-solid fa-edit" iconPos="left" style="float:right" (click)="notizAendernClicked(lastclickedAuftrag)"></button>
            </div>

            <!-- Überschrift -->
            <div *ngIf="zaehlerEnabled == true" class="details-ueberschrift-left">
                &nbsp;
            </div>
            <div *ngIf="zaehlerEnabled == true" class="details-ueberschrift-right">
                Behälterdaten
            </div>
            
            <!-- nächste: Kunden-Nr. / Behälter / Fälligkeit -->
            <div>
                <!--<i class="fa-solid fa-signs-post"></i>-->&nbsp;
            </div>
            <div style="width: 100%">
                
                <div class="" [ngClass]="{'details-grid-tablelike-container': !app.inAppMode, 'details-grid-tablelike-container-inAppMode': app.inAppMode}">
                    <!-- zeile 1: Kunden-Nr. + Beh.Nr. -->
                    <div>
                        Kunden-Nr.:
                    </div>
                    <div>
                        {{lastclickedAuftrag.kundennummer}}
                        {{zaehlerEnabled == true && lastclickedAuftrag.kundenposition != null ? '/ '+lastclickedAuftrag.kundenposition : null}}
                    </div>
                    <div>
                        Behälter-Nr.:
                    </div>
                    <div>
                        {{lastclickedAuftrag.behaelternummer}}
                    </div>
                    <!-- zeile 2: Behälter-BJ. + Inhalt -->
                    <div>
                        Baujahr:
                    </div>
                    <div>
                        {{lastclickedAuftrag.baujahr | datumOhneZeit}}
                    </div>
                    <div>
                        Nettoinhalt:
                    </div>
                    <div>
                        {{lastclickedAuftrag.fuellvolumen}} Liter
                    </div>
                    <!-- zeile 3: Hersteller und Lagerungsart -->
                    <div>
                        Hersteller:
                    </div>
                    <div>
                        {{lastclickedAuftrag.auftragsdatenHersteller.bezeichnung}}
                    </div>

                    <!--Lagerungsart: Variante für Zähler-->
                    <div *ngIf="zaehlerEnabled == true">
                        Lagerungsart:
                    </div>
                    <div *ngIf="zaehlerEnabled == true">
                        {{lagerungsarten[lastclickedAuftrag.lagerungsart] || 'unbekannt'}}
                    </div>
                    <!--Lagerungsart: Variante für Nicht-Zähler-->
                    <div *ngIf="!(zaehlerEnabled == true)">
                        {{lastclickedAuftrag.lagerungsart == '01' ? 'Tankfarbe:' : null}}<div *ngIf="lastclickedAuftrag.lagerungsart == '01'" style="font-size: 0.25em;"><br></div>
                        <p-checkbox *ngIf="lastclickedAuftrag.lagerungsart == '01'" label="oberirdisch" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
                        <p-checkbox *ngIf="!(lastclickedAuftrag.lagerungsart == '01')" label="oberirdisch" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
                    </div>
                    <div *ngIf="!(zaehlerEnabled == true)">
                        {{lastclickedAuftrag.lagerungsart == '01' ? lastclickedAuftrag.tankfarbe : null}}{{lastclickedAuftrag.lagerungsart == '01' ? '&nbsp;' : null}}<div *ngIf="lastclickedAuftrag.lagerungsart == '01'" style="font-size: 0.25em;"><br></div>
                        <p-checkbox *ngIf="lastclickedAuftrag.lagerungsart >= '02'" label="erdgedeckt" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
                        <p-checkbox *ngIf="!(lastclickedAuftrag.lagerungsart >= '02')" label="erdgedeckt" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
                    </div>

                    <!-- zeile 4: Pumpentyp
                    <div>
                        Pumpentyp:
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>-->
                    <!-- zeile 5: Fälligkeitsdatum / Vertragart -->
                    <div>
                        Fälligkeitsdatum:
                    </div>
                    <div>
                        {{lastclickedAuftrag.endedatum | datumOhneZeit}}
                    </div>
                    <!-- div immer anzeigen, aber nur bei Z mit Inhalt, sonst empty div -->
                    <div>
                        <span [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">Vertragsart:</span>
                    </div>
                    <div>
                        <span [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">{{lastclickedAuftrag.vertragartbezeichnung}}</span>
                    </div>
                    <!-- weitere DIVS nur bei Z -->
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">
                        <p-checkbox *ngIf="lastclickedAuftrag.terminabspracheerforderlich == '1'" label="Terminabsprache" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
                        <p-checkbox *ngIf="!(lastclickedAuftrag.terminabspracheerforderlich == '1')" label="Terminabsprache" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
                    </div>
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">
                        <p-checkbox *ngIf="lastclickedAuftrag.technischerLieferstopp == 'J'" label="Technischer Stop" [ngModel]="true" readonly="true" [binary]="true"></p-checkbox>
                        <p-checkbox *ngIf="!(lastclickedAuftrag.technischerLieferstopp == 'J')" label="Technischer Stop" [ngModel]="false" readonly="true" [binary]="true"></p-checkbox>
                    </div>
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">
                        Absatz in KG:
                    </div>
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">
                        {{lastclickedAuftrag.absatzKg}}
                    </div>

                    <!--  -->
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">
                        letzte ÄP:
                    </div>
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'" pTooltip="letzte äußere Prüfung" tooltipPosition="left">
                        {{lastclickedAuftrag.letzteAeusserePruefung | datumOhneZeit}}
                    </div>
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'">
                        letzte IP:
                    </div>
                    <div [style.display]="zaehlerEnabled == true ? 'unset' : 'none'" pTooltip="letzte innere Prüfung" tooltipPosition="left">
                        {{lastclickedAuftrag.letzteInnerePruefungAnlagenpruefung | datumOhneZeit}}
                    </div>
                </div>

            </div>

            <!-- die folgenden Felder nur, wenn Z -->
            <div class="details-grid-container" style="margin-top: 10px;" *ngIf="zaehlerEnabled == true">
                <!-- Überschrift -->
                <div *ngIf="lastclickedAuftrag.fernabfragegeraet != null || lastclickedAuftrag.fernabfragegeraet != null" class="details-ueberschrift-left">
                    &nbsp;
                </div>
                <div *ngIf="lastclickedAuftrag.fernabfragegeraet != null || lastclickedAuftrag.fernabfragegeraet != null" class="details-ueberschrift-right">
                    Fernabfrage
                </div>

                <div *ngIf="lastclickedAuftrag.fernabfragegeraet != null || lastclickedAuftrag.fernabfragegeraet != null">
                    <div style="font-size: 0.25em;">
                        <br>
                    </div>
                    <i class="fa-regular fa-wifi"></i>
                </div>
                <div *ngIf="lastclickedAuftrag.fernabfragegeraet != null || lastclickedAuftrag.fernabfragegeraet != null" pTooltip="Tamo / Fernabfragegerät" tooltipPosition="left">
                    <div style="font-size: 0.25em;">
                        <br>
                    </div>
                    {{lastclickedAuftrag.fernabfragegeraet | phoneCanonical}}
                </div>

                <!-- -->
                <!-- Überschrift -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerStandort != null" class="details-ueberschrift-left">
                   &nbsp;
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerStandort != null" class="details-ueberschrift-right">
                    Zählerstandort
                </div>

                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerStandort != null">
                    <i class="fa fa-watch-calculator"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerStandort != null" pTooltip="Zähler-Standort" tooltipPosition="left">
                    {{lastclickedAuftrag.name_1ZaehlerStandort}} {{lastclickedAuftrag.name_2ZaehlerStandort}}<br>
                    {{lastclickedAuftrag.strasseZaehlerStandort}}<br>
                    {{lastclickedAuftrag.plzZaehlerStandort}} {{lastclickedAuftrag.ortZaehlerStandort}} {{lastclickedAuftrag.ortzusatzZaehlerStandort}}
                </div>
                <!-- nächste -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonZaehlerStandort != null">
                    <i class="fa-solid fa-phone"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonZaehlerStandort != null" pTooltip="Telefon" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonZaehlerStandort | phoneCanonical}}">{{lastclickedAuftrag.telefonZaehlerStandort | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonZaehlerStandort)">{{lastclickedAuftrag.telefonZaehlerStandort | phoneCanonical}}</a>
                </div>
                <!-- Tel. geschäftlich -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort != null && lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort != telefonZaehlerStandort">
                    <i class="fa-solid fa-briefcase"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort != null && lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort != telefonZaehlerStandort" pTooltip="Telefon geschäftlich" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort | phoneCanonical}}">{{lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort)">{{lastclickedAuftrag.telefonGeschaeftlichZaehlerStandort | phoneCanonical}}</a>
                </div>
                <!-- Tel. privat -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatZaehlerStandort != null && lastclickedAuftrag.telefonPrivatZaehlerStandort != telefonZaehlerStandort">
                    <i class="fa-solid fa-phone"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatZaehlerStandort != null && lastclickedAuftrag.telefonPrivatZaehlerStandort != telefonZaehlerStandort" pTooltip="Telefon privat" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonPrivatZaehlerStandort | phoneCanonical}}">{{lastclickedAuftrag.telefonPrivatZaehlerStandort | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonPrivatZaehlerStandort)">{{lastclickedAuftrag.telefonPrivatZaehlerStandort | phoneCanonical}}</a>
                </div>
                <!-- Tel. mobil -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilZaehlerStandort != null && lastclickedAuftrag.telefonMobilZaehlerStandort != telefonZaehlerStandort">
                    <i class="fa-solid fa-mobile"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilZaehlerStandort != null && lastclickedAuftrag.telefonMobilZaehlerStandort != telefonZaehlerStandort" pTooltip="Telefon mobil" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonMobilZaehlerStandort | phoneCanonical}}">{{lastclickedAuftrag.telefonMobilZaehlerStandort | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonMobilZaehlerStandort)">{{lastclickedAuftrag.telefonMobilZaehlerStandort | phoneCanonical}}</a>
                </div>
                <!-- eMail -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailZaehlerStandort != null">
                    <i class="fa-solid fa-envelope"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailZaehlerStandort != null" pTooltip="E-Mail" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.eMailZaehlerStandort != null" href="mailto:{{lastclickedAuftrag.eMailZaehlerStandort}}">{{lastclickedAuftrag.eMailZaehlerStandort}}</a>
                    <a *ngIf="app.inAppMode==true && lastclickedAuftrag.eMailZaehlerStandort != null" style="cursor: pointer" (click)="eMailClicked(lastclickedAuftrag.eMailZaehlerStandort)">{{lastclickedAuftrag.eMailZaehlerStandort}}</a>
                </div>

                <!-- -->
                <!-- Überschrift -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerKunde != null" class="details-ueberschrift-left">
                    &nbsp;
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerKunde != null" class="details-ueberschrift-right">
                    Rechnungsadresse
                </div>
                
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerKunde != null">
                    <i class="fa fa-watch-calculator"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.name_1ZaehlerKunde != null" pTooltip="Zähler-Kunde" tooltipPosition="left">
                    {{lastclickedAuftrag.name_1ZaehlerKunde}} {{lastclickedAuftrag.name_2ZaehlerKunde}}<br>
                    {{lastclickedAuftrag.strasseZaehlerKunde}}<br>
                    {{lastclickedAuftrag.plzZaehlerKunde}} {{lastclickedAuftrag.ortZaehlerKunde}} {{lastclickedAuftrag.ortzusatzZaehlerKunde}}
                </div>
                <!-- nächste -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonZaehlerKunde != null">
                    <i class="fa-solid fa-phone"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonZaehlerKunde != null" pTooltip="Telefon" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonZaehlerKunde | phoneCanonical}}">{{lastclickedAuftrag.telefonZaehlerKunde | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonZaehlerKunde)">{{lastclickedAuftrag.telefonZaehlerKunde | phoneCanonical}}</a>
                </div>
                <!-- Tel. geschäftlich -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde != null && lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde != telefonZaehlerKunde">
                    <i class="fa-solid fa-briefcase"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde != null && lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde != telefonZaehlerKunde" pTooltip="Telefon geschäftlich" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde | phoneCanonical}}">{{lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde)">{{lastclickedAuftrag.telefonGeschaeftlichZaehlerKunde | phoneCanonical}}</a>
                </div>
                <!-- Tel. privat -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatZaehlerKunde != null && lastclickedAuftrag.telefonPrivatZaehlerKunde != telefonZaehlerKunde">
                    <i class="fa-solid fa-phone"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonPrivatZaehlerKunde != null && lastclickedAuftrag.telefonPrivatZaehlerKunde != telefonZaehlerKunde" pTooltip="Telefon privat" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonPrivatZaehlerKunde | phoneCanonical}}">{{lastclickedAuftrag.telefonPrivatZaehlerKunde | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonPrivatZaehlerKunde)">{{lastclickedAuftrag.telefonPrivatZaehlerKunde | phoneCanonical}}</a>
                </div>
                <!-- Tel. mobil -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilZaehlerKunde != null && lastclickedAuftrag.telefonMobilZaehlerKunde != telefonZaehlerKunde">
                    <i class="fa-solid fa-mobile"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.telefonMobilZaehlerKunde != null && lastclickedAuftrag.telefonMobilZaehlerKunde != telefonZaehlerKunde" pTooltip="Telefon mobil" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonMobilZaehlerKunde | phoneCanonical}}">{{lastclickedAuftrag.telefonMobilZaehlerKunde | phoneCanonical}}</a>
                    <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonMobilZaehlerKunde)">{{lastclickedAuftrag.telefonMobilZaehlerKunde | phoneCanonical}}</a>
                </div>
                <!-- eMail -->
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailZaehlerKunde != null">
                    <i class="fa-solid fa-envelope"></i>
                </div>
                <div *ngIf="zaehlerEnabled == true && lastclickedAuftrag.eMailZaehlerKunde != null" pTooltip="E-Mail" tooltipPosition="left">
                    <a *ngIf="app.inAppMode!=true && lastclickedAuftrag.eMailZaehlerKunde != null" href="mailto:{{lastclickedAuftrag.eMailZaehlerKunde}}">{{lastclickedAuftrag.eMailZaehlerKunde}}</a>
                    <a *ngIf="app.inAppMode==true && lastclickedAuftrag.eMailZaehlerKunde != null" style="cursor: pointer" (click)="eMailClicked(lastclickedAuftrag.eMailZaehlerKunde)">{{lastclickedAuftrag.eMailZaehlerKunde}}</a>
                </div>

            </div>

        </div>

        <div *ngIf="zaehlerEnabled == true && (lastclickedAuftrag.zaehlerKundennummer != null || lastclickedAuftrag.zaehlernummer != null)" class="details-grid-container">
            <div class="details-ueberschrift-left">
                <!--icon?-->&nbsp;
            </div>
            <div class="details-ueberschrift-right">
                Zählerdaten
            </div>

            <div>
                <!--icon?-->&nbsp;
            </div>
            <div style="width: 100%">
                
                <div class="" [ngClass]="{'details-grid-tablelike-container': !app.inAppMode, 'details-grid-tablelike-container-inAppMode': app.inAppMode}">
                    <!-- zeile 1: Kunden-Nr. + Beh.Nr. -->
                    <div>
                        Zähler-Kunden-Nr.:
                    </div>
                    <div>
                        {{lastclickedAuftrag.zaehlerKundennummer}} {{zaehlerEnabled == true && lastclickedAuftrag.zaehlerKundenposition != null ? '/ '+lastclickedAuftrag.zaehlerKundenposition : null}}
                    </div>
                    <div>
                        Zähler-Nr.:
                    </div>
                    <div>
                        {{lastclickedAuftrag.zaehlernummer}}
                    </div>
                    <!-- nä. zeile -->
                    <div>
                        Baujahr:
                    </div>
                    <div>
                        {{lastclickedAuftrag.zaehlerbaujahr | datumOhneZeit}}
                    </div>
                    <div>
                        Zaehlergröße:
                    </div>
                    <div>
                        {{lastclickedAuftrag.zaehlergroesse}}
                    </div>
                    <!-- nä. zeile -->
                    <div>
                        Hersteller:
                    </div>
                    <div>
                        {{lastclickedAuftrag.zaehlerhersteller}}
                    </div>
                    <div>
                        Bauart:
                    </div>
                    <div>
                        {{lastclickedAuftrag.zaehlerbauart}}
                    </div>
                    <!-- nä. zeile -->
                    <div>
                        Eichdatum:
                    </div>
                    <div>
                        {{lastclickedAuftrag.zaehlereichdatum | datumOhneZeit}}
                    </div>
                    <div>
                        &nbsp;
                    </div>
                    <div>
                        &nbsp;
                    </div>

                </div>
            </div>

        </div>


    </ng-container>
    <ng-template pTemplate="footer">
        <br>
        <button pButton *ngIf="debugMode==true" type="button" label="dbg" icon="fa-solid fa-debug" iconPos="left" class="p-button-warning" style="float:left" (click)="debugLastClickedAuftrag()"></button>
        <button pButton type="button" label="Aufgabe starten" icon="fa-solid fa-clipboard-list" iconPos="left" class="p-button-success" style="float:left" (click)="aufgabeStartenClicked(lastclickedAuftrag.id)"></button>

        <button pButton type="button" label="Navigation" icon="fa-solid fa-diamond-turn-right" iconPos="left" (click)="navigationClicked(lastclickedAuftrag.id)"></button>

        <button pButton *ngIf="app.inAppMode != true && benutzer != null && benutzer.istAdmin == true" type="button" label="Disponieren" icon="fa-regular fa-calendar" iconPos="left" (click)="app.dispositionStart(lastclickedAuftrag.id)"></button>

        <!-- button mit stacked icons -->
        <button pButton style="padding: 0.25em; padding-right: 1em;"
        *ngIf="app.inAppMode != true && lastclickedAuftrag_in_Routenplanung != null && lastclickedAuftrag_in_Routenplanung == true" type="button" label="{{benutzer != null && benutzer.istAdmin == true ? 'Markierung aufheben' : 'Route entfernen'}}" iconPos="left" (click)="demarkierenClicked(lastclickedAuftrag.id)">
            <span class="fa-stack">
              <i class="fa-solid fa-route fa-stack-1x"></i>
              <i class=" fa-solid fa-slash fa-stack-1x"></i>
            </span>
        </button>

        <button pButton *ngIf="app.inAppMode != true && lastclickedAuftrag_in_Routenplanung != null && lastclickedAuftrag_in_Routenplanung == false" type="button" label="{{benutzer != null && benutzer.istAdmin == true ? 'Auftrag markieren' : 'Route hinzufügen'}}" icon="fa-solid fa-route" iconPos="left" (click)="markierenClicked(lastclickedAuftrag.id)"></button>

        <!--
            AEP can-food
            IP  bullseye-pointer
            allg Reparatur toolbox
            Anstrich brush
            Rohrleitungsprüfung pipe-circle-check
            Sicherheitsventil pipe-valve
            Verdampfer droplet
            Wartung screwdriver-wrench
        -->
        <!-- Versuch, AEP aus 2 Icons zusammenzusetzen
        <span class="fa-stack fa-2x">
            <i class="fa fa-map-marker fa-stack-2x" style="color: #08f; font-size: 2em;"></i>

            <i class="fa fa-thin fa-can-food fa-stack-1x" style="color: white; font-size: 1em; margin-left: 1.25px; margin-top: -2.25px;"></i>

            <i class="fa fa-can-food fa-stack-1x" style="color: white; font-size: 0.75em; margin-left: 1px; margin-top: -2px;"></i>
        </span>     
        -->
        
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="dialogEditNotizShow" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="details-grid-container">
            <!-- zeile 1 -->
            <div>
                <i class="fa fa-{{ getLeistungForAuftrag(lastclickedAuftrag).icon }} fa-xl"></i>
            </div>
            <div>
                <big>{{ getLeistungForAuftrag(lastclickedAuftrag).bezeichnung }}:</big>
            </div>
        </div>
    </ng-template>
    <ng-container *ngIf="lastclickedAuftrag != null">
        <div class="details-grid-container">
            <!-- zeile 1: Behälterstandort -->
            <div>
                <i class="fa fa-location"></i>
            </div>
            <div>
                {{lastclickedAuftrag.name_1Behaelterstandort}} {{lastclickedAuftrag.name_2Behaelterstandort}}<br>
                {{lastclickedAuftrag.strasseBehaelterstandort}}<br>
                {{lastclickedAuftrag.plzBehaelterstandort}} {{lastclickedAuftrag.ortBehaelterstandort}} {{lastclickedAuftrag.ortzusatzBehaelterstandort}}
            </div>
            <!-- nächste -->
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null">
                <i class="fa-solid fa-phone"></i>
            </div>
            <div *ngIf="lastclickedAuftrag.telefonBehaelterstandort != null">
                <a *ngIf="app.inAppMode!=true" href="tel:{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
                <a *ngIf="app.inAppMode==true" style="cursor: pointer" (click)="phoneNrClicked(lastclickedAuftrag.telefonBehaelterstandort)">{{lastclickedAuftrag.telefonBehaelterstandort | phoneCanonical}}</a>
            </div>
        </div>

        <div class="details-grid-container" style="margin-top: 10px;">
            <!-- nächste -->
            <div>
                <i class="fa-solid fa-circle-info"></i>
            </div>
            <div>
                <span class="p-float-label">
                    <textarea #bemerkungPos style="width:100%; padding-top: 0.5em; padding-bottom: 0.5em;" [rows]="4" pInputTextarea 
                    [(ngModel)]="lastclickedAuftrag.notizDienstleister"
                    ></textarea><!--kein autoResize="autoResize" sonst optisches Problem beim wieder-reinkommen-->
                    <label>Notiz</label>
                </span>
            </div>
        </div>

    </ng-container>
    <ng-template pTemplate="footer">
        <br>
        <button pButton type="button" label="Speichern" iconPos="left" (click)="notizSpeichernClicked()"></button>
    </ng-template>
</p-dialog>
