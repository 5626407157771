import { Component, OnInit, AfterViewInit, ViewChild, Input, Output, EventEmitter, SimpleChange, SimpleChanges, Inject, forwardRef, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { MessageWrapperService } from '../_services/message-wrapper.service';
import * as moment from 'moment';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'crud-basic-input-calendar',
  templateUrl: './crud-basic-input-calendar.component.html',
  styleUrls: ['./crud-basic-input-calendar.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CRUDBasicInputCalendarComponent),
      multi: true
    }
  ]
})
export class CRUDBasicInputCalendarComponent implements AfterViewInit, ControlValueAccessor {
  // die Komponente kann direkt formControlName arbeiten:
  //       https://alligator.io/angular/custom-form-control/
  //       https://netbasal.com/angular-custom-form-controls-made-easy-4f963341c8e2

  // Parameter, die 1:1 an p-calendar weitergegeben werden:
  @Input('dateFormat') dateFormat: string;
  @Input('showTime') showTime: boolean;
  @Input('showSeconds') showSeconds: boolean;
  @Input('utc') utc: boolean;
  @Input('stepMinute') stepMinute: string;
  @Input('defaultDate') defaultDate: Date;
  @Input('inputStyle') inputStyle: string;
  @Input('style') style: string;
  @Input('noTextInput') noTextInput: boolean;

  @ViewChild('calendar', { static: true }) public calendar: Calendar;

  value: any = null;

  onChange_callbackFunction: any; // bekommt die function von Angular forms - wenn formControlName - die bei Änderung gecallt werden muss.
  onChange_lastEditValue: any; // enthält den jeweils zuletzt rückgemeldeten Wert - als Vergleich/Abfangmechanismus, um nicht unnötig oft zu callen
  onChange_lastEditValueKnown: boolean = false; 
  //onTouch_callbackFunction : any; // bekommt die function von Angular forms - wenn formControlName - die bei Touch gecallt werden muss.

  enableInputHelper: boolean = true;
  datePickerVisible: boolean = false;
  inputField: any;

  /*DE: any = { // aktivieren: beim p-calendar einstellen: [locale]="DE"
  firstDayOfWeek: 1,
  dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  dayNamesShort: ["Son", "Mon", "Din", "Mit", "Don", "Fre", "Sam"],
  dayNamesMin: ["So","Mo","Di","Mi","Do","Fr","Sa"],
  monthNames: [ "Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember" ],
  monthNamesShort: [ "Jan", "Feb", "Mär", "Apr", "Mai", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ],
  today: 'Heute',
  clear: 'Clear',
  dateFormat: 'dd/mm/yy',
  weekHeader: 'Wk'
  }*/
  
  constructor(
    @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
    private translate: TranslateService,
    private messageWrapperService: MessageWrapperService
  ) {
    //console.log("CRUDBasicInputComponent.constructor() globalService.getUser()", this.globalService.getUser());
  }

  ngAfterViewInit(): void {
    console.log("CRUDBasicInputCalendarComponent.ngAfterViewInit() this.calendar:", this.calendar)

    // dafür sorgen, dass die p-autocomplete nicht in ein <span ng_content_xxx> verpackt wird, sonst
    // funktionieren die ganzen Styles nicht! Gelöst durch replace "span" by "child"
    // saubere Lösung könnte sein:
    // "containerless components" - dazu gibt es jede Menge feature-requests bei angular (ging wohl mit AngularJS "replace")
    // :host { display: contents; }   -> funktioniert aber (noch???) nicht, ist ein experimental feature in chrome

    //let calendarElement = this.calendar['nativeElement'];
    let calendarElement = this.calendar['el']['nativeElement'];
    let parentNode = calendarElement.parentNode;
    let parentparentNode = parentNode.parentNode;
    
    //console.log("CRUDBasicInput.ngOnInit() inputWriteableElement:", inputWriteableElement);
    //console.log("CRUDBasicInput.ngOnInit() inputFormattedElement:", inputFormattedElement);
    //console.log("CRUDBasicInput.ngOnInit() parentNode:", parentNode);
    //console.log("CRUDBasicInput.ngOnInit() parentparentNode:", parentparentNode);

    setTimeout(() => {
      //console.log("CRUDBasicInput.switchToWriteable() replacing parentNode...");
  
      while (parentNode.childNodes.length > 0) {
        //console.log("CRUDBasicInput.switchToWriteable() replacing:", parentNode.childNodes[0]);
        let node = parentNode.childNodes[0]
        let isIE = false || !!document['documentMode'];
        if (isIE == true) parentparentNode.insertBefore(parentNode.childNodes[0], parentparentNode.childNodes[0]); // IE!
        else parentparentNode.prepend(parentNode.childNodes[0]); // nicht IE!
      }
      //parentparentNode.remove(parentNode); // warum auch immer, aber damit verschwinden auch die bereits verschobenen childs

      //console.log("CRUDBasicInput.ngOnInit() parentparentNode:", parentparentNode);
    }, 50);
  }
  
  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  //ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
  //  console.log("CRUDBasicInput.ngOnChanges():", changes);
  //}

  // aus ControlValueAccessor Interface: patchValue/setValue/new FormControl soll Wert im HTML aktualisieren
  writeValue(obj: any): void {
    //console.log("CRUDBasicInput.writeValue():", obj);
    //this.inputWriteableControl.writeValue(obj);
    this.value = obj;

    //this.lastEditValue = this.value;
    //this.lastEditValueKnown = true;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onChange" aufrufen sollen.
  // die zunächst NUR MERKEN, ggf. rufen wir die (siehe onChange()) auf
  registerOnChange(fn: (rating: number) => void): void {
    // console.log("CRUDBasicInput.registerOnChange() fn:", fn);
    this.onChange_callbackFunction = fn;
  }

  // Angular forms sendet uns eine Referenz auf eine Funktion, die wir "onTouch" aufrufen sollen. 
  registerOnTouched(fn: () => void): void {
    // console.log("CRUDBasicInput.registerOnTouched() fn:", fn);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    //this.inputWriteableControl.registerOnTouched(fn);
    //this.onTouch_callbackFunction = fn;
  }

  // Angular forms ruft diese Funktion, wenn sich der disabled-Status ändert.
  setDisabledState(isDisabled: boolean): void {
    // console.log("CRUDBasicInput.setDisabledState() isDisabled:", isDisabled);
    // Vermutlich reicht es aus, das 1:1 an p-autoComplete weiterzugeben, da p-autoComplete bereits formControlName unterstützt
    //this.inputWriteableControl.setDisabledState(isDisabled);
    //this.inputFormattedControl.setDisabledState(isDisabled);
  }

  //https://ngdev.space/angular-2-input-property-changes-detection-3ccbf7e366d2
  /*ngOnChanges(changes: SimpleChanges) { // monitoring Input-Changes - nur monitoring/debug - ohne weitere Funktion!!! (kann man weglassen!)
    if(this.debugMode==true) console.log("CRUDBasicInput.ngOnChanges():", changes);

    if(changes.value != null) {
      if(this.debugMode==true) console.log("CRUDBasicInput.ngOnChanges().it's value!");
    }
    else {
      if(this.debugMode==true) console.log("CRUDBasicSelectComponent.ngOnChanges().something else changed.");      
    }
  }*/

  onChange() {
    //console.log("CRUDBasicInput.onChange() event:", event);
    //console.log("CRUDBasicInput.onChange() this.value:", this.value);
    if (this.value != this.onChange_lastEditValue || this.onChange_lastEditValueKnown == false) { // nur wenn der Wert != dem zuletzt gemeldeten Wert ist (doppel-Rückmeldungen vermeiden! Performance!)

      let callBackValue : any = null;
      callBackValue = this.value;

      //console.log("CRUDBasicInput.onChange() calling callback - value:", callBackValue);
      this.onChange_callbackFunction(callBackValue);
      this.onChange_lastEditValue = this.value;
      this.onChange_lastEditValueKnown = true;
    }
    else {
      //console.log("CRUDBasicInput.onChange() skip, since it's the same option as last time!");
    }
  }

  //handleSelect(event: any/*, control: any*/) {
    //console.log("CRUDBasicDetail.CRUDBasicHandleDateFormat() control:", control);
    //console.log("CRUDBasicDetail.CRUDBasicHandleDateFormat() control.parent:", control.parent);
    //console.log("CRUDBasicInputCalendar.handleSelect() event:", event);

    //this.value=new Date(event);
    //this.onChange();
  //}

  onFocus(event: any/*, control: any*/) {
    //console.log("CRUDBasicInputCalendar.onFocus() event:", event);
    this.datePickerVisible = true; // US 15146 eigentlich ist das falsch! Nur der onShow() sollte datePickerVisible auf true setzen
                                   // aber der onShow() triggert einfach nicht! vermutlich ein primeNg bug!
                                   // Notlösung: wir gehen davon aus, dass beimn focusieren auch der datePicker visible wird - ist ja normalerweise so!
    this.inputField = event.srcElement;
  }

  onShow(event: any/*, control: any*/) {
    // US 15146 der onShow() triggert einfach nicht! vermutlich ein primeNg bug! Notlösung: Siehe onFocus()
    //console.log("CRUDBasicInputCalendar.onShow() event:", event);
    this.datePickerVisible = true;
  }

  onClose(event: any/*, control: any*/) {
    //console.log("CRUDBasicInputCalendar.onClose() event:", event);
    if(this.datePickerVisible == true) {
      //console.log("CRUDBasicInputCalendar.onClose() calling handleDateFormat since datePicker is visible!");
      this.handleDateFormat(/*event*/this.inputField.value);
    }
    else {
      //console.log("CRUDBasicInputCalendar.onClose() NOT calling handleDateFormat since datePicker is NOT visible!");
      return null;
    }
  }

  onBlur(event: any/*, control: any*/) {
    //console.log("CRUDBasicInputCalendar.onBlur() event:", event);
    if(this.datePickerVisible == false) {
      //console.log("CRUDBasicInputCalendar.onBlur() calling handleDateFormat since datePicker is NOT visible!");
      this.handleDateFormat(/*event*/event.srcElement.value);
    }
    else {
      //console.log("CRUDBasicInputCalendar.onBlur() NOT calling handleDateFormat since datePicker is visible!");
      return null;
    }
  }

  handleDateFormat(/*event: any*//*, control: any*/newValue:any) {
    //console.log("CRUDBasicInputCalendar.handleDateFormat() event:", event);
    //console.log("CRUDBasicInputCalendar.handleDateFormat() calendar:", this.calendar);
    //try {
    //  console.log("CRUDBasicInputCalendar.handleDateFormat() newValue:", newValue);
    //}
    //catch(e) {
    //}
    if(this.enableInputHelper == false) {
      console.log("CRUDBasicInputCalendar.handleDateFormat() NO enableInputHelper!, so skip!");
    }
    else {
      let newDate = /*event.srcElement.value*/newValue,
      newDay,
      newMonth,
      newYear,
      mFormatDate;
      
    if (newDate.length == 4 && newDate.indexOf('.') == -1) { // 4 Zeichen + 0 Punkte = D.M.YY

      newDay = newDate.substr(0, 1);
      newMonth = newDate.substr(1, 1);
      newYear = newDate.substr(2, 2);

      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "D/M/YY");

    } else if (newDate.length == 5 && newDate.indexOf('.') == -1) { // 5 Zeichen + 0 Punkte = D.MM.YY

      newDay = newDate.substr(0, 1);
      newMonth = newDate.substr(1, 2);
      newYear = newDate.substr(3, 2);
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "D/MM/YY");

    } else if (newDate.length == 6 && newDate.indexOf('.') == -1) { // 6 Zeichen + 0 Punkte = DD.MM.YY

      newDay = newDate.substr(0, 2);
      newMonth = newDate.substr(2, 2);
      newYear = newDate.substr(4, 2);
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "DD/MM/YY");

    } else if (newDate.length == 7 && newDate.indexOf('.') == -1) { // 7 Zeichen + 0 Punkte = D.MM.YYYY

      newDay = newDate.substr(0, 1);
      newMonth = newDate.substr(1, 2);
      newYear = newDate.substr(3, 4);
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "D/MM/YYYY");

    } else if (newDate.length == 8 && newDate.indexOf('.') == -1) { // 8 Zeichen + 0 Punkte = DD.MM.YYYY

      newDay = newDate.substr(0, 2);
      newMonth = newDate.substr(2, 2);
      newYear = newDate.substr(4, 4);
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "DD/MM/YYYY");

    } else if (newDate.length == 6 && newDate.indexOf('.') != -1) { // 4 Zeichen + 2 Punkte = D.MM.YY

      newDay = newDate.substr(0, 1);
      newMonth = newDate.substr(2, 1);
      newYear = newDate.substr(4, 2);
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "D/M/YY");

    } else if (newDate.length == 7 && newDate.indexOf('.') != -1) { // 5 Zeichen + 2 Punkte = D.MM.YY | DD.M.YYYY
      var pktCheck = newDate.split(".");
      if (pktCheck[0].length == 1 && pktCheck[1].length == 2) {
        newDay = newDate.substr(0, 1);
        newMonth = newDate.substr(2, 2);
        newYear = newDate.substr(5, 2);
      } else if (pktCheck[0].length == 2 && pktCheck[1].length == 1) {
        newDay = newDate.substr(0, 2);
        newMonth = newDate.substr(3, 1);
        newYear = newDate.substr(5, 2);
      }
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "D/MM/YY");

    } else if (newDate.length == 8 && newDate.indexOf('.') != -1) { // 6 Zeichen + 2 Punkte = DD.MM.YY

      newDay = newDate.substr(0, 2);
      newMonth = newDate.substr(3, 2);
      newYear = newDate.substr(6, 2);
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "DD/MM/YY");

    } else if (newDate.length == 9 && newDate.indexOf('.') != -1) { // 7 Zeichen + 2 Punkte = D.MM.YYYY | DD.M.YYYY

      var pktCheck = newDate.split(".");
      if (pktCheck[0].length == 1 && pktCheck[1].length == 2) {
        newDay = newDate.substr(0, 1);
        newMonth = newDate.substr(2, 2);
        newYear = newDate.substr(5, 4);
      } else if (pktCheck[0].length == 2 && pktCheck[1].length == 1) {
        newDay = newDate.substr(0, 2);
        newMonth = newDate.substr(3, 1);
        newYear = newDate.substr(5, 4);
      }
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "D/MM/YYYY");

    } else if (newDate.length == 10 && newDate.indexOf('.') != -1) { // 8 Zeichen + 2 Punkte = DD.MM.YYYY

      newDay = newDate.substr(0, 2);
      newMonth = newDate.substr(3, 2);
      newYear = newDate.substr(6, 4);
      mFormatDate = moment((newDay + "." + newMonth + "." + newYear), "DD/MM/YYYY");

    }
    if (mFormatDate != null && mFormatDate._isValid) {
      //control.parent.patchValue({     
      //  datum: new Date(mFormatDate)/*.format("DD.MM.YYYY")*/
      //});
      //control.setValue(new Date(mFormatDate));
      this.value=new Date(mFormatDate);
      this.onChange();
      //event.target.value = new Date(mFormatDate)/*.format("DD.MM.YYYY")*/;
    } else {
      //this.CRUDForm.patchValue({     
      //  datum: null
      //});
      //control.setValue(null);
      this.value=null;
      this.onChange();
      //event.target.value = null;
    }
    }
  }

  handleError(error: any) {
    //this.loading = false;
    //this.blockedDocument = false;

    let errorMessage: string = "";

    let summary = this.translate.instant('Fehler', true);

    if (error.status === 422) {
      summary += ' (422)';
      if (error != null) {
        errorMessage = error.error.Concurrency || error.error.DbUpdateException || error.error.Error || 'Server Error';
      }
      else {
        errorMessage = "Server Error";
      }
    }
    else if (error.status === 401) {
      summary += ' (401)';
      errorMessage = "Unauthorized";
      //this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
    }
    else {
      errorMessage = error.message;
    }

    this.messageWrapperService.postStaticMessage({ severity: 'error', summary: summary, detail: errorMessage });
  }
}
