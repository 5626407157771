import { /*CRUDBasicDetailComponent_Template, */CRUDBasicDetailComponent } from '../crud-basic-detail/crud-basic-detail.component';

import { Component, OnInit, Inject, forwardRef, Injector, ViewChild, Input } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder, AbstractControl, FormControlName } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '../_services/translate.service';
import { GenericValidator } from '../_helpers/generic-validator';
//import { AutoComplete } from 'primeng/primeng';

import { IDokumente } from '../_interfaces/dokumente';
import { DokumenteService } from '../_services/dokumente.service';
import { DokumentDetailGuard } from './dokument-detail.guard';
import { _DateiService } from '../_services/_datei.service';
import { I_Datei } from '../_interfaces/_datei';
import { DokumentDateiUploadComponent } from '../dokument-datei-upload/dokument-datei-upload.component';
import * as cloneDeep from 'lodash/cloneDeep'; // DateTimeOffset-Fix

//import { DokumentDetailComponent_Template } from './dokument-detail.component.include_template';

@Component({
  selector: 'app-dokument-detail',
  //template: `${CRUDBasicDetailComponent_Template || ''}${DokumentDetailComponent_Template}`,
  templateUrl: './dokument-detail.component.html',
  styleUrls: ['../crud-basic-detail/crud-basic-detail.component.css'],
  host: { '(window:keydown)': 'hotkeys($event)' }
})
export class DokumentDetailComponent extends CRUDBasicDetailComponent implements OnInit {
  @ViewChild('dokument') dokument: DokumentDateiUploadComponent;

  // CHILD-spezifisch: Konstanten - START
  CRUDItemKurzform: string = "Dokumente";
  CRUDPageTitleNeu: string = this.translate.instant("Neues Dokument", true);
  CRUDPageTitleBearbeiten: string = this.translate.instant("Dokument", true);
  CRUDItemBezeichnungSingularCapitalized: string = "Dokument";
  CRUDItemBezeichnungPluralCapitalized: string = "Dokumente";
  CRUDItemBezeichnungSingular: string = "dokument";
  CRUDItemBezeichnungPlural: string = "dokumente";
  CRUDItemRouteSingular: string = "dokument";
  CRUDItemRoutePlural: string = "dokumente";
  CRUDItemHelpTopic: string = null;

  //debugMode: boolean = true;
  // CHILD-spezifisch: Konstanten - Ende

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - START

  datei: I_Datei = null;
  dokumentValueChangeSubscriptionActive = true;
  dokumentValueBefore: I_Datei = null;

  typValueChangeSubscriptionActive = true;
  typValueBefore: string = null;

  // CHILD-spezifisch: zusätzliche Widgets (ausser Standard Inputs, Autocomplete) - ENDE

  constructor(
    @Inject( forwardRef(() => AppComponent)) public _app: AppComponent,
    private _injector: Injector,
    private _translate: TranslateService,
    private _crudItemService: DokumenteService,
    private _guard: DokumentDetailGuard,
    private _dateiService: _DateiService,

    // CHILD-spezifisch: zusätzliche Services - START
    // CHILD-spezifisch: zusätzliche Services - ENDE
  ) {
    super(_app, _injector);

    this.dokumentValueBefore = _dateiService.initialize_Datei();

    this.crudItemService = _crudItemService;
    this.guard = _guard;

    // CHILD-spezifisch die Validator Messages bestücken - START
    this.validationMessages =
    {
      dokument: {
        required: this._translate.instant('Dokument', true) + ': ' + this._translate.instant('ist erforderlich', true),
        maxlength: this._translate.instant('Dokument', true) + ' ' + this._translate.instant('darf 0 Zeichen nicht überschreiten', true)
      },

      bezeichnung: {
        maxlength: this._translate.instant('Bezeichnung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true),
        required: this._translate.instant('Bezeichnung', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      beschreibung: {
        maxlength: this._translate.instant('Beschreibung', true) + ' ' + this._translate.instant('darf 255 Zeichen nicht überschreiten', true)
      },

      kopfdaten: {
        required: this._translate.instant('Kopfdaten', true) + ': ' + this._translate.instant('ist erforderlich', true),
      },

      link: {
        maxlength: this._translate.instant('Link', true) + ' ' + this._translate.instant('darf 1000 Zeichen nicht überschreiten', true)
      },
};
    // CHILD-spezifisch die Validator Messages bestücken - ENDE
    
    this.genericValidator = new GenericValidator(this.validationMessages);

    this.buildForm();
  }

  buildForm() {
    // CHILD-spezifisch die Form aufbauen - START
    this.datei = this._dateiService.initialize_Datei();
    this.CRUDForm = this.fb.group({
      dokument: [this.datei, [/*Validators.required,*/ Validators.maxLength(0)]],
      bezeichnung: ['', [Validators.required, Validators.maxLength(255)]],
      beschreibung: ['', [Validators.maxLength(255)]],
      kopfdaten: [null, [/*Validators.required*/]],
      //leitfaden: [null, [/*Validators.required*/]],
      //leitfadenSortierung: [null, [/*Validators.required*/]],
      typ: [null, [/*Validators.required*/]],
      reihenfolge: [null, [/*Validators.required*/]],
      link: ['', [Validators.maxLength(1000)]],
    },{
      validator: this.formValidator // auf FormEbene!
    });

    // value-change-handler! // https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/#valuechanges-of-formcontrol
    this.CRUDForm.get("dokument").valueChanges.subscribe(newValue => {
    console.log("LeistungDetailComponent valueChange dokument: newValue:", newValue);
    //console.log("LeistungDetailComponent valueChange dokument: .get():", this.CRUDForm.get("dokument").value); // entspr. new Value
    console.log("LeistungDetailComponent valueChange dokument: .value:", this.CRUDForm.value.dokument); // enthält OLD Value (sofern nicht innerhalb timeout())
    console.log("LeistungDetailComponent valueChange dokument: dataId:", this.dataId);
    //let oldValue = this.CRUDForm.value.dokument; // funktioniert hier warum auch immer nicht!
    let oldValue = this.dokumentValueBefore;
      if(this.dokumentValueChangeSubscriptionActive == true && this.dataId != 0) {
        if(oldValue != null && oldValue.dateiendung != null && oldValue.dateiendung.length > 0) { // beim Start nicht schon prüfen!
          if((newValue != null ? newValue.dateiendung : "") != (oldValue != null ? oldValue.dateiendung : "")) {
            this.messageWrapperService.postTimedMessage({ severity: 'warn', summary: "Dokument", detail: "Das nachträgliche Ändern des Dokuments kann zu unerwünschtem Ergebnis bei Aufträgen führen, die begonnen, aber noch nicht abgeschlossen sind!" }); 
          }
        }
      }
    });

    // CHILD-spezifisch die Form aufbauen - ENDE
  }

  ngOnInit() {
    this.blockedDocument = true;

    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - START
    // CHILD-spezifische Zusätze, z.B. Nachladen anderer Entities - ENDE

    super.ngOnInit();
  }

  getValuesFromForm() {
    let a = super.getValuesFromForm(); // Standard! do not change!

    this.dokumentValueChangeSubscriptionActive = false; // nach dem Save keine Warnung mehr!

    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - START
    if(a.typ != 'Lnk' && a.typ != 'Ueb') {
      a.dateiname = a.dokument.dateiname;
      a.dateiendung = a.dokument.dateiendung;
      a.dokument = a.dokument._file;

      a.link = null;
    }
    else {
      a.dateiname = null;
      a.dateiendung = null;
      a.dokument = null;
    }

    delete a.typ_ChoiceListEntry;
    if(a.typ == null) a.reihenfolge = null;
    // CHILD-spezifische assigns, z.B. spracheId aus strache.Id bestücken - ENDE

    //console.log("DokumentDetailComponent.getValuesFromForm() a:", a);
    return a;
  }

  sendValuesToForm() {
    // CHILD-spezifisch die Form patchen - START
    this.datei.dateiname = this.CRUDItem.dateiname;
    this.datei.dateiendung = this.CRUDItem.dateiendung;
    this.datei._file = this.CRUDItem.dokument;
    this.CRUDForm.patchValue({
      bezeichnung: this.CRUDItem.bezeichnung,
      beschreibung: this.CRUDItem.beschreibung,
      kopfdaten: this.CRUDItem.kopfdaten,
      //leitfaden: this.CRUDItem.leitfaden,
      //leitfadenSortierung: this.CRUDItem.leitfadenSortierung,
      typ: this.CRUDItem.typ,
      reihenfolge: this.CRUDItem.reihenfolge,

      link: this.CRUDItem.link,

      dokument: this.dataId == 0 ? null : this.datei
    });
    // CHILD-spezifisch die Form patchen - ENDE
    //console.log("DokumentDetailComponent.sendValuesToForm() CRUDForm:", this.CRUDForm);
    super.sendValuesToForm(); // haben nicht alle DetailComponents - erst ab Ticket 9412 17.07.2019
  }

  upload() {
    //let dateiendungBefore = this.CRUDForm.value.dokument != null ? this.CRUDForm.value.dokument.dateiendung : null;
    let dateiBefore = this.CRUDForm.value.dokument;
    this.dokumentValueBefore = cloneDeep(dateiBefore)
    console.log("DokumentDetailComponent.upload() dokumentValueBefore:", this.dokumentValueBefore);
    this.dokument.upload();
  }

  iconClicked() {
    if(this.dataId != null && this.dataId > 0) this._crudItemService.downloadDokumentViaBlob(this.dataId, 'D');
  }

  formValidator(form: FormGroup) { // validate auf FORM-Ebene!
    let errors : any[] = [];
    let errorsFound : boolean = false;

    //console.log("DokumenteDetail.formValidator() form:", form);

    if(form.value.typ != 'Ueb') {
      if(form.value.typ != 'Lnk') {
        if(form.value.dokument == null || form.value.dokument.dateiname == null || form.value.dokument.dateiname.length <= 0) { 
          errors['bitte_dokument_hinterlegen']=true;
          errorsFound = true;
        }
      }
      else {
        if(form.value.link == null || form.value.link.length <= 0) { 
          errors['bitte_link_hinterlegen']=true;
          errorsFound = true;
        }
      }
    }

    //form.setErrors(errorsFound ? errors : null);
    if(errorsFound) return errors;
    else return null;
  }

}
